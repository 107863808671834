import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles((_: Theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "1fr",
    width: "100%",
    overflowX: "auto",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px",
  },
}));

const StyledTableGrid = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses =
    classes && classes.root
      ? classNames(classes.root, commonClasses.root)
      : commonClasses.root;
  return (
    <Grid
      container
      classes={{ ...classes, root: activeRootClasses }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default StyledTableGrid;
