import { useQuery, UseQueryOptions } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';


const fetchDeviceTags = async (tagIds: number[]) => {
  return await VrsTagsApi.getDeviceTagsByTagIds(tagIds);
};

/**
 * Custom hook to fetch device tags using react-query.
 *
 * @param {number[]} tagIds - The IDs of the tags to fetch device tag relationships for.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the device tags data and query status.
 */
const useFetchDeviceTags = (
  tagIds: number[],
  options?: UseQueryOptions<unknown, unknown, unknown, [string, string[]]>
) => {
  return useQuery(['deviceTags', tagIds.map(t => String(t))], () => fetchDeviceTags(tagIds), {
    enabled: tagIds.length > 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export default useFetchDeviceTags;
