import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { myGrayShade2Alpha10 } from "../../../constants/colors";

export const useEditProfileDialogStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: "flex",
    flexFlow: "row",
    maxWidth: theme.spacing(80),
    alignItems: "center",
    "&:nth-last-child(n + 2)": {
      marginBottom: theme.spacing(1.875),
    },
    width: "100%",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  profileDetail: {
    paddingTop: "10px",
  },
  detail: {
    width: "100%",
    minWidth: "580px !important",
    maxWidth: "580px !important",
    [theme.breakpoints.only("xs")]: {
      minWidth: "300px !important",
      maxWidth: "300px !important",
    },
  },
  tabContainer: {
    borderBottom: `thin solid ${myGrayShade2Alpha10}`,
  },
  detailTop: {
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
  },
  detailContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    minHeight: "400px",
  },
  detailContainerOnly: {
    padding: "10px 20px",
  },
  content: {
    padding: "0px !important",
  },
}));
