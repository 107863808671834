import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLayoutState } from "../../context/LayoutContext/LayoutContext";
import { useProgressIndicatorStyles } from "./ProgressIndicator.css";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface ProgressIndicatorProps {
  fullScreen?: boolean;
  withBackground?: boolean;
  delay?: number;        // Added delay prop (in seconds)
  message?: string;      // Added message prop
}

const ProgressIndicator = ({
                             fullScreen,
                             withBackground,
                             delay = 15,           // Default delay of 15 seconds
                             message
                           }: ProgressIndicatorProps) => {
  const classes = useProgressIndicatorStyles();
  const { isSidebarOpened } = useLayoutState();
  const { _T } = useVrsTranslationState(); // Importing the translation hook
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, delay * 1000); // Convert seconds to milliseconds
    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [delay]);

  // Default translated message
  const defaultMessage = _T("We are still working on your request...");

  return (
    <div
      className={classNames({
        [classes.overlayWithDrawer]: !fullScreen && isSidebarOpened,
        [classes.overlay]: !(!fullScreen && isSidebarOpened),
        [classes.withBackground]: withBackground,
      })}
    >
      <div className={classes.spinner}>
        <div className={classes.placeholder}>
          <CircularProgress />
        </div>
        {showMessage && <div className={classes.message}>{message || defaultMessage}</div>}
      </div>
    </div>
  );
};

export { ProgressIndicator };
