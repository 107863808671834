import MuiDialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { myCorporatePrimaryColor, myWhite } from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 600,
    fontSize: theme.spacing(2),
    backgroundColor: myCorporatePrimaryColor,
    color: myWhite,
  },
}));

export const StyledDialogTitle = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses =
    classes && classes.root
      ? classNames(classes.root, commonClasses.root)
      : commonClasses.root;
  return (
    <MuiDialogTitle
      classes={{ ...classes, root: activeRootClasses }}
      {...props}
    >
      {children}
    </MuiDialogTitle>
  );
};
