import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { myBlack, standardBoxShadow } from "../../constants/colors";

export default makeStyles((theme: Theme) => ({
  messageBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "100px",
    padding: theme.spacing(12.5, 2.5),
    minHeight: theme.spacing(40),
    margin: theme.spacing(2.5, 1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
  },
  logoContainer: {
    height: "auto",
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    maxWidth: "100%",
    backgroundColor: myBlack,
    padding: theme.spacing(1.875, 2.5), // 15px 20px;
    borderRadius: theme.spacing(0.5),
  },
  logo: {
    minHeight: theme.spacing(3.75),
    width: theme.spacing(25),
  },
  content: {
    fontSize: "20px",
  },
}));
