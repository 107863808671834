import { useMemo } from "react";
import VrsListPrinterLogsApi from "../api/prodListPrinterLogsApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";

import { LIST_PRINTER_LOGS_SUCCESS } from "./actionTypes";

export function loadPrinterLogsSuccess(payload) {
  return { type: LIST_PRINTER_LOGS_SUCCESS, payload };
}

function loadAppSyncListPrinterLogs(
  siteId: string,
  printerId: string,
  logType: string
) {
  return (_) => {
    return VrsListPrinterLogsApi.getAppSyncListPrinterLogs(
      siteId,
      printerId,
      logType
    )
      .then((printerLogs) => {
        return printerLogs;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}
export const useVrsLoadPrinterLogsActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      loadAppSyncListPrinterLogs: (
        siteId: string,
        printerId: string,
        logType: string
      ) => loadAppSyncListPrinterLogs(siteId, printerId, logType)(dispatch),
    }),
    [dispatch]
  );
};
