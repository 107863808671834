import makeStyles from "@mui/styles/makeStyles";
import { myBlack } from "../../constants/colors";
import { Theme } from "@mui/material";

export const useCopyrightButtonStyle = makeStyles((theme: Theme) => ({
  root: {
    color: myBlack,
    padding: theme.spacing(0, 0.25)
  }
}));
