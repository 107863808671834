
import { useMemo } from 'react';
import ShiftApi from '../api/prodShiftApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import {
  CREATE_SHIFT_SUCCESS,
  DELETE_SHIFT_SUCCESS,
  LOAD_SHIFTSATMIN_SUCCESS,
  LOAD_SHIFTS_INITIATED,
  LOAD_SHIFTS_SUCCESS,
  UPDATE_SHIFT_SUCCESS
} from './actionTypes';

export function loadShiftsSuccess(payload) {
  return { type: LOAD_SHIFTS_SUCCESS, payload };
}

export function loadShiftsInitiated(payload) {
  return { type: LOAD_SHIFTS_INITIATED, payload };
}

export function loadShifts(siteId) {
  return function (dispatch) {
    dispatch(loadShiftsInitiated({ id: siteId }));
    return ShiftApi.getShifts(siteId).then((shifts) => {
      dispatch(loadShiftsSuccess(shifts));
      return shifts;
    }).catch((error) => {
      throw (error);
    });
  };
}


export function loadShiftsAtMinuteSuccess(payload) {
  return { type: LOAD_SHIFTSATMIN_SUCCESS, payload };
}

export function getShiftsAtMinute(siteId, isotime) {
  return function (dispatch) {
    return ShiftApi.getShiftsAtMinute(siteId, isotime).then((shifts) => {
      /* console.log('getting shifts',shifts); */
      dispatch(loadShiftsAtMinuteSuccess(shifts));
      return shifts;
    }).catch((error) => {

      throw (error);
    });
  };
}

export function createShiftSuccess(payload) {
  return { type: CREATE_SHIFT_SUCCESS, payload };
}

export function updateShiftSuccess(payload) {
  return { type: UPDATE_SHIFT_SUCCESS, payload };
}

function saveShiftEvent(shift) {
  return function (dispatch) {
    const edited = !!shift.id;
    const errCallback = (error) => {
      console.log('saveShiftEvent error', error);

      throw error;
    };
    if (edited) {
      return ShiftApi.updateEvent(shift.siteId, shift).then((shift) => {
        dispatch(updateShiftSuccess(shift));
      }).catch(errCallback);
    }

    return ShiftApi.saveShiftEvent(shift).then((shift) => {
      dispatch(createShiftSuccess(shift));
    }).catch(errCallback);
  };
}

export function deleteShiftSuccess(id) {
  return { type: DELETE_SHIFT_SUCCESS, id };
}

function deleteShiftEvent(siteId, id) {
  return function (dispatch) {
    return ShiftApi.deleteShiftEvent(siteId, id).then(
      dispatch(deleteShiftSuccess(id)),
    ).catch((error) => {

      throw (error);
    });
  };
}

export const useShiftActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(() => ({
    loadShifts: (shiftId) => loadShifts(shiftId)(dispatch),
    getShiftsAtMinute: (siteId, isotime) => getShiftsAtMinute(siteId, isotime)(dispatch),
    saveShiftEvent: (shift) => saveShiftEvent(shift)(dispatch),
    deleteShiftEvent: (siteId, id) => deleteShiftEvent(siteId, id)(dispatch)
  }), [dispatch]);
};
