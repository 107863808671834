import { Checkbox } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { myPrimaryColor, myPrimaryColorLighter } from "../../constants/colors";

const useStyles = makeStyles((_: Theme) => ({
  switchBase: {
    color: myPrimaryColorLighter,
    "&$checked": {
      color: myPrimaryColor,
    },
    "&$checked + $track": {
      backgroundColor: myPrimaryColor,
    },
  },
  checked: {},
  track: {},
}));

export const StyledSwitch = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeSwitchBaseClasses =
    classes && classes.switchBase
      ? classNames(classes.switchBase, commonClasses.switchBase)
      : commonClasses.switchBase;
  return (
    <Checkbox
      classes={{
        ...classes,
        switchBase: activeSwitchBaseClasses,
        checked: commonClasses.checked,
        track: commonClasses.track,
      }}
      {...props}
    >
      {children}
    </Checkbox>
  );
};
