import makeStyles from "@mui/styles/makeStyles";
import { mySecondaryColor, myWhite } from "../../constants/colors";
import { Theme } from "@mui/material";

export const useSearchableSelectorStyle = makeStyles((theme: Theme) => ({
  textRoot: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0.2),
  },
  inputRoot: {
    paddingRight: theme.spacing(4, "!important"),
  },
  paper: {
    margin: 0,
    fontSize: theme.spacing(1.625),
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  optionLabel: {
    backgroundColor: mySecondaryColor,
    color: myWhite,
    borderRadius: "6px",
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.125, 1.125),
    marginRight: theme.spacing(1.125),
  },
  optionPart1: {
    display: "flex",
    alignItems: "center",
  },
  deleteItem: {
    marginRight: theme.spacing(0.5),
  },
  icon: {
    display: "flex",
    marginRight: theme.spacing(1.125),
    justifyContent: "center",
    alignItems: "center",
  },
  groupText: {
    fontSize: "13px",
    fontWeight: 600,
    padding: '0px 5px'
  },
}));
