import { useMemo } from 'react';
import ProductApi from '../api/prodProductApi';
import { useAppGlobalDispatch } from '../context/AppContext/AppContext';
import { Logger } from '../utilities/Logger/Logger';
import {
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  LOAD_PRODUCTS_INITIATED,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCT_SUCCESS
} from './actionTypes';

export function loadProductsSuccess(payload) {
  return { type: LOAD_PRODUCTS_SUCCESS, payload };
}

export function loadProductsInitiated(payload) {
  return { type: LOAD_PRODUCTS_INITIATED, payload };
}

function loadProductSuccess(payload) {
  return { type: LOAD_PRODUCT_SUCCESS, payload };
}

function loadProducts(siteId) {
  return (dispatch) => {
    dispatch(loadProductsInitiated({ id: siteId }));
    return ProductApi.getProducts(siteId).then((products) => {
      dispatch(loadProductsSuccess(products));
      return products;
    }).catch((error) => {
      console.log('error', error);
      throw (error);
    });
  }
}

function loadProduct(siteId, productId) {
  return (dispatch) => {
    return ProductApi.getProduct(siteId, productId).then((products) => {
      dispatch(loadProductSuccess(products));
      return products;
    }).catch((error) => {
      console.log('error', error);
      throw (error);
    });
  }
}

function createProductSuccess(payload) {
  return { type: CREATE_PRODUCT_SUCCESS, payload };
}

function saveProduct(siteId, product) {
  return (dispatch) => {
    const edited = !!product.id;
    const errCallback = (error) => {
      Logger.of('App.productActions.saveProduct').warn('error', error);
      throw error;
    };
    if (edited) {
      return ProductApi.updateProduct(siteId, product).then(() => {
        dispatch(loadProductsInitiated({ id: siteId }));
        ProductApi.getProducts(siteId).then((products) => {
          dispatch(loadProductsSuccess(products));
        })
      }).catch(errCallback);
    }
    return ProductApi.saveProduct(product).then((prod) => {
      dispatch(createProductSuccess(prod));
    }).catch(errCallback);
  }
}

function deleteProductSuccess(product) {
  return { type: DELETE_PRODUCT_SUCCESS, product };
}

function deleteProduct(siteId, id) {
  return (dispatch) => {
    return ProductApi.deleteProduct(siteId, id).then( () => {
    dispatch(deleteProductSuccess(id));
    return id;
    }).catch((error) => {
      throw (error);
    });
  }
}

export const useProductActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(() => ({
    deleteProduct: (siteId, id) => deleteProduct(siteId, id)(dispatch),
    saveProduct: (siteId, product) => saveProduct(siteId, product)(dispatch),
    loadProducts: (siteId) => loadProducts(siteId)(dispatch),
    loadProduct: (siteId, productId) => loadProduct(siteId, productId)(dispatch),
  }), [dispatch]);
};
