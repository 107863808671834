import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { myRed, mySecondaryColor, myWhite } from "../../../../constants/colors";

export const useWorkScheduleTableStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  gridClasses: {
    gridAutoColumns: "minmax(280px, 11fr)",
  },
  rowClasses: {
    gridTemplateColumns: "3fr 4fr 4fr",
    columnGap: "0px !important",
  },
  cellClasses: {
    "&:nth-child(3n)": {
      borderRight: "unset",
    },
  },
  cellHeadClasses: {
    whiteSpace: "normal",
    wordBreak: "break-word",
    backgroundColor: mySecondaryColor,
    color: myWhite,
    "&:nth-child(4n)": {
      borderRight: "unset",
    },

    textAlign: "center",
    padding: theme.spacing(0.5, 0, "!important"),
  },
  errors: {
    color: myRed,
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "align",
    alignItems: "center",
    padding: "5px",
  },
}));
