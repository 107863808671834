import { useEffect, useState } from "react";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { SearchableSelector } from "../SearchableSelector/SearchableSelector";

interface CompanySelectorProps {
  onCompanyChanged: (companyId) => void;
  companyId: string;
  companies: Array<any>;
  allCompanies?: boolean;
  isDisabled?: boolean;
  disableClearable?: boolean;
  rootClass?: any;
  textSize?: "medium" | "small";
}
function CompanySelector({
  onCompanyChanged,
  companyId,
  companies,
  allCompanies,
  isDisabled,
  disableClearable,
  rootClass,
  textSize,
}: CompanySelectorProps) {
  const { _T } = useVrsTranslationState();

  const [companyOptions, setCompanyOptions] = useState<Array<any>>([]);
  const [companyName, setCompanyName] = useState<string>("");

  useEffect(() => {
    const companyOptionsObj: any = {};
    companies &&
      companies.forEach((company) => {
        const companyName = company.companyName;
        if (!companyOptionsObj[companyName] && company.companyId) {
          companyOptionsObj[companyName] = {
            text: companyName || `Company ${company.companyId}`,
            value: company.companyId,
          };
        }
      });

    const options = Object.keys(companyOptionsObj)
      .map((key) => companyOptionsObj[key])
      .sort((a, b) => (a.text > b.text ? 1 : a.text < b.text ? -1 : 0));

    if (allCompanies) {
      options.unshift({ value: "-1", text: _T("All Companies") });
    }

    setCompanyOptions(options);
  }, [allCompanies, companies]);

  useEffect(() => {
    const selectedCompany = allCompanies
      ? companies &&
        [
          { companyId: "-1", companyName: _T("All Companies") },
          ...companies,
        ].find((e) => e.companyId === companyId)
      : companies && companies.find((e) => e.companyId === companyId);
    setCompanyName(selectedCompany ? selectedCompany.companyName : companyId);
  }, [companies, companyId, allCompanies]);

  return (
    <SearchableSelector
      fieldName="companyId"
      label={_T("Company")}
      placeholder={_T("Company")}
      value={companyName}
      onChange={onCompanyChanged}
      onReset={() => {
        onCompanyChanged("");
      }}
      options={companyOptions}
      disableDescription={true}
      disableClearable={disableClearable || companyOptions.length < 2}
      textSize={textSize || "small"}
      rootClass={rootClass}
      isDisabled={isDisabled || !companyOptions || companyOptions.length <= 0}
    />
  );
}

CompanySelector.displayName = "CompanySelector";

export { CompanySelector };
