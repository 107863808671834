import Axios, {AxiosRequestConfig} from "axios";
import {ExternalActionType} from "../enums/externalActionType";
import config from '../config'
import { UM_VCCM } from '../constants/global';
import authLib from './authLib';

export function callExternalApi(method: string, externalUrl: string, body: any, axiosConfig: AxiosRequestConfig): Promise<any> {
  const methodLower = method.toLowerCase();
  if (methodLower === ExternalActionType.get || methodLower === ExternalActionType.delete) {
    return Axios[methodLower](externalUrl, axiosConfig);
  } else {
    return Axios[methodLower](externalUrl, body, axiosConfig);
  }
}

export function getApiUrl(gatewayName: string): string {
  let gatewayUrlKey = "URL";
  if (gatewayName) gatewayUrlKey = gatewayName + "_URL";
  return config.apiGateway[gatewayUrlKey]
}

export function getApiPrefix(): string {
  const vccmPrefix = '';

  const loggedIn = authLib.modulesLoggedIn();
  if (loggedIn[UM_VCCM]) return vccmPrefix;
  else switch (authLib.getUMModuleName()) {
    case UM_VCCM:
    default:
      return vccmPrefix;
  }
}
