import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useConfirmSaveWorkScheduleDialogStyles = makeStyles(
  (_: Theme) => ({
    item: {
      padding: "3px",
    },
    notification: {
      fontSize: "14px",
      fontWeight: 600,
    },
    notificationYes: {
      color: "green",
    },
    notificationNo: {
      color: "red",
    },
  })
);
