import { forwardRef, PropsWithChildren } from "react";
import { animated, useSpring } from "@react-spring/web";
import { EmptyItem } from "../Basic/EmptyItem/EmptyItem";
import StyledTableGrid from "../StyledTableGrid/StyledTableGrid";

interface TableGridWithEmptyDataCheckProps {
  hasData: boolean;
  hasFilteredData: boolean;
  emptyText: string;
  emptyFilteredText?: string;
  gridClasses: string;
  loaded?: boolean;
}

// eslint-disable-next-line react/display-name
const TableGridWithEmptyDataCheck = forwardRef(
  (
    {
      children,
      hasData,
      hasFilteredData,
      emptyText,
      emptyFilteredText,
      gridClasses,
      loaded,
    }: PropsWithChildren<TableGridWithEmptyDataCheckProps>,
    ref: any
  ) => {
      const styles = useSpring({
        opacity: loaded || loaded === undefined ? 1 : 0,
      });

      return hasData ? (
        hasFilteredData ? (
          <animated.div style={styles} ref={ref}>
            <StyledTableGrid classes={{ root: gridClasses }}>
              {children}
            </StyledTableGrid>
          </animated.div>
        ) : emptyFilteredText ? (
          <EmptyItem text={emptyFilteredText} />
        ) : null
      ) : emptyText ? (
        <EmptyItem text={emptyText} />
      ) : emptyText ? (
        <EmptyItem text={emptyText} />
      ) : null;
    }
);

export { TableGridWithEmptyDataCheck };
