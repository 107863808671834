import {
  useMemo
} from 'react';
import UsersApi
  from '../api/prodUserApi';
import {
  useAppGlobalDispatch
} from '../context/AppContext/AppContext';
import {
  ASSIGN_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  LOAD_INTERNAL_USERS_SUCCESS,
  LOAD_USERS_SUCCESS,
  SET_COGNITO_USER_LOADED,
  SET_DEFAULT_PAGE_REQUIRED,
  UPDATE_USER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  VRS_USER_ROLES_UPDATED_SUCCESS,
  VRS_USERS_MIGRATION_SUCCESS
} from './actionTypes';

export function loadUsersSuccess(users, version = null) {
  return {
    type: LOAD_USERS_SUCCESS,
    users,
    version
  };
}

export function loadInternalUsersSuccess(users, version = null) {
  return {
    type: LOAD_INTERNAL_USERS_SUCCESS,
    users,
    version
  };
}

function loadUsers(companies) {
  return async (dispatch) => {
    let paginationToken = null;
    const allUsers: Array<any> = [];
    do {
      try {
        const response = await UsersApi.getAllUsers(1, paginationToken, companies);
        paginationToken = response.paginationToken; //eslint-disable-line prefer-destructuring
        if (Array.isArray(response)) {
          allUsers.push(...response);
        }
      } catch (error) {
        console.log('userActions.allUsers error', error);
        throw error;
      }
    } while (paginationToken);
    dispatch(loadUsersSuccess(allUsers));
    return allUsers;
  };
}

function getUserByEmail(userEmail, companies) {
  return async (_) => {
    const users = await UsersApi.getUserByEmail(userEmail, companies)
    return users;
  };
}

function loadInternalUsers() {
  return async (dispatch) => {
    let paginationToken = null;
    const allUsers: Array<any> = [];
    do {
      try {
        const response = await UsersApi.getAllUsers(1, paginationToken, null, true);
        paginationToken = response.paginationToken; //eslint-disable-line prefer-destructuring
        if (Array.isArray(response)) {
          allUsers.push(...response);
        }
      } catch (error) {
        console.log('userActions.loadInternalUsers error', error);
        throw error;
      }
    } while (paginationToken);
    dispatch(loadInternalUsersSuccess(allUsers));
    return allUsers;
  };
}

export function createUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    user
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    user
  };
}


function saveUser(user, messageAction) {
  return (dispatch) => {
    console.log('userActions.saveUser saving =>', user);
    return UsersApi.saveUser(user, messageAction).then((user) => {
      dispatch(createUserSuccess(user));
      console.log('userActions.saveUser success =>', user);
      return user;
    }).catch((error) => {
      console.log('userActions.saveUser error', error);
      throw error;
    });
  };
}

export function resetPasswordSuccess(user) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user
  };
}

function resetPassword(user) {
  return (dispatch) => {
    console.log('userActions.resetPassword saving =>', user);
    return UsersApi.resetPassword(user).then((user) => {
      dispatch(resetPasswordSuccess(user));
      console.log('userActions.resetPassword success =>', user);
      return user;
    }).catch((error) => {
      console.log('userActions.resetPassword error', error);
      throw error;
    });
  };
}

function sendWelcomeEmail(user) {
  return (_) => {
    console.log('userActions.sendWelcomeEmail =>', user);
    return UsersApi.sendWelcomeEmail(user).then((user) => {
      console.log('userActions.sendWelcomeEmail success =>', user);
      return user;
    }).catch((error) => {
      console.log('userActions.sendWelcomeEmail error', error);
      throw error;
    });
  };
}

export function assignUserSuccess(userId) {
  return {
    type: ASSIGN_USER_SUCCESS,
    userId
  };
}

function assignUser(userId, siteId, roleLabel, siteRoles) {
  return (dispatch) => {
    return UsersApi.assignUser(userId, siteId, roleLabel, siteRoles).then(
      dispatch(assignUserSuccess(userId)),
    ).catch((error) => {

      console.log('userActions.assignUser error', error);
      throw error;
    });
  };
}

export function deleteUserSuccess(id) {
  return {
    type: DELETE_USER_SUCCESS,
    id
  };
}

function deleteUser(siteId, id) {
  return (dispatch) => {
    return UsersApi.deleteUser(siteId, id).then(
      dispatch(deleteUserSuccess(id)),
    ).catch((error) => {

      console.log('userActions.deleteUser error', error);
      throw error;
    });
  };
}

function createVrsUserRoles(userRoleCategories) {
  return (dispatch) => {
    return UsersApi.createVrsUserRoles(userRoleCategories).then(result => {
      dispatch({
        type: VRS_USER_ROLES_UPDATED_SUCCESS,
        payload: result
      });
      return result;
    }).catch((error) => {
      console.log('userActions.createVrsUserRoles error', error);
      throw error;
    });
  };
}

function setPageRequired(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_DEFAULT_PAGE_REQUIRED,
      payload
    });
  }
}

function setCognitoUserLoaded(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_COGNITO_USER_LOADED,
      payload
    });
  }
}

function migrateVrsUsers({
                           limit,
                           queryOnly,
                           internalOnly,
                           emailAddress
                         }: { limit: number; queryOnly: boolean; internalOnly: boolean; emailAddress: string }) {
  return (dispatch) => {
    return UsersApi.migrateVrsUsers({
      limit,
      queryOnly,
      internalOnly,
      emailAddress
    }).then(result => {
      dispatch({
        type: VRS_USERS_MIGRATION_SUCCESS,
        payload: result
      });
      return result;
    }).catch((error) => {
      console.log('userActions.migrateVrsUsers error', error);
      throw error;
    });
  };
}

export const useUserActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(() => ({
    loadInternalUsers: () => loadInternalUsers()(dispatch),
    loadUsers: (companies) => loadUsers(companies)(dispatch),
    getUserByEmail: (userEmail, companies: string | null = null) => getUserByEmail(userEmail, companies)(dispatch),
    saveUser: (user, suppressEmail = false) => saveUser(user, suppressEmail ? 'suppress' : undefined)(dispatch),
    resetPassword: (user) => resetPassword(user)(dispatch),
    sendWelcomeEmail: (user) => sendWelcomeEmail(user)(dispatch),
    assignUser: (userId, siteId, roleLabel, siteRoles) => assignUser(userId, siteId, roleLabel, siteRoles)(dispatch),
    deleteUser: (siteId, id) => deleteUser(siteId, id)(dispatch),
    setPageRequired: (value) => setPageRequired(value)(dispatch),
    setCognitoUserLoaded: (value) => setCognitoUserLoaded(value)(dispatch),
    createVrsUserRoles: (userRoleCategories) => createVrsUserRoles(userRoleCategories)(dispatch),
    migrateVrsUsers: ({
                        limit,
                        queryOnly,
                        internalOnly,
                        emailAddress
                      }: {limit: number; queryOnly: boolean; internalOnly: boolean; emailAddress: string}) => migrateVrsUsers({
      limit,
      emailAddress,
      internalOnly,
      queryOnly
    })(dispatch)
  }), [dispatch]);
};
