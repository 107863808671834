import {
  CREATE_SHIFT_SUCCESS,
  DELETE_SHIFT_SUCCESS,
  LOAD_SHIFTS_SUCCESS,
  RESET_SITE_DATA,
  UPDATE_SHIFT_SUCCESS
} from "../../actions/actionTypes";
import initialState from "../initialState";

// A reducing function that returns the next state tree, given the current state tree and an action to handle.
export function shiftReducer(state = initialState.shifts, action) {
  switch (action.type) {
    case RESET_SITE_DATA:
      return initialState.shifts;

    case LOAD_SHIFTS_SUCCESS:
      return action.payload;

    case DELETE_SHIFT_SUCCESS:
      return [
        ...state.filter(shift => shift.id !== action.id),
      ];

    case CREATE_SHIFT_SUCCESS:
      return [
        ...state,
        { ...action.payload },
      ];

    case UPDATE_SHIFT_SUCCESS:
      return [
        ...state.filter(shift => shift.id !== action.payload.id),
        { ...action.payload }
      ];

    default:
      return state;
  }
}
