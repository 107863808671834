

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// styles
import useStyles from "./ProfileMenuItem.css";

export interface IProfileMenuItemProps {
  icon: any;
  linkLabel: string;
  onClickMenuItem: () => void;
  testId: string;
}

const ProfileMenuItem = ({
  icon,
  linkLabel,
  onClickMenuItem,
  testId
}: IProfileMenuItemProps) => {

  const classes = useStyles();

  return <ListItem button
    className={classes.link}
    onClick={onClickMenuItem}>
    <ListItemIcon className={classes.linkIcon}>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={linkLabel}
      classes={{
        primary: classes.linkText,
      }}
      data-testid={testId} />
  </ListItem>

}

export { ProfileMenuItem };
