import "moment";
import momentTimeZone from "moment-timezone";
import { extendMoment } from "moment-range";
import LineApi from "../api/prodLineApi";
import SiteApi from "../api/prodSiteApi";
import { Logger } from "../utilities/Logger/Logger";
import {
  CREATE_LINE_SUCCESS,
  CREATE_QA_COUNT_SUCCESS,
  DELETE_LINE_SUCCESS,
  LOAD_LINES_INITIATED,
  LOAD_LINES_OVERVIEW_INITIATED,
  LOAD_LINES_OVERVIEW_SUCCESS,
  LOAD_LINES_SUCCESS,
  LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS,
  LOAD_LINE_OEE_DAY_SUCCESS,
  LOAD_LINE_OEE_HOUR_SUCCESS,
  LOAD_LINE_OEE_SHIFT_SUCCESS,
  LOAD_LINE_SHIFT_TARGETS_SUCCESS,
  LOAD_LINE_SLOW_CYCLES_SUCCESS,
  LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS,
  LOAD_LINE_TIME_IN_STATE_SUCCESS,
  LOAD_LINE_UTILIZATION_SUCCESS,
  SET_LINE,
  UPDATE_LINE_SUCCESS,
} from "./actionTypes";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import { useMemo } from "react";

const moment = extendMoment(momentTimeZone as any);

export function createLineSuccess(line) {
  return { type: CREATE_LINE_SUCCESS, line };
}

export function deleteLine(id, siteId) {
  return (dispatch) => {
    return LineApi.deleteLine(id, siteId)
      .then(dispatch(deleteLineSuccess(id)))
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteLineSuccess(id) {
  return { type: DELETE_LINE_SUCCESS, id };
}

export function loadLineOee(siteId, id, startTime, endTime, interval) {
  return (dispatch) => {
    return LineApi.getLineOee(siteId, id, startTime, endTime, interval)
      .then((data) => {
        const payload = { lineId: id, interval, data };
        switch (interval) {
          case "hours":
            dispatch(loadLineOeeHourSuccess(payload));
            break;
          case "shift_hours":
            dispatch(loadLineOeeShiftSuccess(payload));
            break;
          case "days":
            dispatch(loadLineOeeDaySuccess(payload));
            break;
          default:
            break;
        }
        return payload;
      })
      .catch((_) => {});
  };
}

export function loadLineOeeDaySuccess(oee) {
  return { type: LOAD_LINE_OEE_DAY_SUCCESS, oee };
}

export function loadLineOeeHourSuccess(oee) {
  return { type: LOAD_LINE_OEE_HOUR_SUCCESS, oee };
}

export function loadLinesOverviewSuccess(payload) {
  return { type: LOAD_LINES_OVERVIEW_SUCCESS, payload };
}

export function loadLineOeeShiftSuccess(oee) {
  return { type: LOAD_LINE_OEE_SHIFT_SUCCESS, oee };
}

export function loadLineOeeShift(siteId, id, interval = "shift_hours") {
  return (dispatch) => {
    return LineApi.getLineOeeShift(siteId, id, interval)
      .then((data) => {
        dispatch(loadLineOeeShiftSuccess({ lineId: id, data }));
      })
      .catch((error) => {
        Logger.of("App.lineActions.loadLineOeeShift").error(
          "lineActions.loadLineOeeShift error",
          error
        );
        throw error;
      });
  };
}

export function loadLinesSuccess(data) {
  return { type: LOAD_LINES_SUCCESS, data };
}

export function loadLinesInitiated(data) {
  return { type: LOAD_LINES_INITIATED, data };
}

export function loadLinesOverviewInitiated(data) {
  return { type: LOAD_LINES_OVERVIEW_INITIATED, data };
}

export function loadLinesOverview(
  siteId,
  lineIds,
  startDateTime,
  useLoader = false
) {
  return (dispatch) => {
    if (useLoader) loadLinesOverviewInitiated({ id: siteId });
    return LineApi.getLinesOverview(siteId, lineIds, startDateTime)
      .then((response) => {
        // don't make them wait forever. give them something.
        // flatten
        const results = Array.isArray(response)
          ? response.reduce((acc, val) => ({ ...acc, ...val }), {})
          : response;
        dispatch(loadLinesOverviewSuccess({ id: siteId, response: results }));
        return results;
      })
      .catch((error) => {
        if (useLoader) throw error;
      });
  };
}

export function loadLines(siteId) {
  return (dispatch) => {
    dispatch(loadLinesInitiated({ id: siteId }));
    return LineApi.getAllLines(siteId)
      .then((lines) => {
        dispatch(loadLinesSuccess({ id: siteId, lines }));
        if (lines && lines.length > 0) {
          dispatch(
            loadLinesOverview(
              siteId,
              lines.map((l) => l.id),
              momentTimeZone().toISOString(),
              true
            )
          );
        }
        return lines;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadAllLinesAndOverviews(siteId) {
  return (dispatch) => {
    dispatch(loadLinesInitiated({ id: siteId }));
    return LineApi.getAllLines(siteId)
      .then((lines) => {
        dispatch(loadLinesSuccess({ id: siteId, lines }));
        if (lines && lines.length > 0) {
          loadLinesOverviewInitiated({ id: siteId });
          return LineApi.getLinesOverview(
            siteId,
            lines.map((l) => l.id),
            moment().toISOString()
          )
            .then((response) => {
              const results = Array.isArray(response)
                ? response.reduce((acc, val) => ({ ...acc, ...val }), {})
                : response;

              dispatch(
                loadLinesOverviewSuccess({ id: siteId, response: results })
              );
            })
            .catch((_) => {});
        }
      })
      .catch((_) => {});
  };
}

export function loadLineSlowCyclesSuccess(payload) {
  return { type: LOAD_LINE_SLOW_CYCLES_SUCCESS, payload };
}

export function loadLineShiftTargetsSuccess(lineShiftTargets) {
  return { type: LOAD_LINE_SHIFT_TARGETS_SUCCESS, lineShiftTargets };
}

export function loadLineTimeInStateSuccess(payload) {
  return { type: LOAD_LINE_TIME_IN_STATE_SUCCESS, payload };
}

export function loadLineShiftTargets(siteId, lineId) {
  return (dispatch) => {
    return LineApi.getLineShiftTargets(siteId, lineId)
      .then((data) => {
        dispatch(loadLineShiftTargetsSuccess({ siteId, lineId, data }));
        return data;
      })
      .catch((error) => {
        Logger.of("App.lineActions.loadLineShiftTargets").error(
          "lineActions.loadLineShiftTargets error",
          error
        );
        throw error;
      });
  };
}

export function loadLineSlowCycles(
  siteId,
  lineId,
  startDt,
  endDt,
  type,
  slowCycleThreshold
) {
  return (dispatch) => {
    return LineApi.getLineProcessMinute(
      siteId,
      lineId,
      startDt,
      endDt,
      type,
      slowCycleThreshold
    )
      .then((data) => {
        dispatch(loadLineSlowCyclesSuccess({ siteId, lineId, data }));
      })
      .catch((error) => {
        Logger.of("App.lineActions.loadLineSlowCycles").error(
          "lineActions.loadLineSlowCycles error",
          error
        );
        throw error;
      });
  };
}

export function loadLineThroughputByDaySuccess(data) {
  return { type: LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS, data };
}

export function loadLineThroughputByDay(id) {
  return (dispatch) => {
    return LineApi.getLineThroughputByDay(id)
      .then((data) => {
        dispatch(loadLineThroughputByDaySuccess({ lineId: id, data }));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadTimeInState(siteId, lineIds, threshold) {
  return (dispatch) => {
    return LineApi.getLineTimeInState(siteId, lineIds, null, threshold)
      .then((response) => {
        const payload: any = {
          siteId,
          multiline: lineIds.length > 1,
          response,
        };
        if (!payload.multiline) {
          payload.lineId = lineIds[0];
        }
        dispatch(loadLineTimeInStateSuccess(payload));
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadLineTimeInState(siteId, id, threshold) {
  return (dispatch) => {
    return loadTimeInState(siteId, [id], threshold)(dispatch);
  };
}

export function loadLineDowntimeSummary(
  endDt,
  interval,
  lineIds,
  shiftIds,
  siteId,
  startDt
) {
  return (dispatch) => {
    return SiteApi.getSiteDowntimeSummary(
      endDt,
      interval,
      lineIds,
      shiftIds,
      siteId,
      startDt,
      false,
      false
    )
      .then((response) => {
        const payload = { interval, response, lineIds };
        dispatch(loadLineDowntimeSummarySuccess(payload));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadLineDowntimeSummarySuccess(payload) {
  return { type: LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS, payload };
}

export function loadLineUtilization(id) {
  return (dispatch) => {
    return LineApi.getLineUtilization(id)
      .then((data) => {
        dispatch(loadLineUtilizationSuccess({ lineId: id, data }));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadLineUtilizationSuccess(lineUtilization) {
  return { type: LOAD_LINE_UTILIZATION_SUCCESS, lineUtilization };
}

export function saveLine(line) {
  return (dispatch) => {
    const edited = !!line.id;
    const errCallback = (error) => {
      console.log("saveLine error", error);

      throw error;
    };
    if (edited) {
      return LineApi.updateLine(line)
        .then((line) => {
          dispatch(updateLineSuccess(line));
        })
        .catch(errCallback);
    }
    return LineApi.saveLine(line)
      .then((line) => {
        dispatch(createLineSuccess(line));
      })
      .catch(errCallback);
  };
}

export function saveQaCount(count, lineId, siteId) {
  return (dispatch) => {
    return LineApi.saveQaCount(count, lineId, siteId)
      .then((response) => {
        dispatch(saveQaCountSuccess(response));
      })
      .catch((error) => {
        console.log("save QA Count error", error);

        throw error;
      });
  };
}

export function saveQaCountSuccess(payload) {
  return { type: CREATE_QA_COUNT_SUCCESS, payload };
}

export function setLine(dispatch, id) {
  dispatch({ type: SET_LINE, id });
}

export function updateLineSuccess(line) {
  return { type: UPDATE_LINE_SUCCESS, line };
}

export function loadLineDetails(siteId, lineId, slowCycleThreshold = 0) {
  return (dispatch) => {
    dispatch(loadLineOeeShift(siteId, lineId, "shift_hours"));
    dispatch(loadLineShiftTargets(siteId, lineId)(dispatch)).catch((error) =>
      Logger.of("App.lineActions.loadLineDetails").warn(
        "LineActions loadLineShiftTargets error",
        error
      )
    );
    dispatch(
      loadLineTimeInState(siteId, lineId, slowCycleThreshold)(dispatch)
    ).catch((error) =>
      Logger.of("App.lineActions.loadLineDetails").warn(
        `LineActions line ${lineId} loadLineTimeInState error`,
        error
      )
    );
  };
}

export const useLineActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      loadLineDetails: (siteId, lineId, slowCycleThreshold) =>
        loadLineDetails(siteId, lineId, slowCycleThreshold)(dispatch),
      saveQaCount: (count, lineId, siteId) =>
        saveQaCount(count, lineId, siteId)(dispatch),
      saveLine: (line) => saveLine(line)(dispatch),
      loadLineUtilization: (id) => loadLineUtilization(id)(dispatch),
      loadLineDowntimeSummary: (
        endDt,
        interval,
        lineIds,
        shiftIds,
        siteId,
        startDt
      ) =>
        loadLineDowntimeSummary(
          endDt,
          interval,
          lineIds,
          shiftIds,
          siteId,
          startDt
        )(dispatch),
      loadTimeInState: (siteId, lineIds, threshold) =>
        loadTimeInState(siteId, lineIds, threshold)(dispatch),
      loadLineThroughputByDay: (id) => loadLineThroughputByDay(id)(dispatch),
      loadLineSlowCycles: (
        siteId,
        lineId,
        startDt,
        endDt,
        type,
        slowCycleThreshold
      ) =>
        loadLineSlowCycles(
          siteId,
          lineId,
          startDt,
          endDt,
          type,
          slowCycleThreshold
        )(dispatch),
      loadLineShiftTargets: (siteId, lineId) =>
        loadLineShiftTargets(siteId, lineId)(dispatch),
      loadAllLinesAndOverviews: (siteId) =>
        loadAllLinesAndOverviews(siteId)(dispatch),
      loadLines: (siteId) => loadLines(siteId)(dispatch),
      loadLinesOverview: (siteId, lineIds, startDateTime, useLoader) =>
        loadLinesOverview(siteId, lineIds, startDateTime, useLoader)(dispatch),
      loadLineTimeInState: (siteId, id, threshold) =>
        loadLineTimeInState(siteId, id, threshold)(dispatch),
      loadLineOeeShift: (siteId, id, interval) =>
        loadLineOeeShift(siteId, id, interval)(dispatch),
      loadLineOee: (siteId, id, startTime, endTime, interval) =>
        loadLineOee(siteId, id, startTime, endTime, interval)(dispatch),
      deleteLine: (id, siteId) => deleteLine(id, siteId)(dispatch),
    }),
    [dispatch]
  );
};
