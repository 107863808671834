import { useMemo } from "react";
import { useNavigate } from "react-router";
import { checkSql, checkTimescale } from "../utilities/utils";

function navigateToLink(link, options?: any) {
  return (navigate) => {
    if (link) {
      if (options) {
        navigate(typeof link === 'string' ? link.replace("<ts>", checkTimescale() ? "-ts" : "").replace("<sql>", checkSql() ? "-sql" : "") : link, options);
      } else {
        navigate(typeof link === 'string' ? link.replace("<ts>", checkTimescale() ? "-ts" : "").replace("<sql>", checkSql() ? "-sql" : "") : link);
      }
    }
  };
}

export const useNavigateToLink = () => {
  const navigate = useNavigate();

  return useMemo(
    () => (link, options?: any) => navigateToLink(link, options)(navigate),
    [navigate]
  );
};
