import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { myRed } from "../../constants/colors";

export const useLogManagementStyles = makeStyles((theme: Theme) => ({
  info: {
    color: myRed,
    display: "flex",
    justifyContent: "center",
  },
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "black",
    },
  },
  removeMargin: {
    margin: 0,
    padding: theme.spacing(1.5, 0, "!important"),
    marginTop: 12,
  },
  alightRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    minWidth: 650,
  },
  gridClasses: {
    gridAutoColumns: "minmax(600px, 4fr)",
  },
  rowClasses: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  cellClasses: {
    "&:nth-child(4n)": {
      borderRight: "unset",
    },
  },
  grid7ColumnsClasses: {
    gridAutoColumns: "minmax(1200px, 7fr)",
  },
  row7ColumnsClasses: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
  },
  cell7ColumnsClasses: {
    "&:nth-child(8n)": {
      borderRight: "unset",
    },
  },
  gridSpacer: {
    paddingBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));
