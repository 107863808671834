import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useInfoDialogStyle = makeStyles((_: Theme) => ({
  root: {
    justifyContent: "center",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  }
}));
