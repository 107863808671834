import { useMemo } from "react";
import VrsDeviceApi from "../api/prodVrsDeviceApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import { IEventHistoryFilter } from "../interfaces/AppGlobal/IEventHistoryFilter";
import { ISnapshotFilter } from "../interfaces/AppGlobal/ISnapshotFilter";
import { ISupportRequestObj } from "../interfaces/DeviceInfo/ISupportRequestObj";
import { ITimeRange } from "../interfaces/Device/ITimeRange";
import { parseTabLimitedFileData } from "../utilities/utils";
import { SET_COMPANY_DEVICES_LOADED } from "./actionTypes";
import { IServiceLogInput } from "../interfaces/IServiceLog";

function loadAppSyncVrsDeviceFull(
  deviceId,
  eventHistoryFilter: IEventHistoryFilter,
  snapshotFilter: ISnapshotFilter
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceFull(
      deviceId,
      eventHistoryFilter,
      snapshotFilter
    )
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDeviceBasic(deviceId) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceBasic(deviceId)
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDataTagValues(deviceId) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDataTagValues(deviceId)
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDataTagSimpleValues(deviceId) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDataTagSimpleValues(deviceId)
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDataTagDetailValues(deviceId, dataTagNames) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDataTagDetailValues(deviceId, dataTagNames)
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDeviceEventHistory(
  deviceId,
  eventHistoryFilter: IEventHistoryFilter
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceEventHistory(
      deviceId,
      eventHistoryFilter
    )
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDeviceParameterHistory(
  deviceId,
  eventHistoryFilter: IEventHistoryFilter
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceParameterHistory(
      deviceId,
      eventHistoryFilter
    )
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDeviceTimeInStatus(
  deviceId,
  eventHistoryFilter: IEventHistoryFilter
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceTimeInStatus(
      deviceId,
      eventHistoryFilter
    )
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function loadAppSyncVrsDeviceSnapshotEvents(
  deviceId,
  snapshotFilter: ISnapshotFilter
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceEvents(deviceId, snapshotFilter)
      .then((vrsDevice) => {
        return vrsDevice;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getPrinterCookie(deviceId) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDeviceCookie(deviceId)
      .then((vrsDeviceSimple) => {
        if (
          vrsDeviceSimple &&
          vrsDeviceSimple.data &&
          vrsDeviceSimple.data.device
        ) {
          return vrsDeviceSimple.data.device.PrinterCookie;
        }
        return "";
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function setDeviceLoaded(dispatch, loadedStatus) {
  dispatch({ type: SET_COMPANY_DEVICES_LOADED, payload: { loadedStatus } });
}

function loadAppSyncVrsDevices(
  companyId: string,
  siteId: string,
  timeRange: ITimeRange,
  serialNumber: string,
) {
  return (dispatch) => {
    setDeviceLoaded(dispatch, false);
    return VrsDeviceApi.getAppSyncDevices(
      companyId,
      siteId,
      timeRange,
      serialNumber,
    )
      .then((vrsDevices) => {
        return vrsDevices;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      })
      .finally(() => {
        setDeviceLoaded(dispatch, true);
      });
  };
}

function loadAppSyncVrsDevicesSimple(
  companyId: string,
  siteId: string,
  timeRange: ITimeRange,
  serialNumber: string,
) {
  return (dispatch) => {
    setDeviceLoaded(dispatch, false);
    return VrsDeviceApi.getAppSyncDevicesSimple(
      companyId,
      siteId,
      timeRange,
      serialNumber,
    )
      .then((vrsDevices) => {
        return vrsDevices;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      })
      .finally(() => {
        setDeviceLoaded(dispatch, true);
      });
  };
}

function loadAppSyncVrsDevicesTimeRangeData(
  companyId: string,
  siteId: string,
  timeRange: ITimeRange,
  serialNumber: string,
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDevicesTimeRangeData(
      companyId,
      siteId,
      timeRange,
      serialNumber,
    )
      .then((vrsDevices) => {
        return vrsDevices;
      })
      .catch((error) => {
        throw error;
      });
  };
}

function loadAppSyncTrend(
  printerId: string,
  trendName: string,
  snapshotFilter: ISnapshotFilter,
  statusChartType: string
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncTrend(
      printerId,
      trendName,
      snapshotFilter,
      statusChartType
    )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getServiceLogList(printerId: string) {
  return (_) => {
    return VrsDeviceApi.getAppSyncServiceLogList(printerId).then((data) => {
      if (data && data.serviceLogs) {
        return data.serviceLogs;
      } else {
        return [];
      }
    });
  };
}

function updateServiceLogEntry(
  printerId: string,
  serviceLogInput: IServiceLogInput
) {
  return (_) => {
    return VrsDeviceApi.AppSyncUpdateServiceLogEntry(
      printerId,
      serviceLogInput
    ).then((data) => {
      if (data && data.updateServiceLog) {
        return data.updateServiceLog;
      } else {
        return [];
      }
    });
  };
}

function addServiceLogEntry(
  printerId: string,
  status: string,
  description: string,
  title: string
) {
  return (_) => {
    return VrsDeviceApi.AppSyncAddServiceLogEntry(
      printerId,
      status,
      description,
      title
    ).then((data) => {
      if (data && data.addServiceLog) {
        return data.addServiceLog;
      } else {
        return [];
      }
    });
  };
}

function getLogDataDownload(
  printerId: string,
  eventDate: string,
  siteId: string,
  serialNumber: string,
  eventId: string
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncDiagnosticLogDownload(
      printerId,
      eventDate,
      siteId,
      serialNumber,
      eventId
    )
      .then((data) => {
        if (data && data.faultDiagnosticLog) {
          const { logFieldNames, logData } = parseTabLimitedFileData(
            data.faultDiagnosticLog
          );
          return { logFieldNames, logData };
        }

        return { logFieldNames: [], logData: null };
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getMiniDataDownload(
  printerId: string,
  eventDate: string,
  siteId: string,
  serialNumber: string,
  eventId: string
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncMiniDataDownload(
      printerId,
      eventDate,
      siteId,
      serialNumber,
      eventId
    )
      .then((data) => {
        if (data) {
          return { data: data.items };
        }

        return { data: null };
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function checkForLogFileDownload(
  printerId: string,
  eventDate: string,
  siteId: string,
  serialNumber: string,
  eventId: string
) {
  return (_) => {
    return VrsDeviceApi.checkForAppSyncDiagnosticLogDownload(
      printerId,
      eventDate,
      siteId,
      serialNumber,
      eventId
    )
      .then((data: boolean) => {
        if (data) {
          return { data: data };
        }

        return { data: false };
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function getPrinterTestResult(
  printerId: string,
  utcDateTime: string,
  eventCodeId: string,
  eventId: string
) {
  return (_) => {
    return VrsDeviceApi.getAppSyncPrinterTestResult(
      printerId,
      utcDateTime,
      eventCodeId,
      eventId
    )
      .then((data: any) => {
        if (data) {
          return { data: data };
        }

        return { data: false };
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function searchPlantAppSync(searchTerm: string) {
  return (_) => {
    return VrsDeviceApi.searchPlantAppSync(searchTerm)
      .then((plants) => {
        return plants;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function askSupportAppSync(supportRequestInput: ISupportRequestObj) {
  return (_) => {
    return VrsDeviceApi.askSupport(supportRequestInput)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

export const useVrsDeviceActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      askSupportAppSync: (supportRequestInput: ISupportRequestObj) =>
        askSupportAppSync(supportRequestInput)(dispatch),

      searchPlantAppSync: (searchTerm) =>
        searchPlantAppSync(searchTerm)(dispatch),

      loadAppSyncVrsDeviceFull: (
        deviceId,
        eventHistoryFilter: IEventHistoryFilter,
        snapshotFilter: ISnapshotFilter
      ) =>
        loadAppSyncVrsDeviceFull(
          deviceId,
          eventHistoryFilter,
          snapshotFilter
        )(dispatch),

      loadAppSyncVrsDeviceBasic: (deviceId) =>
        loadAppSyncVrsDeviceBasic(deviceId)(dispatch),

      loadAppSyncVrsDeviceEventHistory: (
        deviceId,
        eventHistoryFilter: IEventHistoryFilter
      ) =>
        loadAppSyncVrsDeviceEventHistory(
          deviceId,
          eventHistoryFilter
        )(dispatch),
      loadAppSyncVrsDeviceParameterHistory: (
        deviceId,
        eventHistoryFilter: IEventHistoryFilter
      ) =>
        loadAppSyncVrsDeviceParameterHistory(
          deviceId,
          eventHistoryFilter
        )(dispatch),

      loadAppSyncVrsDeviceTimeInStatus: (
        deviceId,
        eventHistoryFilter: IEventHistoryFilter
      ) =>
        loadAppSyncVrsDeviceTimeInStatus(
          deviceId,
          eventHistoryFilter
        )(dispatch),

      loadAppSyncVrsDeviceSnapshotEvents: (
        deviceId,
        snapshotFilter: ISnapshotFilter
      ) => loadAppSyncVrsDeviceSnapshotEvents(deviceId, snapshotFilter)(dispatch),
      getPrinterCookie: (deviceId) => getPrinterCookie(deviceId)(dispatch),
      loadAppSyncVrsDevices: (
        companyId,
        siteId,
        timeRange: ITimeRange,
        serialNumber,
      ) =>
        loadAppSyncVrsDevices(
          companyId,
          siteId,
          timeRange,
          serialNumber,
        )(dispatch),

      loadAppSyncVrsDevicesSimple: (
        companyId,
        siteId,
        timeRange: ITimeRange,
        serialNumber,
      ) =>
        loadAppSyncVrsDevicesSimple(
          companyId,
          siteId,
          timeRange,
          serialNumber,
        )(dispatch),

      loadAppSyncVrsDataTagValues: (deviceId) =>
        loadAppSyncVrsDataTagValues(deviceId)(dispatch),

      loadAppSyncVrsDataTagSimpleValues: (deviceId) =>
        loadAppSyncVrsDataTagSimpleValues(deviceId)(dispatch),

      loadAppSyncVrsDataTagDetailValues: (deviceId, dataTagNames) =>
        loadAppSyncVrsDataTagDetailValues(deviceId, dataTagNames)(dispatch),

      loadAppSyncVrsDevicesTimeRangeData: (
        companyId,
        siteId,
        timeRange: ITimeRange,
        serialNumber,
      ) =>
        loadAppSyncVrsDevicesTimeRangeData(
          companyId,
          siteId,
          timeRange,
          serialNumber,
        )(dispatch),

      loadAppSyncTrend: (
        printerId: string,
        trendName: string,
        snapshotFilter: ISnapshotFilter,
        statusChartType: string
      ) =>
        loadAppSyncTrend(
          printerId,
          trendName,
          snapshotFilter,
          statusChartType
        )(dispatch),
      getMiniDataDownload: (
        printerId,
        eventDate,
        siteId: string,
        serialNumber: string,
        eventId: string
      ) =>
        getMiniDataDownload(
          printerId,
          eventDate,
          siteId,
          serialNumber,
          eventId
        )(dispatch),
      checkForLogFileDownload: (
        printerId,
        eventDate,
        siteId: string,
        serialNumber: string,
        eventId: string
      ) =>
        checkForLogFileDownload(
          printerId,
          eventDate,
          siteId,
          serialNumber,
          eventId
        )(dispatch),
      getPrinterTestResult: (
        printerId: string,
        utcDateTime: string,
        eventCodeId: string,
        eventId: string
      ) =>
        getPrinterTestResult(
          printerId,
          utcDateTime,
          eventCodeId,
          eventId
        )(dispatch),
      getLogDataDownload: (
        printerId,
        eventDate,
        siteId: string,
        serialNumber: string,
        eventId: string
      ) =>
        getLogDataDownload(
          printerId,
          eventDate,
          siteId,
          serialNumber,
          eventId
        )(dispatch),
      getServicelogList: (printerId) => getServiceLogList(printerId)(dispatch),
      AddServicelogEntry: (printerId, description, status, title) =>
        addServiceLogEntry(printerId, status, description, title)(dispatch),
      UpdateServiceLogEntry: (printerId, serviceLogInput) =>
        updateServiceLogEntry(printerId, serviceLogInput)(dispatch),
    }),
    [dispatch]
  );
};
