import { isTimescaleUrl } from "../utilities/utils";

export const cleanObject = (object: { [x: string]: any }) => {
  const n = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      n[key] = object[key];
    }
  });
  return n;
};

export const graphQlOptionsArg = () => {
  return `options: ${isTimescaleUrl() ? '["ts"]' : "[]"}`;
};

export function timeScaleOptions() {
  const timescaleOptions = graphQlOptionsArg();
  return [
    timescaleOptions,
    timescaleOptions.includes("ts") ? "WithTimescale" : "",
  ];
}

/**
 * Creates a string of input fields for a GraphQL query from an input object.
 *
 * @param {Object} input - The input object containing key-value pairs.
 * @returns {string} - A string of input fields for a GraphQL query.
 */
export const createInputFields = (input: { [key: string]: any }): string => {
  return Object.entries(input)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}: [${value.map(v => `"${v}"`).join(', ')}]`;
      }
      return `${key}: ${value}`;
    })
    .join(', ');
};

/**
 * Creates a selection set string for a GraphQL query from an array of selection fields.
 *
 * @param {string[]} selectionSet - An array of selection fields.
 * @returns {string} - A string of selection fields for a GraphQL query.
 */
export const createSelectionSet = (selectionSet: string[]): string => {
  return selectionSet.join('\n');
};
