import makeStyles from "@mui/styles/makeStyles";
import { myCorporatePrimaryColor, myRed, mySecondaryColor, myWhite } from "../../constants/colors";

import loginImage from "../../loginImages/my_login_background.png";
import { Theme } from "@mui/material";

export const useLoginStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${loginImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: myWhite,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(30)
    },
    padding: theme.spacing(0.625)
  },
  logo: {
    minWidth: theme.spacing(75),
    [theme.breakpoints.down('md')]: {
      minWidth: theme.spacing(62.5),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(50),
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(25),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%'
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: theme.spacing(35),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    maxWidth: theme.spacing(20)
  },
  email: {
    maxWidth: theme.spacing(70)
  },
  password: {
    maxWidth: theme.spacing(70)
  },
  errorMessage: {
    textAlign: "center",
    color: myRed
  },
  loginBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "column"
  },
  backBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  newAccountDivider: {
    marginBottom: theme.spacing(2)
  },
  newAccountHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  newAccountIcon: {
    color: mySecondaryColor,
    marginRight: theme.spacing(0.5)
  },
  usernameText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: myCorporatePrimaryColor,
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    minHeight: '30px'
  },
  emptyDiv: {
    minHeight: '47px'
  },
  smallDiv: {
    minHeight: '20px'
  },
  extra: {
    minHeight: '80px'
  }
}));
