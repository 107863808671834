import { DialogProps } from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { useConfirmDialogStyle } from "./ConfirmDialog.css";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../StyledDialogTitle/StyledDialogTitle";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface IConfirmDialogProps {
  title: any;
  open: boolean;
  onClose: (value: boolean) => void;
  content: any;
  saving?: boolean;
  maxWidth?: DialogProps["maxWidth"];
}

const ConfirmDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content,
  saving,
}: IConfirmDialogProps) => {
  const classes = useConfirmDialogStyle();
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const { _T } = useVrsTranslationState();

  return (
    <DialogWithBackdropClick
      disableBackdropClick
      classes={{ paper: classes.root }}
      fullWidth={true}
      maxWidth={maxWidth || "xs"}
      disableEscapeKeyDown
      aria-labelledby="confirm"
      open={open}
    >
      <StyledDialogTitle id="confirm-dialog-title">
        <div>{title}</div>
      </StyledDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          autoFocus
          onClick={handleOk}
          data-testid="confirm-ok-btn"
          variant="contained"
          disabled={saving}
          color="primary"
        >
          {_T("Yes")}
        </Button>
        <Button
          onClick={handleCancel}
          color="secondary"
          data-testid="confirm-cancel-btn"
          disabled={saving}
          variant="contained"
        >
          {_T("No")}
        </Button>
      </DialogActions>
    </DialogWithBackdropClick>
  );
};

export { ConfirmDialog };
