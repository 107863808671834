import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { usePageTitleStyle } from "./PageTitle.css";
import { HeaderTitleInfo } from "../../interfaces/HeaderTitleInfo";
import {
  useConfigState,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import LinkToUrl from "../Basic/LinkToUrl/LinkToUrl";

interface PageTitleProps extends HeaderTitleInfo {
  breadcrumbVisible: boolean;
  titleVisible: boolean;
  textOnly?: boolean;
}

function PageTitle({
  titleId,
  icon,
  linkParams,
  breadcrumbVisible,
  textOnly,
  titleVisible,
}: PageTitleProps) {
  const classes = usePageTitleStyle();
  const { _T } = useVrsTranslationState();
  const [breadCrumbData, setBreadCrumbData] = useState<any>(null);

  const configState = useConfigState();

  useEffect(() => {
    if (titleId && configState.locale) {
      setBreadCrumbData(linkParams || {});
    }
  }, [titleId, linkParams, configState.locale, textOnly, titleVisible]);

  return (
    <>
      <div className={classes.appPageTitle}>
        {titleVisible && (
          <div className={classes.appPageTitleHeading}>
            <div className={classes.itemParent}>
              <div className={classes.mainIcon}> {icon}</div>
              <span className={classes.appPageTitleHeadingText}>
                {textOnly ? titleId : _T(titleId)}
              </span>
            </div>
          </div>
        )}
        {breadcrumbVisible && (
          <div className={classes.appPageTitleHeading}>
            {breadCrumbData ? (
              <>
                {breadCrumbData.parents &&
                  breadCrumbData.parents.map((el, index) => (
                    <div
                      key={`${el.id}-${index}`}
                      className={classes.itemParent}
                    >
                      {index !== 0 && (
                        <div className={classes.separator}>
                          <ArrowForwardIosIcon fontSize="small" />
                        </div>
                      )}
                      <div className={classes.breadcrumbItem}>
                        {el.link ? (
                          <LinkToUrl to={el.link}>
                            <span className={classes.link}>
                              {el.textOnly ? el.id : el.id && _T(el.id)}
                            </span>
                          </LinkToUrl>
                        ) : (
                          <span className={classes.item}>
                            {el.textOnly ? el.id : el.id && _T(el.id)}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                {!breadCrumbData.ignoreItself && (
                  <div className={classes.itemParent}>
                    <div className={classes.separator}>
                      <ArrowForwardIosIcon fontSize="small" />
                    </div>
                    <div className={classes.breadcrumbItem}>
                      <span className={classes.item}>
                        {" "}
                        {textOnly ? titleId : _T(titleId)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}

export default PageTitle;
