import {
  LOAD_COMPANY_SUCCESS,
  LOAD_COMPANIES_SUCCESS,
  SET_COMPANY_ID_AND_SITE_ID,
  SET_COMPANY_ID,
  SET_COMPANY_SITE_ID,
  LOAD_COMPANIES_INITIATED,
  LOAD_COMPANIES_FAILED,
  LOAD_SITES_FOR_COMPANY_INITIATED,
  LOAD_SITES_FOR_COMPANY_SUCCESS,
  SET_SITES_FOR_COMPANY,
  LOAD_SITES_FOR_COMPANY_FAILED,
  RESET_SITES_FOR_COMPANY,
  SET_COMPANY_DEVICES_LOADED,
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import Utils from "../../utilities/utils";

export interface ICompanyState {
  companies: Array<any>;
  companySites: Array<any>;
  selectedCompanyId: string;
  selectedSiteIdForCompany: string;
  getSelectedCompany: () => any;
  companyInitiated: boolean;
  companyLoaded: boolean;
  sitesInitiated: boolean;
  sitesLoaded: boolean;
  companyDevicesLoaded: boolean;
}
export const initialCompanyState: ICompanyState = {
  companies: [],
  companySites: [],
  selectedCompanyId: "0",
  selectedSiteIdForCompany: "0",
  companyInitiated: false,
  companyLoaded: false,
  sitesInitiated: false,
  sitesLoaded: false,
  companyDevicesLoaded: false,
  getSelectedCompany: function () {
    return this.companies.find(
      (company) => company.companyId === this.selectedCompanyId
    );
  },
};

export function companyReducer(
  state: ICompanyState = initialCompanyState,
  action: IAppGlobalAction
): ICompanyState {
  switch (action.type) {
    case LOAD_COMPANY_SUCCESS: {
      const currentCompany = state.companies.find(
        (company) => company.companyId === action.payload.companyId
      );
      if (currentCompany) {
        return {
          ...state,
          selectedCompanyId: currentCompany.companyId,
          companies: state.companies.map((company) => {
            if (company.companyId === action.payload.companyId) {
              return {
                ...company,
                ...action.payload,
              };
            }
            return company;
          }),
        };
      }

      return {
        ...state,
        companies: state.companies.concat([action.payload]),
        selectedCompanyId: action.payload.companyId,
      };
    }

    case LOAD_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: [...action.payload],
        companyLoaded: true,
      };
    }

    case SET_COMPANY_ID_AND_SITE_ID: {
      Utils.setCompanyAndPlantCookie(action.payload.companyId, action.payload.siteId);
      return {
        ...state,
        selectedCompanyId: action.payload.companyId,
        selectedSiteIdForCompany: action.payload.siteId,
      };
    }

    case SET_COMPANY_DEVICES_LOADED: {
      return {
        ...state,
        companyDevicesLoaded: action.payload.loadedStatus
      };
    }

    case SET_COMPANY_SITE_ID: {
      Utils.setPlantCookie(action.payload.siteId);
      return {
        ...state,
        selectedSiteIdForCompany: action.payload.siteId,
      };
    }

    case SET_COMPANY_ID: {
      Utils.setCompanyCookie(action.payload.companyId);
      return {
        ...state,
        selectedCompanyId: action.payload.companyId,
      };
    }

    case LOAD_COMPANIES_INITIATED:
      return {
        ...state,
        companyInitiated: true,
      };

    case LOAD_COMPANIES_FAILED:
      return {
        ...state,
        companyLoaded: false,
        companyInitiated: false,
      };

    case LOAD_SITES_FOR_COMPANY_INITIATED:
      return {
        ...state,
        sitesInitiated: true,
        sitesLoaded: false,
      };

    case LOAD_SITES_FOR_COMPANY_SUCCESS:
      return {
        ...state,
        sitesLoaded: true,
        companySites: action.payload,
      };

    case SET_SITES_FOR_COMPANY:
      return {
        ...state,
        companySites: action.payload,
      };

    case LOAD_SITES_FOR_COMPANY_FAILED:
      return {
        ...state,
        sitesLoaded: false,
        sitesInitiated: false,
        companySites: [],
      };

    case RESET_SITES_FOR_COMPANY:
      console.log('RESET_SITES_FOR_COMPANY=', RESET_SITES_FOR_COMPANY);
      return {
        ...state,
        companySites: [],
      };

    default: {
      return state;
    }
  }
}
