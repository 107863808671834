
import { LinearProgress } from '@mui/material';
import { useSimpleLinearProgressStyles } from './SimpleLinearProgress.css';


const SimpleLinearProgress = () => {
  const classes = useSimpleLinearProgressStyles();
  return <div className={classes.root}>
  <div className={classes.linearProgress}>
    <LinearProgress />
  </div></div>
}

export { SimpleLinearProgress };

