import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";


export default makeStyles((theme: Theme)=> ({
  root: {
    display: "flex",
    height: '100%',
  },
  headerSpace: {
    minHeight: '70px',
    [theme.breakpoints.down('md')]: {
      minHeight: '90px',
    }
  },
  headerSpaceExtra: {
    minHeight: '90px',
    [theme.breakpoints.down('md')]: {
      minHeight: '120px',
    }
  },
  contentContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    zIndex: 30,
    [theme.breakpoints.only("xl")]: {
      minHeight: 'calc(100vh - 5px)',
    },
    [theme.breakpoints.down("xl")]: {
      minHeight: 'calc(100vh - 5px)',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 8px)',
    }
  },
  content: {
    flex: '1 0 auto',
    padding: theme.spacing(1, 0.75, 2.5, 0.75),
    overflow: 'hidden',
    height: '90%'
  },
  footer: {
    flexShrink: 0,
    padding: theme.spacing(0, 1.5),
    width: '100%',
  },
  displayModeContainer: {
    margin: theme.spacing(1),
    width: '100%',
    height: '100%',
  },
  displayModeContent: {
    padding: theme.spacing(1, 1.5),
    width: '100%',
    height: '100%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'hidden'
    }
  }
}));
