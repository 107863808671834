import { invokeApig } from '../libs/authLib';
import { getApiPrefix } from '../libs/apiLib';

const baseUrlReasons = () => getApiPrefix() + '/downtimeReasons';
const baseUrlEvents = () => getApiPrefix() + '/downtimeEvents';

class DowntimeApi {
  static getAllDowntimeReasons() {
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: baseUrlReasons() });
  }

  static getDowntimeReasons(siteId) {
    const apiUrl = `${baseUrlReasons()}/getReasons`;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, queryParams: { siteId } });
  }

  static getDowntimeReason(siteId, id) {
    const apiUrl = `${baseUrlReasons()}/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, queryParams: { siteId } });
  }

  static saveDowntimeReason(downtimeReason) {
    if (downtimeReason.id === '') delete downtimeReason.id;
    // VCCM-1691 optional description
    if (downtimeReason.description === '') delete downtimeReason.description;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: baseUrlReasons(), method: 'POST', body: downtimeReason });
  }

  static updateDowntimeReason(downtimeReason) {
    const apiUrl = `${baseUrlReasons()}/${downtimeReason.id}`;
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (downtimeReason.description === '') downtimeReason.description = null;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, method: 'PUT', queryParams: { siteId: downtimeReason.siteId }, body: downtimeReason });
  }

  static deleteDowntimeReason(siteId, id) {
    const apiUrl = `${baseUrlReasons()}/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_REASONS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static getAllDowntimeEvents() {
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: baseUrlEvents() });
  }

  static getDowntimeEventsBy(siteId, lineId, startTime, endTime, interval) {
    const apiUrl = `${baseUrlEvents()}/getEventsBy`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId, lineId, startDateTime: startTime, endDateTime: endTime, interval, version: 2 } });
  }

  static getDowntimeEventsByLineList(siteId, lineIds, startTime, endTime, interval) {
    const apiUrl = `${baseUrlEvents()}/getEventsBy`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId, lineIds, startDateTime: startTime, endDateTime: endTime, interval, version: 2 } });
  }

  static getDowntimeEvent(siteId, id) {
    const apiUrl = `${baseUrlEvents()}/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, queryParams: { siteId } });
  }

  static saveDowntimeEvent(siteId, updates, originalDowntimeEvent) {
    const apiUrl = `${baseUrlEvents()}/${originalDowntimeEvent.lineId}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, method: 'PUT', body: updates, queryParams: { startDt: originalDowntimeEvent.startDt, siteId } });
  }

  static deleteDowntimeEvent(siteId, id) {
    const apiUrl = `${baseUrlEvents()}/${id}`;
    return invokeApig({ gatewayName: 'DOWNTIMES_EVENTS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }
}

export default DowntimeApi;
