import { useState, useEffect } from 'react';
import { ITrackerValue } from '../../../interfaces/User/ITrackerValue';

// de-bouncer hook
export const useTrackerDebounce = function (value: ITrackerValue, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
