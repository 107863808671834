import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { requiredColor } from "./colors";

export const useGlobalStyles = makeStyles((theme: Theme) => ({
  required: {
    color: requiredColor,
    fontSize: theme.spacing(1.5),
  },
  formControl: {
    display: "flex",
    flexFlow: "row",
  },
}));
