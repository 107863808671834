import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";

const baseUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["RES_APPSYNC_URL"];
};
class ResRequestPrinterLogsApi {
  static sendAppSyncLogConfig(
    base64String: any,
    fileName: string,
    target: string,
    resName: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query SendLogConfigQuery {
          sendLogConfig(RemoteFile: {
              BinaryData: "${base64String}",
              FileName: "${fileName}"} ,
              ResName: "${resName}",
              Target: "${target}"
              ) {
            Result,
            Reason
          }
        }

        `,
    };

    return invokeAppSync({
      gatewayName: "RES_APPSYNC",
      path: baseUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data) {
        return {
          results: results.data,
        };
      }
    });
  }
  static getAppSyncRequestPrinterLogs(
    resName: string,
    printerId: string,
    logTypes: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query LogUploadRequestQuery {
          logUploadRequest(resName: "${resName}", printerSerial: "${printerId}", types: "${logTypes}") {
            Result,
            Reason
          }
        }

        `,
    };

    return invokeAppSync({
      gatewayName: "RES_APPSYNC",
      path: baseUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data) {
        return {
          results: results.data,
        };
      }
    });
  }
}

export default ResRequestPrinterLogsApi;
