import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useConfirmDialogStyle = makeStyles((_: Theme) => ({
  root: {
    padding: 0,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));
