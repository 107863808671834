export enum VrsAccessLevels {
  Administrator = "Administrator",
  Maintenance = "Maintenance",
  User = "User",
  VideojetContractAdmin = "Videojet-ContractAdmin",
  VideojetService = "Videojet-Service",
  Videojet = "Videojet",
  VideojetCSE = "Videojet-CSE",
  VideojetSalesUser = "Videojet-SalesUser",
}
