import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { ISetBoolean } from "../../interfaces/AppGlobal/ISetBoolean";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { IAppGlobalDispatch } from "../../interfaces/AppGlobal/IAppGlobalDispatch";
import { IStorage } from "../../interfaces/Storage/IStorage";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_SITES,
  RESTORE_USER_FROM_SESSION_SUCCESS,
  RESET_TO_INITIAL_STATE,
  SET_HOME_SELECTION_MODE,
  SET_DEFAULT_PAGE_REQUIRED,
  SET_HEADER_TITLE,
  RESET_SITE_DATA,
  RESET_APP_DATA,
} from "../../actions/actionTypes";

import { signOutUser } from "../../libs/authLib";
import {
  englishUsLocale,
  LANGUAGE_REFRESH_MINUTES,
  PROFILE_REFRESH_MINUTES,
  supportedLocals,
} from "../../constants/global";
import {
  rootReducer,
  IRootState,
} from "../../reducers/rootReducer/rootReducer";
import { ISiteState } from "../../reducers/siteReducer/siteReducer";
import { IUserState } from "../../reducers/userReducer/userReducer";
import { IConfigState } from "../../reducers/configReducer/configReducer";
import { IAppGlobalState } from "../../interfaces/AppGlobal/IAppGlobalState";
import { ICompanyState } from "../../reducers/companyReducer/companyReducer";
import Utils, { getLanguageCodeStr, getLanguageLongStr } from "../../utilities/utils";
import { useLocalStorage } from "../LocalStorageContext/LocalStorageContext";
import { useConfigActions } from "../../actions/configActions";
import { useVrsUserActions } from "../../actions/vrsUserActions";
import { IModelState } from "../../reducers/modelReducer/modelReducer";
import { ISalesforceState } from "../../reducers/salesforceReducer/salesforceReducer";
import { compressLZW, decompressLZW } from "../../utilities/stringutils";
import { refreshDesignAccessToken } from "../../api/Design/DesignActions";

const initialRootState = rootReducer(
  {},
  { type: RESET_TO_INITIAL_STATE, payload: null }
);


export const AppGlobalStateContext =
  createContext<IRootState>(initialRootState);
const AppGlobalDispatchContext = createContext((_: IAppGlobalAction) => {});

function AppGlobalProvider({ children }: { children: ReactElement }) {
  const [state, dispatch] = useReducer(rootReducer, initialRootState);

  useEffect(() => {
    let requestInterceptor, responseInterceptor;
    if (state.userState.isAuthenticated) {
      requestInterceptor = Axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          if (config.headers?.designApi) {
            const accessToken = state.userState.designToken; 
            const activeSiteId = state.companyState.selectedSiteIdForCompany === "0" ? "" : state.companyState.selectedSiteIdForCompany;
            // Check if the token is expired before making the request
            const { userProfileLarge } = state.userState;
            const isTokenInvalid = !accessToken || Utils.IsTokenExpired(accessToken) || !Utils.DoesTokenBelongToActiveSite(accessToken, activeSiteId);
            if (activeSiteId && isTokenInvalid && userProfileLarge) {
              try {
                const isExternal = state.configState.appDataInitialization.firstSiteData.isExternal;
                const newAccessToken = await refreshDesignAccessToken(dispatch, activeSiteId, isExternal, userProfileLarge);
                config.headers['Authorization'] = `Bearer ${newAccessToken}`;
              } catch (error) {
                // Handle the error, such as redirecting to login page
                console.error('Error refreshing token before request:', error);
                return Promise.reject(error);
              }
            } else if (accessToken) {
              config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
          }
          return config;
        },
        (error: AxiosError) => {
          return Promise.reject(error);
        }
      );

      responseInterceptor = Axios.interceptors.response.use(
        (response: AxiosResponse) => {
          return response;
        },
        async (error: AxiosError) => {
          const originalRequest = error.config;
          if (error.response && originalRequest.headers?.designApi && error.response.status === 401 && !originalRequest["_retry"]) {
            originalRequest["_retry"] = true;  // Prevent infinite retries
      
            try {
              const activeSiteId = state.companyState.selectedSiteIdForCompany === "0" ? "" : state.companyState.selectedSiteIdForCompany;
              const { userProfileLarge } = state.userState;
              const isExternal = state.configState.appDataInitialization.firstSiteData.isExternal;
              const newAccessToken = await refreshDesignAccessToken(dispatch, activeSiteId, isExternal, userProfileLarge);

              // Update the original request with the new token and retry the request
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              return Axios(originalRequest);
            } catch (refreshError) {
              // If refreshing fails, handle it (e.g., logout the user)
              console.error('Error refreshing token after 401:', refreshError);
              return Promise.reject(refreshError);
            }
          }
          else if (
            error.response &&
            error.response.data &&
            error.response.data.errorId
          ) {
            const message = error.response.data.errorId;
            return Promise.reject({ message, status: error.response.status });
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            return Promise.reject({
              message: error.response.data.message,
              status: error.response.status,
            });
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            return Promise.reject({
              message: error.response.data.error,
              status: error.response.status,
            });
          } else if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            return Promise.reject({
              message: error.response.data,
              status: error.response.status,
            });
          } else {
            return Promise.reject(error);
          }
        }
      );
    } else {
      dispatch({ type: SET_SITES, payload: [] });
    }
    
    return () => {
      // we do not want multiple interceptors
      if (requestInterceptor !== undefined) {
        Axios.interceptors.request.eject(requestInterceptor);
      }

      if (responseInterceptor !== undefined ) {
        Axios.interceptors.response.eject(responseInterceptor);
      }
    }
  }, [dispatch, state.userState.isAuthenticated, state.userState.userProfileLarge, state.companyState.selectedSiteIdForCompany, state.userState.designToken, refreshDesignAccessToken ]);

  return (
    <AppGlobalStateContext.Provider value={state}>
      <AppGlobalDispatchContext.Provider value={dispatch}>
        {children}
      </AppGlobalDispatchContext.Provider>
    </AppGlobalStateContext.Provider>
  );
}

function useAppGlobalState(): IAppGlobalState {
  try {
    const context: IRootState = useContext(AppGlobalStateContext);
    return {
      ...context.configState,
      ...context.siteState,
      ...context.userState,
    };
  } catch (err) {
    throw new Error(
      "useAppGlobalState must be used within a functional component"
    );
  }
}

function useAppCompanyState(): ICompanyState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.companyState;
  } catch (err) {
    throw new Error(
      "useAppCompanyState must be used within a functional component"
    );
  }
}

function useAppModelState(): IModelState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.modelState;
  } catch (err) {
    throw new Error(
      "useAppModelState must be used within a functional component"
    );
  }
}

function useAppSiteState(): ISiteState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.siteState;
  } catch (err) {
    throw new Error(
      "useAppSiteState must be used within a functional component"
    );
  }
}

function useAppSelectedSite(): any {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.siteState.getSelectedSite();
  } catch (err) {
    throw new Error(
      "useAppSiteState must be used within a functional component"
    );
  }
}

function useAppUserState(): IUserState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.userState;
  } catch (err) {
    throw new Error(
      "useAppUserState must be used within a functional component"
    );
  }
}

function useAppConfigState(): IConfigState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.configState;
  } catch (err) {
    throw new Error(
      "useAppConfigState must be used within a functional component"
    );
  }
}

function useAppSalesforceState(): ISalesforceState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.salesforceState;
  } catch (err) {
    throw new Error(
      "useAppSalesforceState must be used within a functional component"
    );
  }
}

function useAppConfigSelector(_T): any {
  try {
    const context = useContext(AppGlobalStateContext);
    return useMemo(
      () => ({
        inspectionFreqTypes: context.configState.inspectionFreqTypes.map(
          (i) => ({
            value: i.value.toString(),
            text: _T(i.transId),
          })
        ),
        inspectionStepTypes: context.configState.inspectionStepTypes.map(
          (i) => ({
            value: i.value.toString(),
            text: _T(i.transId),
          })
        ),
        inspectionRefCompareTypes:
          context.configState.inspectionRefCompareTypes.map((i) => ({
            value: i.value.toString(),
            text: i.text,
          })),
      }),
      [context.configState, _T]
    );
  } catch (err) {
    throw new Error(
      "useAppConfigSelector must be used within a functional component"
    );
  }
}

function useAppLineState(): Array<any> {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.lineState;
  } catch (err) {
    throw new Error(
      "useAppLineState must be used within a functional component"
    );
  }
}

function useAppShiftState(): Array<any> {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.shiftState;
  } catch (err) {
    throw new Error(
      "useAppShiftState must be used within a functional component"
    );
  }
}

function useAppProductState(): Array<any> {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.productState;
  } catch (err) {
    throw new Error(
      "useAppProductState must be used within a functional component"
    );
  }
}

function useConfigState(): IConfigState {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.configState;
  } catch (err) {
    throw new Error(
      "useConfigState must be used within a functional component"
    );
  }
}

function useVrsTranslationState(): {
  _T: (identifier: string) => string;
  loadVrsTranslations: (locale: string, forceRefresh: boolean) => Promise<void>;
  loadVrsUserProfile: (forceRefresh: boolean) => Promise<string>;
} {
  const userLocalStorage = useLocalStorage();
  const vrsUserActions = useVrsUserActions();
  const configActions = useConfigActions();

  try {
    const context = useContext(AppGlobalStateContext);

    const fetchFunction = useCallback(
      (identifier: string) => {
        let locale = navigator.language.split(/[-_]/)[0];
        if (context.configState.vrsUserLocale) {
          locale = context.configState.vrsUserLocale;
        }

        const language = getLanguageLongStr(locale);

        if (
          context.configState.vrsTranslations &&
          context.configState.vrsTranslations[language.toLowerCase()]
        ) {
          const value =
            context.configState.vrsTranslations[language.toLowerCase()][
              identifier
            ];
          if (value) {
            return value;
          }

          if (
            context.configState.vrsTranslationsWithSmallIdentifier &&
            context.configState.vrsTranslationsWithSmallIdentifier[
              language.toLowerCase()
            ]
          ) {
            const smallIdentifierValue =
              context.configState.vrsTranslationsWithSmallIdentifier[
                language.toLowerCase()
              ][identifier ? identifier.toLowerCase() : identifier];
            if (smallIdentifierValue) {
              return smallIdentifierValue;
            }
          }

          if (
            context.configState.vrsTranslations["en-us"] &&
            Utils.isProductionLike()
          ) {
            const engValue =
              context.configState.vrsTranslations["en-us"][identifier];
            if (engValue) {
              return engValue;
            }
          }
        }

        if (
          !Utils.isProductionLike() &&
          identifier &&
          language.toLowerCase() !== "en-us"
        ) {
          return `_T(${identifier})`;
        }
        return identifier;
      },
      [
        context.configState.vrsTranslations,
        context.configState.vrsTranslationsWithSmallIdentifier,
        context.configState.vrsUserLocale,
      ]
    );

    const loadVrsTranslations = useCallback(
      async (locale: string, forceRefresh) => {
        // Get Vrs language items

        const language = getLanguageLongStr(locale);
        const culture = getLanguageCodeStr(locale);

        const languageItemsTime = userLocalStorage.getItem("languageItemsTime");
        const emptyTime = !languageItemsTime;

        let refreshLanguageItems = LANGUAGE_REFRESH_MINUTES * 60000 + 1;
        if (languageItemsTime) {
          refreshLanguageItems =
            new Date().getTime() - new Date(languageItemsTime).getTime();
        }

        if (language !== "en-US") {
          const languageItemIdentifier = `languageItems-${language.toLowerCase()}`;
          let languageItemsStr = userLocalStorage.getItem(
            languageItemIdentifier
          );

          if (languageItemsStr && languageItemsStr[0] === "[") {
            userLocalStorage.removeItem(languageItemIdentifier);
            languageItemsStr = "";
          }

          if (
            forceRefresh ||
            !languageItemsStr ||
            emptyTime ||
            refreshLanguageItems > LANGUAGE_REFRESH_MINUTES * 60000
          ) {
            try {
              userLocalStorage.setItem(
                "languageItemsTime",
                new Date().toISOString()
              );

              configActions.setIsLoading(true);
              const items = await vrsUserActions.getLanguageItemsAppSync(
                language,
                culture
              );

              const itemsTrimmed = items.map((el) => ({
                ...el,
                i: el.i ? el.i.trim() : el.i,
              }));

              languageItemsStr = JSON.stringify(itemsTrimmed);
              // Delete any other languages apart from English due to storage quota
              for (const locale of supportedLocals) {
                if (
                  locale !== language.toLowerCase() &&
                  locale !== englishUsLocale
                ) {
                  const identifier = `languageItems-${locale}`;
                  userLocalStorage.removeItem(identifier);
                }
              }

              userLocalStorage.setItem(
                languageItemIdentifier,
                compressLZW(languageItemsStr)
              );
              configActions.setLanguageItems(
                language.toLowerCase(),
                itemsTrimmed
              );
            } finally {
              configActions.setIsLoading(false);
            }
          } else {
            configActions.setLanguageItems(
              language.toLowerCase(),
              JSON.parse(decompressLZW(languageItemsStr))
            );
          }
        }

        let enLanguageItemsStr = userLocalStorage.getItem(
          "languageItems-en-us"
        );

        if (enLanguageItemsStr && enLanguageItemsStr[0] === "[") {
          userLocalStorage.removeItem(enLanguageItemsStr);
          enLanguageItemsStr = "";
        }

        if (
          forceRefresh ||
          !enLanguageItemsStr ||
          refreshLanguageItems > LANGUAGE_REFRESH_MINUTES * 60000
        ) {
          userLocalStorage.setItem(
            "languageItemsTime",
            new Date().toISOString()
          );
          const items = await vrsUserActions.getLanguageItemsAppSync(
            "en-US",
            "en_US"
          );

          const itemsTrimmed = items.map((el) => ({
            ...el,
            i: el.i ? el.i.trim() : el.i,
          }));

          enLanguageItemsStr = JSON.stringify(itemsTrimmed);
          userLocalStorage.setItem(
            "languageItems-en-us",
            compressLZW(enLanguageItemsStr)
          );
          configActions.setLanguageItems("en-us", itemsTrimmed);
        } else {
          configActions.setLanguageItems(
            "en-us",
            JSON.parse(decompressLZW(enLanguageItemsStr))
          );
        }
      },
      [configActions, userLocalStorage, vrsUserActions]
    );

    const loadVrsUserProfile = useCallback(
      async (forceRefresh: boolean) => {
        // Get Vrs language items
        let vrsUserProfileTime = userLocalStorage.getItem(
          "vrsUserProfileLocaleTime"
        );
        const emptyTime = !vrsUserProfileTime;
        if (emptyTime) {
          vrsUserProfileTime = new Date().toISOString();
        }

        const refreshVrsUserProfile =
          new Date().getTime() - new Date(vrsUserProfileTime).getTime();

        const storageValue = userLocalStorage.getItem("vrsUserLocale");
        let vrsUserLocalStr =
          storageValue && storageValue !== "null" ? storageValue : "";
        if (
          forceRefresh ||
          !vrsUserLocalStr ||
          emptyTime ||
          refreshVrsUserProfile > PROFILE_REFRESH_MINUTES * 60000
        ) {
          userLocalStorage.setItem(
            "vrsUserProfileLocaleTime",
            new Date().toISOString()
          );
          try {
            configActions.setIsLoading(true);
            const profiles = await vrsUserActions.getUserProfileAppSync(false);

            const userProfile = profiles.length > 0 ? profiles[0] : null;
            configActions.setPrefer24HourTimeFormat(
              userProfile?.Prefer24HourTimeFormat || false
            );
            if (
              userProfile &&
              userProfile.Languages &&
              userProfile.LanguageId
            ) {
              const languageProfileItem = userProfile.Languages.find(
                (el) => el.LanguageId === userProfile.LanguageId
              );
              const languageTag = languageProfileItem.IetflanguageTag;
              if (languageProfileItem) {
                if (languageTag && languageTag === "en-US") {
                  vrsUserLocalStr = "en";
                } else {
                  vrsUserLocalStr =
                    languageProfileItem.IetflanguageTag.toLowerCase();
                }
              }
            }

            userLocalStorage.setItem("vrsUserLocale", vrsUserLocalStr);
            configActions.setVrsUserLocale(vrsUserLocalStr);
          } finally {
            configActions.setIsLoading(false);
          }
        } else {
          configActions.setVrsUserLocale(
            vrsUserLocalStr ? vrsUserLocalStr : "en"
          );
        }

        return vrsUserLocalStr;
      },
      [configActions, userLocalStorage, vrsUserActions]
    );

    return {
      _T: fetchFunction,
      loadVrsTranslations,
      loadVrsUserProfile,
    };
  } catch (err) {
    throw new Error(
      "useVrsTranslationState must be used within a functional component"
    );
  }
}

function useConfigCacheState(): any {
  try {
    const context = useContext(AppGlobalStateContext);
    return useCallback(
      (name, key) =>
        (context.configState.appCache[name] || []).find((el) => el.key === key)
          ?.value,
      [context.configState.appCache]
    );
  } catch (err) {
    throw new Error(
      "useConfigState must be used within a functional component"
    );
  }
}

function useIsLoading(): boolean {
  try {
    const context = useContext(AppGlobalStateContext);
    return context.configState.isLoading;
  } catch (err) {
    throw new Error(
      "useConfigState must be used within a functional component"
    );
  }
}

function useAppGlobalDispatch(): IAppGlobalDispatch {
  try {
    const context = useContext(AppGlobalDispatchContext);
    return context;
  } catch (err) {
    throw new Error(
      "useAppGlobalDispatch must be used within a functional component"
    );
  }
}

function useSelectedSite() {
  try {
    const context = useContext(AppGlobalStateContext);
    const sites = context.siteState.sites;
    if (sites.length === 0) {
      return null;
    }

    const selectedSites = sites.filter(
      (s) => s.id === context.siteState.selectedSiteId
    );
    if (selectedSites) {
      return selectedSites[0];
    }

    const selectedSitesBySelection = sites.filter((s) => s.selected === true);
    if (selectedSitesBySelection) {
      return selectedSitesBySelection[0];
    }
    return null;
  } catch (err) {
    throw new Error(
      "useAppGlobalDispatch must be used within a functional component"
    );
  }
}

async function loginUser(
  dispatch: IAppGlobalDispatch,
  userSessionStorage: IStorage,
  userProfile: any,
  setIsLoading: ISetBoolean,
  setError: ISetBoolean
) {
  dispatch({ type: LOGIN_SUCCESS, payload: userProfile });
  setError(false);
  setIsLoading(true);

  if (userProfile) {
    userSessionStorage.setItem("cognito_user", JSON.stringify(userProfile));
    setError(false);
    setIsLoading(false);
  } else {
    userSessionStorage.removeItem("cognito_user");
    dispatch({ type: LOGIN_FAILURE, payload: null });
    setError(true);
    setIsLoading(false);
  }
}

async function restoreUserFromSession(
  dispatch: IAppGlobalDispatch,
  userProfile: any
) {
  dispatch({ type: RESTORE_USER_FROM_SESSION_SUCCESS, payload: userProfile });
}

function useLogOut() {
  const selectedSiteId = useSelectedSite();
  const userLocalStorage = useLocalStorage();
  return async function (dispatch: IAppGlobalDispatch) {
    const redirectURL = `${window.location.origin}/login`;
    await signOutUser();
    dispatch({ type: RESET_TO_INITIAL_STATE, payload: true });
    dispatch({ type: SET_DEFAULT_PAGE_REQUIRED, payload: true });
    dispatch({ type: SET_HOME_SELECTION_MODE, payload: 0 });
    dispatch({ type: LOGOUT_SUCCESS, payload: null });
    dispatch({
      type: SET_HEADER_TITLE,
      payload: { title: "", icon: null, linkParams: { ignoreItSelf: true } },
    });
    dispatch({ type: RESET_SITE_DATA, payload: { id: selectedSiteId } });
    dispatch({ type: RESET_APP_DATA, payload: true });

    window.location.assign(redirectURL);

    userLocalStorage.removeItem("vrsUserProfileLocaleTime");
    userLocalStorage.removeItem("languageItemsTime");
  };
}

export {
  AppGlobalProvider,
  useAppGlobalState,
  useAppSiteState,
  useAppCompanyState,
  useAppConfigState,
  useConfigCacheState,
  useAppUserState,
  useAppLineState,
  useAppShiftState,
  useAppProductState,
  useConfigState,
  useAppConfigSelector,
  useAppGlobalDispatch,
  loginUser,
  useLogOut,
  restoreUserFromSession,
  useSelectedSite,
  useAppSelectedSite,
  useIsLoading,
  useVrsTranslationState,
  useAppModelState,
  useAppSalesforceState,
};

