import { makeStyles } from "@mui/styles";
import { IconButton, Theme } from "@mui/material";
import classNames from "classnames";
import {
  actionBackgroundColor,
  myPrimaryColor,
  mySecondaryColor,
  myWhite,
} from "../../constants/colors";
import { forwardRef } from "react";

const useStyles = makeStyles((_: Theme) => ({
  root: {
    color: myPrimaryColor,
    backgroundColor: actionBackgroundColor,
    "@media (hover: hover)": {
      "&:hover": {
        color: myWhite,
        backgroundColor: mySecondaryColor,
      },
    },
    borderRadius: "5px",
    padding: "8px",
    marginRight: "4px",
    marginLeft: "4px",
    maxHeight: "40px",
  },
}));

const ActionButton = forwardRef(({ children, classes, ...props }: any, ref) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(classes.root, commonClasses.root)
    : commonClasses.root;
  return (
    <IconButton classes={{ ...classes, root: activeRootClasses }} {...props} ref={ref}>
      {children}
    </IconButton>
  );
});

ActionButton.displayName = 'ActionButton';

export { ActionButton }
