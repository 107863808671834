import { ReactElement, useMemo } from "react"
import { useAppGlobalDispatch } from "../../../context/AppContext/AppContext"
import { SET_HEADER_TITLE } from "../../actionTypes";

export function useSetTitleAction(deps = []) {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => {
      return (titleId: string, icon: ReactElement, linkParams = {}, textOnly = false) => dispatch({
        type: SET_HEADER_TITLE, payload: {
          icon,
          titleId,
          linkParams,
          textOnly
        }
      });
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
