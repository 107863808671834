import makeStyles from "@mui/styles/makeStyles";

import {
  myWhiteColorDimmedFurther,
  myWhiteShade4,
} from "../../constants/colors";
import { Theme } from "@mui/material";

const transitionBase = 'all .2s ease-in-out';

export const usePageTitleStyle = makeStyles((theme: Theme) => ({
  appPageTitle: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    transition: transitionBase,
    position: 'relative',
    color: theme.palette.common.white
  },
  appPageTitleHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  itemParent: {
    display: 'flex',
    alignItems: 'center'
  },
  breadcrumbItem: {
    fontSize: theme.spacing(1.625),
    margin: 0,
    color: theme.palette.common.white
  },
  appPageTitleHeadingText: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    margin: 0,
    color: theme.palette.common.white
  },

  titleContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.common.white,
    textDecorationColor: theme.palette.common.white,
    textDecoration: 'underline',
    '&:hover': {
      color: myWhiteShade4,
    }
  },
  item: {
    color: theme.palette.common.white,
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    color: myWhiteColorDimmedFurther,
    marginLeft: theme.spacing(0.625),
    marginRight: theme.spacing(0.625),
  },
  mainIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    marginRight: theme.spacing(1)
  },
}));
