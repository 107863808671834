import { SimpleMessageProps } from "./SimpleMessage.props";

// styles
import useStyles from "./SimpleMessage.css";

// logo
import { ReactComponent as CompanyLogo } from "../Header/videojet-logo.svg";
import { Card } from "@mui/material";

function SimpleMessage({ title, subtitle, extraContent }: SimpleMessageProps) {
  const classes = useStyles();

  return (
    <Card className={classes.messageBox}>
      <div className={classes.logoContainer}>
        <CompanyLogo className={classes.logo} />
      </div>
      <h1>{title}</h1>
      <div className={classes.content}>{subtitle}</div>
      {extraContent}
    </Card>
  );
}

SimpleMessage.displayName = "SimpleMessage";

export { SimpleMessage };
