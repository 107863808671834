import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { myWhiteShade0 } from "../../constants/colors";
import { Theme } from "@mui/material";

const useStyles = makeStyles((_: Theme) => ({
  root: {
    alignItems: "stretch",
    justifyContent: "center",

    display: "grid",
    gridGap: "2px",
    columnGap: "0px !important",
  },
  readonly: {
    backgroundColor: myWhiteShade0,
  },
}));

const StyledTableGridHeadRow = ({ readonly, extraClasses, ...rest }: any) => {
  const classes = useStyles();
  return extraClasses ? (
    <div
      className={classNames(classes.root, ...extraClasses, {
        [classes.readonly]: readonly,
      })}
      {...rest}
    />
  ) : (
    <div
      className={classNames(classes.root, { [classes.readonly]: readonly })}
      {...rest}
    />
  );
};

export default StyledTableGridHeadRow;
