import {
  APP_INITIALISED,
  APP_INITIALISED_WITH_VRS_OPERATIONS,
  LOAD_OUTAGE_SUCCESS,
  LOAD_PROFILE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  RESTORE_USER_FROM_SESSION_SUCCESS,
  SET_COGNITO_USER_LOADED,
  SET_DEFAULT_PAGE_REQUIRED,
  UPDATE_DESIGN_TOKEN,
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import { IOutageStatus } from "../../interfaces/IOutageStatus";

export interface IUserState {
  isAdmin: boolean;
  isPureDesignUser: boolean; // This user has only a single design site
  isIdentityUser: boolean;
  isCSEUser: boolean;
  isServiceUser: boolean;
  isVJInternalUser: boolean;
  authClaims: any;
  initialised: boolean;
  isAuthenticated: boolean;
  designToken: string;
  cognitoUserLoaded: boolean;
  userProfile: any;
  outageStatus: IOutageStatus | null;
  userProfileLarge: any;
  defaultPageDisplayRequired: boolean;
  currentUser: any;
  vrsAbilities: any;
}
export const initialUserState: IUserState = {
  isAdmin: false,
  isPureDesignUser: false,
  isCSEUser: false,
  isServiceUser: false,
  isVJInternalUser: false,
  authClaims: null,
  isIdentityUser: false,
  initialised: false,
  isAuthenticated: false,
  designToken: "",
  cognitoUserLoaded: false,
  userProfile: {
    email: "",
  },
  outageStatus: null,
  userProfileLarge: null,
  defaultPageDisplayRequired: false,
  currentUser: {},
  vrsAbilities: {},
};

export function userReducer(
  state: IUserState = initialUserState,
  action: IAppGlobalAction
): IUserState {
  switch (action.type) {
    case UPDATE_DESIGN_TOKEN:
      return {
        ...state,
        designToken: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userProfile: action.payload,
        defaultPageDisplayRequired: true,
      };
    case RESTORE_USER_FROM_SESSION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        userProfile: action.payload,
        defaultPageDisplayRequired: false,
      };
    case SET_DEFAULT_PAGE_REQUIRED:
      return {
        ...state,
        defaultPageDisplayRequired: action.payload,
      };
    case APP_INITIALISED:
    case APP_INITIALISED_WITH_VRS_OPERATIONS:
      return {
        ...state,
        initialised: true,
        currentUser: action.payload.currentUser,
        isAdmin: action.payload.isAdmin || action.payload.isVrsSuperUser,
        isPureDesignUser: action.payload.isPureDesignUser,
        isIdentityUser: action.payload.isIdentityUser,
        isCSEUser: action.payload.isCSEUser,
        isServiceUser: action.payload.isServiceUser,
        isVJInternalUser: action.payload.isVJInternalUser,
        authClaims: action.payload.authClaims,
      };
    case LOGOUT_SUCCESS:
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        initialised: false,
        userProfile: null,
        userProfileLarge: null,
      };
    case SET_COGNITO_USER_LOADED:
      return {
        ...state,
        cognitoUserLoaded: action.payload,
      };
    case LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLarge: action.payload,
        outageStatus: action.payload
          ? action.payload.ServiceOutageStatus
          : null,
      };
    case LOAD_OUTAGE_SUCCESS:
      return {
        ...state,
        outageStatus: action.payload
      };
    default: {
      return state;
    }
  }
}
