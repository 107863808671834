import { Link } from "react-router-dom";
import { checkSql, checkTimescale } from "../../../utilities/utils";

const LinkToUrl = ({ children, to, ...props }: any) => {
  const activeLink =
    typeof to === "string" ? to.replace("<ts>", checkTimescale() ? "-ts" : "").replace("<sql>", checkSql() ? "-sql" : "") : to;
  return (
    <Link to={activeLink} {...props}>
      {children}
    </Link>
  );
};

export default LinkToUrl;
