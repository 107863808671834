import Utils from '../utilities/utils';
import Moment from 'moment-timezone';
import { getOee } from '../utilities/oeeCalculations';

class SiteReducerHelpers {

  static filterAndSplitDowntimesForWeekOrMonth(responseData, shiftIds, tz, interval) {
    const filteredByShift = responseData.filter(downtime => shiftIds.length === 0 || shiftIds.indexOf(downtime.shiftId) !== -1);
    const finalDowntimesSplitByInterval: Array<any> = [];

    filteredByShift.forEach((downtime) => {
      let splitDowntimeOne;
      let splitDowntimeTwo;

      if (downtime.endDt) {
        const siteLocalStart = Utils.getLocalSiteDateObject(new Date(downtime.startDt), tz);
        const siteLocalEnd = Utils.getLocalSiteDateObject(new Date(downtime.endDt), tz);

        let timeSplit;
        // get week/month change time
        // set duration from startDt to week/month change
        // set duration from endDt to week/month change

        const weekSplitNeeded = interval === 'w' && Moment(siteLocalStart)
          .week() !== Moment(siteLocalEnd)
            .week();
        const monthSplitNeeded = interval === 'm' && Moment(siteLocalStart)
          .month() !== Moment(siteLocalEnd)
            .month();
        if (weekSplitNeeded) {
          timeSplit = Moment(siteLocalEnd)
            .startOf('week');
        }

        if (monthSplitNeeded) {
          timeSplit = Moment(siteLocalEnd)
            .startOf('month');
        }

        if (weekSplitNeeded || monthSplitNeeded) {
          const siteLocalStartMoment = Moment(siteLocalStart);
          const siteLocalEndMoment = Moment(siteLocalEnd);

          const firstDuration = Moment.duration(timeSplit.diff(siteLocalStartMoment));
          const secondDuration = Moment.duration(siteLocalEndMoment.diff(timeSplit));

          splitDowntimeOne = {
            lineId: downtime.lineId,
            reasonId: downtime.reasonId,
            shiftId: downtime.shiftId,
            status: downtime.status,
            duration: firstDuration.asMinutes(),
            startDt: downtime.startDt,
          };

          splitDowntimeTwo = {
            lineId: downtime.lineId,
            reasonId: downtime.reasonId,
            shiftId: downtime.shiftId,
            status: downtime.status,
            duration: secondDuration.asMinutes(),
            startDt: downtime.endDt,
          };
        }
      }

      if (splitDowntimeTwo && splitDowntimeOne) {
        finalDowntimesSplitByInterval.push(splitDowntimeOne);
        finalDowntimesSplitByInterval.push(splitDowntimeTwo);
      } else {
        const start = Moment(downtime.startDt);
        const end = Moment(downtime.endDt);
        const duration = Moment.duration(end.diff(start));
        const finalDowntime = {
          lineId: downtime.lineId,
          reasonId: downtime.reasonId,
          shiftId: downtime.shiftId,
          status: downtime.status,
          startDt: downtime.startDt,
          duration: duration.asMinutes(),
        };
        finalDowntimesSplitByInterval.push(finalDowntime);
      }
    });

    return finalDowntimesSplitByInterval;
  }

  static formatHourOrDayOEE(events, dateName, dateFormat, interval, tz) {
    return events.map((e) => {
      let timeStamp;
      if (interval === 'h') {
        timeStamp = Moment.utc(e[dateName], dateFormat)
          .local()
          .toDate();
      } else {
        timeStamp = Moment.tz(e[dateName], dateFormat, tz)
          .toDate();
      }

      const siteTimeStamp = Utils.getLocalSiteDateObject(timeStamp, tz);
      return ({
        availability: e.availability || 0,
        count: e.count || 0,
        date: e[dateName],
        timeStamp,
        siteTimeStamp,
        oee: e.oee || 0,
        performance: e.performance || 0,
        quality: e.quality || 0,
        totalCounter: 0,
        details: (e.detailedResults || [e]).map(dr => ({
          ...dr, ...((dr.slices && dr.slices.length > 0) ? {
            slices: dr.slices.map(slice => ({
              ...slice,
              ingestVersion: e.ingestVersion
            }))
          } : {})
        })), // line pieces (w/slices)
        slices: e.slices && e.slices.map(slice => ({
          ...slice,
          ingestVersion: e.ingestVersion
        })), // aggregate slices (products, shifts, etc.)

        target: e.currentTargetCount,
        ingestVersion: e.ingestVersion
      });
    })
      .sort((a, b) => a.timeStamp - b.timeStamp);
  }

  static getShiftProdSlices(events) {
    return events.map(line => line.results.map((result) => {
      if (result.slices) {
        return result.slices.map((slice) => {
          result.dateHour ? slice.dateHour = result.dateHour
            : result.date ? slice.date = result.date : slice.date = null;
          slice.lineId = result.lineId;
          slice.siteId = result.siteId;
          return slice;
        });
      }
      return [];
    }))
      .flat(2);
  }

  static formatWeekOrMonthOee(events, dateFormat, productId, shiftId, shiftIds, intervalText, tz) {
    let shiftProdSlices;

    const bySingleProduct = productId && productId !== '';
    const bySingleShift = shiftId;
    const byMultiShifts = shiftIds && shiftIds.length > 0;

    let prodShiftKey;

    if (bySingleProduct && bySingleShift) {
      prodShiftKey = [shiftId, productId].sort().join('_');
    } else if (bySingleProduct) {
      prodShiftKey = productId;
    } else if (bySingleShift) {
      prodShiftKey = shiftId;
    }

    if (prodShiftKey) {
      shiftProdSlices = this.getShiftProdSlices(events).filter(slice => (slice.sliceKey === prodShiftKey));
    } else if (byMultiShifts) {
      shiftProdSlices = this.getShiftProdSlices(events).filter(slice => slice.type === 'schedule' && shiftIds.find(shift => shift === slice.sliceKey));
    } else {
      shiftProdSlices = this.getShiftProdSlices(events).filter(slice => slice.type === 'schedule');
    }

    const timeSlices: Array<any> = [];
    shiftProdSlices.flat().forEach((slice) => {
      // for categorizing, comparing the siteTimeStamp field so that items are aggregated according to their site local tday
      let timeSliceToChange;
      const siteLocalMoment = Moment.tz(slice.date, dateFormat, tz);
      const localSiteDateObject = Utils.getLocalSiteDateObject(siteLocalMoment.toDate(), tz);
      const sliceYear = Moment(localSiteDateObject).year();

      if (intervalText === 'Month') {
        const sliceMonth = Moment(localSiteDateObject).month();
        timeSliceToChange = timeSlices.find((month) => {
          const monthMonth = Moment(month.siteTimeStamp).month();
          const monthYear = Moment(month.siteTimeStamp).year();
          return monthMonth === sliceMonth && monthYear === sliceYear;
        });
      }
      if (intervalText === 'Week') {
        const sliceWeek = Moment(localSiteDateObject).week();
        timeSliceToChange = timeSlices.find((week) => {
          const weekNumber = Moment(week.siteTimeStamp).week();
          const weekYear = Moment(week.siteTimeStamp).year();
          return weekNumber === sliceWeek && weekYear === sliceYear;
        });
      }

      const detailSliceExtracted = {
        lineId: slice.lineId,
        count: slice.count,
        plannedMinutes: slice.plannedMinutes,
        badPieceCount: slice.badPieceCount,
        currentTargetCount: slice.currentTargetCount,
        reportedMinutes: slice.reportedMinutes,
        plannedStoppedMinutes: slice.plannedStoppedMinutes,
        date: slice.date, // set the date to the middle of the week so it's not pushed into the wrong week
        timeStamp: siteLocalMoment.toDate(), // converting to local timestamp
        siteTimeStamp: localSiteDateObject, // converting to site timestamp
        siteId: slice.siteId,
        ingestVersion: slice.ingestVersion,
      };

      if (timeSliceToChange) {
        let detailTimeSlices;
        const detailTimeSliceToChange = timeSliceToChange.details.find(detail => detail.lineId === slice.lineId);

        if (detailTimeSliceToChange) {
          detailTimeSlices = timeSliceToChange.details;
          detailTimeSlices[detailTimeSlices.indexOf(detailTimeSliceToChange)] = Object.assign({}, detailTimeSliceToChange,
            {
              count: detailTimeSliceToChange.count + slice.count,
              plannedMinutes: detailTimeSliceToChange.plannedMinutes + slice.plannedMinutes,
              badPieceCount: detailTimeSliceToChange.badPieceCount + slice.badPieceCount,
              currentTargetCount: detailTimeSliceToChange.currentTargetCount + slice.currentTargetCount,
              reportedMinutes: detailTimeSliceToChange.reportedMinutes + slice.reportedMinutes,
              plannedStoppedMinutes: detailTimeSliceToChange.plannedStoppedMinutes + slice.plannedStoppedMinutes,
              ingestVersion: timeSliceToChange.ingestVersion || slice.ingestVersion,
            });
        } else {
          detailTimeSlices = timeSliceToChange.details.concat([detailSliceExtracted]);
        }

        timeSlices[timeSlices.indexOf(timeSliceToChange)] = Object.assign({}, timeSliceToChange,
          {
            details: detailTimeSlices,
            count: timeSliceToChange.count + slice.count,
            plannedMinutes: timeSliceToChange.plannedMinutes + slice.plannedMinutes,
            badPieceCount: timeSliceToChange.badPieceCount + slice.badPieceCount,
            currentTargetCount: timeSliceToChange.currentTargetCount + slice.currentTargetCount,
            reportedMinutes: timeSliceToChange.reportedMinutes + slice.reportedMinutes,
            plannedStoppedMinutes: timeSliceToChange.plannedStoppedMinutes + slice.plannedStoppedMinutes,
            ingestVersion: timeSliceToChange.ingestVersion || slice.ingestVersion,
          });
      } else { // no toTimeSliceToChange (slice week / month hasn't been created yet)
        const newSliceExtract = {
          count: slice.count,
          plannedMinutes: slice.plannedMinutes,
          badPieceCount: slice.badPieceCount,
          currentTargetCount: slice.currentTargetCount,
          reportedMinutes: slice.reportedMinutes,
          plannedStoppedMinutes: slice.plannedStoppedMinutes,
          date: slice.date, // for week or month, this is a random date within the time frame. the chart will agg and show the correct label
          timeStamp: siteLocalMoment.toDate(), // converting to local timestamp
          siteTimeStamp: localSiteDateObject,
          siteId: slice.siteId,
          details: [detailSliceExtracted],
          ingestVersion: slice.ingestVersion,
        };

        timeSlices.push(newSliceExtract);
      }
    });

    return timeSlices.map((event) => {
      event.details = event.details.map(detailEvent => ({
        ...detailEvent,
        oee: getOee(detailEvent)
      }));
      event.oee = getOee(event);
      return event;
    });
  }
}

export default SiteReducerHelpers;
