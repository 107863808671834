import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useWorkScheduleBarStyles = makeStyles((theme: Theme) => ({
  filterContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    wordBreak: "normal",
  },
  filterContainerEnd: {
    marginLeft: "auto",
  },
  lastGridCell: {
    padding: theme.spacing(1.25),
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "unset",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  enabledNotificationLabel: {
    fontSize: "15px",
  },
}));
