/**
 * Replace error messages with text which might mean something...
 *
 * @param {string} message - Error message to check for replacement
 * @returns {string} replaced message or original
 */
function replaceMessage(message) {
  const replacements = [
    { item: '"printerRoleId" is not allowed to be empty', repl: '"Please select a Count Type"' }
  ];
  for (const repl of replacements) {
    if (message.includes(repl.item)) {
      return repl.repl + '; ';
    }
  }
  return message + '; ';
}

/**
 * Extract errors from an error - - - - - - - - - - object, replacing messages if needed
 * (error formatting is minimal for anything other
 * than a group of dynogels errors)
 *
 * @param {Object} error object
 * @returns {string} error string with all errors formatted for viewing
 */
export function extractErrors(error) {
  let err, errStr = '';
  try {
    if (error.message) {
      err = JSON.parse(error.message);
      if (err && err.status === false) {
        errStr = err.error;
      }
      else if (err && err.code && err.code === 'ValidationError') {
        errStr = err.code + ': ' + err.message;
      }
      else if (err && err.code && err.code === 'ValidationException') {
        errStr = err.code + ': ' + err.message;
      }
      else if (err && err.name && err.name === 'DynogelsUpdateError' && err.detail) {
        for (const which of [err.remove, err.update]) {
          for (const idx in which) {
            errStr += replaceMessage(which[idx].message);
          }
        }
      }
    }
    else if (error.statusCode && error.statusCode !== 200) {
      errStr = error.statusCode + ': ' + error.message;
    }

  }
  catch (e) {
    console.log("error building error string:", e);
    return error.message ? error.message : '';
  }
  return errStr;
}
