import StyledTableGridRow from "../../../../components/StyledTableGridRow/StyledTableGridRow";
import StyledTableGridCell from "../../../../components/StyledTableGridCell/StyledTableGridCell";
import { useWorkScheduleRowStyles } from "./WorkScheduleRow.css";
import { ISchedule } from "../../../../interfaces/User/ISchedule";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";

import { DayNames } from "../../../../constants/global";
import { Button, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IWorkScheduleRow {
  schedule: ISchedule;
  rowClasses: string;
  cellClasses: string;
  updateWorkSchedule: (workSchedule: ISchedule) => void;
  onTimeError: (day, label: string, value: boolean) => void;
  resetIndex: number;
  refreshIndex: number;
  enableNotification: boolean;
}

const WorkScheduleRow = ({
  schedule,
  rowClasses,
  cellClasses,
  updateWorkSchedule,
  resetIndex,
  onTimeError,
  enableNotification,
  refreshIndex,
}: IWorkScheduleRow) => {
  const classes = useWorkScheduleRowStyles();
  const { _T } = useVrsTranslationState();

  const startTimeFromSchedule = useMemo(() => {
    const time = new Date();
    time.setHours(schedule.StartHour);
    time.setMinutes(schedule.StartMinute);
    return time;
  }, [schedule]);

  const shiftLengthFromSchedule = useMemo(() => {
    const time = new Date();
    time.setHours(schedule.ShiftHours);
    time.setMinutes(schedule.ShiftMins);
    return time;
  }, [schedule]);

  const [startTime, setStartTime] = useState<any>(startTimeFromSchedule);
  const [shiftLength, setshiftLength] = useState<any>(shiftLengthFromSchedule);

  const onStartSetTimeError = useCallback(
    (reason, event) => {
      if (reason || event === null) {
        onTimeError(schedule.Day, "start", true);
      }

      if (!reason && event !== null) {
        onTimeError(schedule.Day, "start", false);
      }
    },
    [schedule, onTimeError]
  );

  const onShiftSetTimeError = useCallback(
    (reason, event) => {
      if (reason || event === null) {
        onTimeError(schedule.Day, "shift", true);
      }

      if (!reason && event !== null) {
        onTimeError(schedule.Day, "shift", false);
      }
    },
    [schedule, onTimeError]
  );

  const onSetStartTime = useCallback(
    (value) => {
      setStartTime(value);
      if (
        value &&
        value.$d?.toString() !== "Invalid Date" &&
        (Number(value.$H) !== schedule.StartHour ||
          Number(value.$m) !== schedule.StartMinute)
      ) {
        updateWorkSchedule({
          ...schedule,
          StartHour: Number(value.$H) || 0,
          StartMinute: Number(value.$m) || 0,
        });
      }
    },
    [schedule, onTimeError]
  );

  const onSetShiftLength = useCallback(
    (value) => {
      setshiftLength(value);
      if (
        value &&
        value.$d?.toString() !== "Invalid Date" &&
        (Number(value.$H) !== schedule.ShiftHours ||
          Number(value.$m) !== schedule.ShiftMins)
      ) {
        updateWorkSchedule({
          ...schedule,
          ShiftHours: Number(value.$H) || 0,
          ShiftMins: Number(value.$m) || 0,
        });
      }
    },
    [schedule, startTimeFromSchedule, onTimeError]
  );

  const onInputSetStartTime = useCallback(
    (event) => {
      if (!event.target.value) {
        onTimeError(schedule.Day, "start", true);
      }
    },
    [schedule, onTimeError]
  );

  const onInputSetShiftLength = useCallback(
    (event) => {
      if (!event.target.value) {
        onTimeError(schedule.Day, "shift", true);
      }
    },
    [schedule, onTimeError]
  );

  useEffect(() => {
    if (refreshIndex > 0) {
      const firstTime = new Date();
      firstTime.setHours(schedule.StartHour);
      firstTime.setMinutes(schedule.StartMinute);

      const shiftTime = new Date();
      shiftTime.setHours(schedule.ShiftHours);
      shiftTime.setMinutes(schedule.ShiftMins);

      setStartTime(firstTime);
      setshiftLength(shiftTime);
    }
  }, [refreshIndex, schedule]);

  useEffect(() => {
    if (resetIndex > 0) {
      const firstTime = new Date();
      firstTime.setHours(0);
      firstTime.setMinutes(0);

      const lastTime = new Date();
      lastTime.setHours(23);
      lastTime.setMinutes(59);

      setStartTime(firstTime);
      setshiftLength(lastTime);
    }
  }, [resetIndex]);

  return (
    <>
      <StyledTableGridRow extraClasses={[rowClasses]}>
        <StyledTableGridCell classes={{ root: cellClasses }}>
          <div>{_T(DayNames[schedule.Day])}</div>
        </StyledTableGridCell>
        <StyledTableGridCell classes={{ root: cellClasses }}>
          <div className={classes.inputCell}>
            <TimePicker
              value={startTime}
              onChange={onSetStartTime}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onChange={onInputSetStartTime}
                />
              )}
              ampm={false}
              disableOpenPicker={true}
              showToolbar={true}
              onError={onStartSetTimeError}
              disabled={!enableNotification}
              ToolbarComponent={Button}
            />
          </div>
        </StyledTableGridCell>
        <StyledTableGridCell classes={{ root: cellClasses }}>
          <div className={classes.inputCell}>
            <TimePicker
              value={shiftLength}
              onChange={onSetShiftLength}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onChange={onInputSetShiftLength}
                />
              )}
              ampm={false}
              disableOpenPicker={true}
              showToolbar={false}
              onError={onShiftSetTimeError}
              disabled={!enableNotification}
              ToolbarComponent={Button}
            />
          </div>
        </StyledTableGridCell>
      </StyledTableGridRow>
    </>
  );
};

export default WorkScheduleRow;
