import makeStyles from "@mui/styles/makeStyles";
import { mySecondaryColor } from "../../constants/colors";
import { Theme } from "@mui/material";

export const useNewPasswordDialogStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.125),
  },
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.spacing(70),
    flexFlow: 'row',
    alignItems: 'center',
    '&:nth-last-child(n + 2)': {
      marginBottom: theme.spacing(1.875)
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleText: {
    fontSize: theme.spacing(3),
    fontWeight: 600
  },
  titleIcon: {
    fontSize: theme.spacing(6),
    color: mySecondaryColor,
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  passwordCheck: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    fontSize: theme.spacing(2),
    fontWeight: 400
  }
}));
