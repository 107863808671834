import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import { myRed, requiredColor, standardBoxShadow } from "../../../../constants/colors";


export const useRequestLogsDialogStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.25, 1.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
  },
  content: {
    display: "flex",
    flexFlow: "column",
    marginBottom: theme.spacing(2.5),
    maxWidth: theme.spacing(100),
  },
  formControl: {
    display: "flex",
    flexFlow: "column",
    maxWidth: theme.spacing(80),
    alignItems: "flex-start",
    "&:nth-last-child(n + 2)": {
      marginBottom: theme.spacing(1.875),
    },
  },
  notes:{
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  controlContainer: {
    maxWidth: theme.spacing(32.5),
    marginLeft: theme.spacing(3.75),
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  headerContainer: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
  dropdownParent: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  dropdownRoot: {
    width: "100%",
    padding: theme.spacing(1.5, 2.875),
  },
 
  title: {
    marginBottom: theme.spacing(2.5)
  },
  error: {
    color: myRed
  },
  textArea: {
    width: "100%",
    fontSize: theme.spacing(2),
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    padding: theme.spacing(1.25),
    borderRadius: "4px",
    border: "1px solid #bbbbbb",
    "&:hover, &:focus": {
      cursor: "1px solid #222222",
    },
  },
  textAreaError: {
    border: "1px solid #f44336",
    "&:hover, &:focus": {
      cursor: "1px solid #f44336",
    },
  },
  inputBoxLabel: {
    marginBottom: theme.spacing(0.5),
  },
  errorText: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    color: requiredColor,
    fontSize: '0.75rem'
  },
}));
