import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useEditProfileDetailsStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: "flex",
    flexFlow: "row",
    maxWidth: theme.spacing(80),
    alignItems: "center",
    "&:nth-last-child(n + 2)": {
      marginBottom: theme.spacing(1.875),
    },
    width: "100%",
  },
  profileDetail: {
    paddingTop: "10px",
  },
}));
