import { useMemo } from "react";
import VrsOutageApi from "../api/prodVrsOutageApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import { LOAD_OUTAGE_SUCCESS } from "./actionTypes";
import { IOutageStatus } from "../interfaces/IOutageStatus";

export function loadOutageSuccess(payload) {
  return {
    type: LOAD_OUTAGE_SUCCESS,
    payload,
  };
}

function getOutagesAppSync() {
  return (_) => {
    return VrsOutageApi.getOutagesAppSync()
      .then((profile) => {
        return profile;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function checkOutageAppSync() {
  return (dispatch) => {
    return VrsOutageApi.checkOutageAppSync()
      .then((outageResult) => {
        dispatch(loadOutageSuccess(outageResult));
        console.log('outageResult=', outageResult);
        return outageResult;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}


function saveOutagesAppSync(
  outages: Array<IOutageStatus>
) {
  return (_) => {
    return VrsOutageApi.saveOutagesAppSync(outages)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

export const useVrsOutageActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      getOutagesAppSync: () =>
        getOutagesAppSync()(dispatch),
      checkOutageAppSync: () => checkOutageAppSync()(dispatch),
      saveOutagesAppSync: (
        outages: Array<IOutageStatus>
      ) => saveOutagesAppSync(outages)(dispatch)
    }),
    [dispatch]
  );
};
