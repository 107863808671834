import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import {
  companyReducer,
  ICompanyState,
} from "../companyReducer/companyReducer";
import { siteReducer, ISiteState } from "../siteReducer/siteReducer";
import { configReducer, IConfigState } from "../configReducer/configReducer";
import { userReducer, IUserState } from "../userReducer/userReducer";
import { lineReducer } from "../lineReducer/lineReducer";
import { shiftReducer } from "../shiftReducer/shiftReducer";
import { productReducer } from "../productReducer/productReducer";
import {
  salesforceReducer,
  ISalesforceState,
} from "../salesforceReducer/salesforceReducer";
import { RESET_TO_INITIAL_STATE } from "../../actions/actionTypes";
import { IModelState, modelReducer } from "../modelReducer/modelReducer";

function combineReducers(reducers) {
  return (state: any, action: IAppGlobalAction) => {
    const newState: any = {};
    Object.keys(reducers).forEach((key) => {
      const dataKey = key.replace("Reducer", "State");
      newState[dataKey] = reducers[key](
        action.type === RESET_TO_INITIAL_STATE ? undefined : state[dataKey],
        action
      );
    });
    return newState;
  };
}
export interface IRootState {
  configState: IConfigState;
  userState: IUserState;
  companyState: ICompanyState;
  modelState: IModelState;
  siteState: ISiteState;
  lineState: Array<any>;
  shiftState: Array<any>;
  productState: Array<any>;
  salesforceState: ISalesforceState;
}

function createRootReducer() {
  return combineReducers({
    configReducer,
    userReducer,
    companyReducer,
    modelReducer,
    siteReducer,
    lineReducer,
    shiftReducer,
    productReducer,
    salesforceReducer,
  });
}

export const rootReducer = createRootReducer();
