import { useQuery, UseQueryOptions } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';

const fetchUserTags = async (tagIds: number[]) => {
  return await VrsTagsApi.getUserTagsByTagIds(tagIds);
};

/**
 * Custom hook to fetch user tags using react-query.
 *
 * @param {number[]} tagIds - The IDs of the tags to fetch user tag relationships for.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the user tags data and query status.
 */
const useFetchUserTags = (
  tagIds: number[],
  options?: UseQueryOptions<unknown, unknown, unknown, [string, string[]]>
) => {
  return useQuery(['userTags', tagIds.map(t => String(t))], () => fetchUserTags(tagIds), {
    enabled: tagIds.length > 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export default useFetchUserTags;
