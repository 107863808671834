import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import {
  LOAD_SALESFORCE_SITES_SUCCESS,
  LOAD_SALESFORCE_SERVICE_REGION_GEOMETRY_SUCCESS
} from "../../actions/actionTypes";

export interface ISalesforceState {
  salesforceSites: Array<any>,
  salesforceSitesLoaded: boolean,
  salesforceServiceRegionGeometry: Array<any>,
  salesforceServiceRegionGeometryLoaded: boolean,
}
export const initialSalesforceSiteState: ISalesforceState = {
  salesforceSites: [],
  salesforceSitesLoaded: false,
  salesforceServiceRegionGeometry: [],
  salesforceServiceRegionGeometryLoaded: false,
};

export function salesforceReducer(
  state: ISalesforceState = initialSalesforceSiteState,
  action: IAppGlobalAction
): ISalesforceState {

  switch (action.type) {
    case LOAD_SALESFORCE_SITES_SUCCESS: {
      return {
        ...state,
        salesforceSites: state.salesforceSites.concat(action.payload),
        salesforceSitesLoaded: true,
      };
    }
    case LOAD_SALESFORCE_SERVICE_REGION_GEOMETRY_SUCCESS: {
      return {
        ...state,
        salesforceServiceRegionGeometry: state.salesforceServiceRegionGeometry.concat(action.payload),
        salesforceServiceRegionGeometryLoaded: true,
      };
    }
    default: {
      return state;
    }
  }
}