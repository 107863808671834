import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import { IOutageStatus } from "../interfaces/IOutageStatus";

const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_SYSTEM_URL"];
};


class VrsOutageApi {
  static getOutagesAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: `
      query OutagesQuery {
        outages(options: "") {
          Color
          IsOutageOn
          OutageEndDate
          OutageId
          OutageStartDate
          OutageText
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SYSTEM",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.outages) {
        return {
          error: null,
          data: results.data?.outages,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static checkOutageAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve(null);
    }

    const graphQLBody = {
      query: `
      query CheckOutageQuery {
        checkoutage {
            Color
            IsOutageOn
            OutageEndDate
            OutageId
            OutageStartDate
            OutageText
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SYSTEM",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.checkoutage) {
        return results.data?.checkoutage;
      }
      return null;
    });
  }

  static saveOutagesAppSync(outages: Array<IOutageStatus>) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: `
      mutation SaveOutagesMutation {
        saveOutages(options: "", saveOutageConfigurationInput: {
          Outages: [${outages.map((outage) => `
          {
            OutageId: ${outage.OutageId},
            OutageText: "${outage.OutageText}",
            Color: "${outage.Color}",
            IsOutageOn: ${outage.IsOutageOn ? "true" : "false"},
            OutageStartDate: ${
              outage.OutageStartDate ? '"' + outage.OutageStartDate + '"' : null
            },
            OutageEndDate: ${
              outage.OutageEndDate ? '"' + outage.OutageEndDate + '"' : null
            }
           }
          `)}]
        }) {
          Color
          IsOutageOn
          OutageEndDate
          OutageId
          OutageStartDate
          OutageText
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SYSTEM",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveOutages) {
        return {
          error: null,
          data: results.data?.saveOutages,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
}

export default VrsOutageApi;
