import { invokeApig } from '../libs/authLib';
import { Logger } from '../utilities/Logger/Logger';
import { getApiPrefix } from '../libs/apiLib';

class LinesApi {
  static getAllLines(siteId) {
    const apiUrl = getApiPrefix() + '/lines';
    return invokeApig({ gatewayName: 'LINES', path: apiUrl, queryParams: { siteId } });
  }

  static getLineOee(siteId, id, startDateTime, endDateTime, interval) {
    const apiUrl = `${getApiPrefix()}/oee/${id}`;
    return invokeApig({
      gatewayName: 'OEE',
      path: apiUrl,
      queryParams: { startDateTime, endDateTime, interval, siteId },
    });
  }

  static getLineOeeShift(siteId, id, interval) {
    const apiUrl = `${getApiPrefix()}/oee/${id}`;
    return invokeApig({ gatewayName: 'OEE', path: apiUrl, queryParams: { interval, siteId } });
  }

  /**
   * Get Lines overview by lines
   *
   * @param {string} siteId - Site to get overview for
   * @param {string[]} lineIds - lineIds to include in the overview
   * @param {string} startDateTime - Optional time to locate shift overview for
   * @param {number} maxLineIdsQty
   * @returns {Promise<*>} - all the good stuff
   */
  static getLinesOverview(siteId, lineIds, startDateTime, maxLineIdsQty = 7) {
    const apiUrl = `${getApiPrefix()}/shiftOverview`;
    if (lineIds.length > maxLineIdsQty) {
      const l = Math.ceil(lineIds.length / maxLineIdsQty);
      const c = lineIds.reduce((agg, e, i) => {
        const k = i % l;
        if (agg.length <= k) {
          agg.push([e]); // create new array and add this id
        } else {
          agg[k].push(e); // push this id
        }
        return agg;
      }, []);
      Logger.of('App.prodLineApi.getLinesOverview').info('split requests', c);
      return Promise.all(c.map(ids => invokeApig({ gatewayName: 'SHIFT', path: apiUrl, queryParams: { startDateTime: startDateTime || new Date().toISOString(), siteId, lineIds: ids } })));
    } return invokeApig({ gatewayName: 'SHIFT', path: apiUrl, queryParams: { startDateTime: startDateTime || new Date().toISOString(), siteId, lineIds } });
  }


  /**
   * Loads the minute data for the specified site with given parameters
   *
   * @function
   * @param {string} siteId - Id of the site you want the results for.
   * @param {string} lineId - Id of the line you want the results for.
   * @param {string} startDt - The date and time to start processing of minutes.
   * @param {string} endDt - The date and time to end processing of minutes.
   * @param {string} type - Optional type(shape) of data to be returned.
   * @param {number} slowCycleThreshold - Optional threshold to be considered a slow cycle.
   * @returns {Promise} - Response from API
   */
  static getLineProcessMinute(siteId, lineId, startDt, endDt, type = 'none', slowCycleThreshold = 0) {
    const apiUrl = `${getApiPrefix()}/oee/processMinute/${lineId}`;
    return invokeApig({ gatewayName: 'OEE', path: apiUrl, queryParams: { siteId, startDt, endDt, type, slowCycleThreshold } });
  }

  static getLineShiftTargets(siteId, lineId) {
    const apiUrl = `${getApiPrefix()}/shiftTargets/${lineId}`;
    return invokeApig({ gatewayName: 'SHIFT', path: apiUrl, queryParams: { siteId } });
  }

  static getLineThroughputByDay(_) {
    return new Promise(() => {
      throw new Error('Not implemented');
    })
  }

  static getLineUtilization(_) {
    return new Promise(() => {
      throw new Error('Not implemented');
    })
  }

  static getLineTimeInState(siteId, lineIds, startDateTime, slowCycleThreshold) {
    const [lineId] = lineIds;
    const apiUrl = `${getApiPrefix()}/timeInState/${lineId}`;
    return invokeApig({ gatewayName: 'SHIFT', path: apiUrl, queryParams: { siteId, lineIds, slowCycleThreshold, startDateTime: startDateTime || new Date().toISOString() } });
  }

  static getLineTimeInStateForAllShifts(siteId, lineIds, startDateTime, endDateTime, slowCycleThreshold) {
    const apiUrl = `${getApiPrefix()}/timeInState/null`;
    return invokeApig({
      gatewayName: 'SHIFT',
      path: apiUrl,
      queryParams: { siteId, lineIds, startDateTime, endDateTime, slowCycleThreshold }
    });
  }

  static allowedLineProps = ['siteId',
    'id',
    'title',
    'display',
    'description',
    'groupName',
    'printerRoleId',
    'printerId',
    'deviceAssignments',
    'bestRate',
    'runRate',
    'multiplier',
    'quantity',
    'displayDetails',
    'assignedProductId',
    'assignedProductRun',
    'automaticPlannedDowntimeStart',
    'isDeleted',
    'createdBy',
    'updatedBy'];

  static saveLine(line) {
    const apiUrl = getApiPrefix() + '/lines';
    console.log('saving new line', line);
    // remove extra properties
    const filteredLine: any = this.allowedLineProps.reduce((obj, key) => ({ ...obj, [key]: line[key] != null ? line[key] : undefined }), {});
    if (filteredLine.id === '') delete filteredLine.id;
    if (filteredLine.printerRoleId === '') delete filteredLine.printerRoleId;
    if (filteredLine.printerId === '') delete filteredLine.printerId;
    if (filteredLine.description === '') delete filteredLine.description;
    return invokeApig({ gatewayName: 'LINES', path: apiUrl, method: 'POST', body: filteredLine });
  }

  static updateLine(line) {
    const apiUrl = `${getApiPrefix()}/lines/${line.id}`;
    console.log(`updating line ${line.id} =>`, line);
    // VCCM-1691 optional description, remove from stored record (by sending null) if empty string
    if (line.description === '') line.description = null;
    return invokeApig({ gatewayName: 'LINES', path: apiUrl, method: 'PUT', body: line });
  }

  static UpdateOrSaveLine(line) {
    const edited = line.id;
    if (edited) {
      return this.updateLine(line);
    }
    return this.saveLine(line);
  }

  static saveQaCount(count, lineId, siteId) {
    const apiUrl = `${getApiPrefix()}/oee/updateRejects/${lineId}`;
    console.log('adding qa pieces line', count);
    const request = { gatewayName: 'OEE', path: apiUrl, method: 'PUT', queryParams: { badPieceCount: `${count}`, siteId } };
    console.log('request', request);
    return invokeApig(request);
  }

  static deleteLine(id, siteId) {
    const apiUrl = `${getApiPrefix()}/lines/${id}`;
    return invokeApig({ gatewayName: 'LINES', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }


}

export default LinesApi;
