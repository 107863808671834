import humanizeDuration from "humanize-duration";
import * as ENUMS from "../../enums/Enums";
import momentEN from "moment-timezone";

const defaultSelector = (data) => data;

class DateHelper {
  _T: any;
  _timeFrames: any;

  constructor(_T, timeFrames = DateHelper.timeFrames) {
    this._T = _T;
    this._timeFrames = timeFrames;
  }

  static shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: function () {
          return "y";
        },
        mo: function () {
          return "mo";
        },
        w: function () {
          return "w";
        },
        d: function () {
          return "d";
        },
        h: function () {
          return "h";
        },
        m: function () {
          return "m";
        },
        s: function () {
          return "s";
        },
        ms: function () {
          return "ms";
        },
      },
    },
  });

  static timeIntervals = [
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.HOUR],
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.DAY],
    // VCCM-1682 commented out for release
    ENUMS.INTERVALS.instances[ENUMS.INTERVALS.WEEK],
    /* ENUMS.INTERVALS.instances[ENUMS.INTERVALS.MONTH],*/
  ];

  static timeFrames = [
    { translationKey: "CustomDateRange", value: "customDateRange" },
    { translationKey: "Yesterday", value: "yesterday" },
    { translationKey: "LastWeek", value: "lastWeek" },
    { translationKey: "Last30Days", value: "last30Days" },
    { translationKey: "ThisMonth", value: "thisMonth" },
    { translationKey: "LastMonth", value: "lastMonth" },
  ];

  static humanizeDuration(duration) {
    return this.shortEnglishHumanizer(duration, {
      largest: 2,
      round: true,
      units: ["d", "h", "m"],
    });
  }

  static getFormattedTimeFrames(_T, selector = defaultSelector) {
    return this.formatData(this.timeFrames, _T, selector);
  }

  static translateWeekday(momentLocale, enWeekdayName) {
    const idx = momentEN.weekdays().indexOf(enWeekdayName);
    return idx >= 0 ? momentLocale.weekdays(idx) : enWeekdayName;
  }

  getTimeFrameTextByValue(tfVal) {
    return DateHelper.getTextByValue(this._timeFrames, tfVal, this._T);
  }

  static getFormattedTimeIntervals(_T, selector = defaultSelector) {
    return this.formatData(this.timeIntervals, _T, selector);
  }

  static formatData(data, _T, selector = defaultSelector) {
    const selected = selector(data);
    return _T == null
      ? selected
      : selected.map((d) => ({
          ...d,
          text: _T(d.translationKey),
        }));
  }

  static getTextByValue(data, val, _T) {
    const entry = data.find((d) => d.value === val);
    return entry ? _T(entry.translationKey) : val;
  }
}

export default DateHelper;
