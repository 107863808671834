import {
  LOAD_PLANT_MODELS_SUCCESS,
  LOAD_PLANT_MODELS_INITIATED,
  LOAD_PLANT_MODELS_FAILED,
  LOAD_PLANT_MODELS_RESET,
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";

export interface IModelState {
  plantModels: Array<any>;
  plantModelInitiated: boolean;
  plantModelLoaded: boolean;
}
export const initialModelState: IModelState = {
  plantModels: [],
  plantModelInitiated: false,
  plantModelLoaded: false,
};

export function modelReducer(
  state: IModelState = initialModelState,
  action: IAppGlobalAction
): IModelState {
  switch (action.type) {
    case LOAD_PLANT_MODELS_SUCCESS: {
      return {
        ...state,
        plantModels: [...action.payload],
        plantModelLoaded: true,
      };
    }

    case LOAD_PLANT_MODELS_RESET: {
      return {
        ...state,
        plantModelLoaded: false,
        plantModelInitiated: false,
      };
    }

    case LOAD_PLANT_MODELS_INITIATED:
      return {
        ...state,
        plantModelInitiated: true,
      };

    case LOAD_PLANT_MODELS_FAILED:
      return {
        ...state,
        plantModelLoaded: false,
        plantModelInitiated: false,
      };

    default: {
      return state;
    }
  }
}
