// In `app-client-v2/src/hooks/useSaveUserTags.ts`

import { useMutation, useQueryClient } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';

/**
 * Custom hook that handles saving user tags.
 * Uses the `useMutation` hook from `react-query` to handle asynchronous
 * operations for saving user tags via the `VrsTagsApi`.
 *
 * The mutation on success will invalidate the 'userTags' query to ensure
 * the cache is up to date with the latest data.
 *
 * @returns {object} The mutation object returned by `useMutation`, containing methods
 *                   to execute the mutation and track its status.
 */
const useSaveUserTags = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ tagId, userTags }: { tagId: number, userTags: string[] }) =>
      await VrsTagsApi.saveUserTags(tagId, userTags),
    { onSuccess: async () => await queryClient.invalidateQueries('userTags'), }
  );
};

export default useSaveUserTags;
