// Greys
export const myBlack = "#000";
export const myBlackAlpha0 = "rgba(0, 0, 0, 0)";
export const myBlackAlpha2 = "rgba(0, 0, 0, .2)";
export const myBlackAlpha12 = "rgba(0, 0, 0, .12)";
export const myBlackAlpha14 = "rgba(0, 0, 0, .14)";
export const myBlackAlpha54 = "rgba(0, 0, 0, .54)";
export const myBlackAlpha87 = "rgba(0, 0, 0, .87)";

export const myVeryDarkGrey = "#2a2a2a";
export const myDarkGrey = "#666";
export const myGrey = "#b9b9b9";
export const myGrayShade1 = "#959595";
export const myGrayShade2 = "#292b2c";
export const myGrayShade2Alpha10 = "rgba(42, 42, 42, .1)";
export const myTurquoiseShade4 = "#4cced7";
export const myGrayShade3 = "#636c72";
export const myGrayShade4 = "#8c8c8c";
export const myLightGrey = "#f2f2f2";
export const myWhite = "#fff";
export const myWhiteShade0 = "#f8f8f8";
export const myWhiteShade1 = "#e6e6e6";
export const myWhiteShade2 = "#c0c0c0";
export const myWhiteShade3 = "#eeeeee";
export const myWhiteShade4 = "#d7d7d7";

export const defaultBackgroundColor = "#f6f7ff";

export const myGreyBorder = "#e6e7f1";

export const myPrimaryColor = "#577790";
export const myCorporatePrimaryColor = "#00a4de";
export const mySecondaryColor = "#333333";
export const widgetColor = "#577790";

export const myPrimarySelectedColor = "#1f748e";
export const myPrimaryColorLighter = "#8fa9bc";
export const myPrimaryCorporateColorLighter = "#56b7d5";

export const mySelectedColorLighter = "#d9d9d9";

export const mySecondarySelectedColor = "#404040";
export const mySecondaryColorDimmed = "rgba(46, 46, 46, .60)";
export const mySecondaryColorLighter = "#424242";
export const myWhiteColorDimmed = "rgba(255, 255, 255, .70)";
export const myWhiteColorDimmedFurther = "rgba(255, 255, 255, .40)";

export const myGreyDimmed = "rgba(185, 185, 185, .40)";
export const myGreyDimmed1 = "rgba(185, 185, 185, .90)";
export const myGreyDimmed2 = "rgba(245, 255, 255, .99)";
export const myDisabledColor = "rgba(245, 255, 255, .65)";

export const myDarkBackground = "#1c2022";
export const myVeryDarkTurquoise = "#0e686d";
export const mydarkTurquoise = "#13939b";
export const myTurquoise = "#19bec8";
export const myTurquoiseShade1 = "#00bec9";
export const myTurquoiseShade1Alpha50 = "rgba(0, 190, 201, .5)";
export const myTurquoiseShade2 = "#008e96";
export const myTurquoiseShade3 = "#00848c";
export const actionBackgroundColor = "#efeff6";

export const myCyan = "#5ce3eb";
export const myLightCyan = "#b7f2f6";
export const myVeryLightCyan = "#e4fafc";
export const myGhostWhite = "#f8f9ff";

// Extra colours
export const myGreyExtra = "#999";
export const myLiteGreyExtra = "#aaa";
export const myLiteGreyExtra2 = "#ccd1d1";
export const myStgWarning = "#a90d61";

export const myInValidColor = "rgba(255,0,0,0.35)";
export const myChangedColor = "rgba(0,0,255,0.90)";

// Errors (not really)
export const myBlue = "#07abd6";
export const myGreen = "#7dd607";
export const myGreenShade1 = "#d0eecb";
export const myRed = "#f33434";
export const myRedShade1 = "#e45448";
export const myRedShade1Alpha50 = "rgba(228, 84, 72, .5)";
export const myRedShade2 = "#d92e20";
export const myRedShade3 = "#d02c1f";
export const myYellow = "#ffc714";
export const myOrange = "#ffa514";
export const myLightPink = "#ffefee";
export const myGray = "#333333";
export const myDarkWhite = "#eaeaea";
export const lightGray = "#e3d8d8";
export const accordionBodyColor = myWhite;
export const accordionHeadColor = '#f4f6f6'
export const lightblue = "#d7e6f4";
export const darkerlightblue = '#afcce9';
export const darkerlightblue2 = "#b7afe9";
export const tableAlternativeColor = "#e3f1fb";

export const standardBoxShadow =
  "0 .18rem .3rem rgba(166,166,185,.5), 0 .326rem 3rem rgba(122,123,151,.3)";
export const smallBoxShadow =
  "2px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)";
export const requiredColor = "#ff0000";
export const imageBackgroundColor = "#e0e0e0";

export const disabledBackgroundColor = "#ebebe4";
export const disabledColor = "#c6c6c6";

export const CHART_COLOR = "#577790";
export const CHART_COLOR_NOT_ACTIVE = "#a0a0a0";
export const CHART_COLOR_ACTIVE = "#21ba45";

export const DEFAULT_COLOR = "#632FA7";
export const DEFAULT_DOWNTIME_COLOR = "#632FA7";
export const DEFAULT_LINE_COLOR = "#632FA7";
export const DEFAULT_PRODUCT_COLOR = "#632FA7";
export const COLOR_OPACITY = 0.85;
export const TEXT_COLOR_OPACITY = 1;
export const DISPLAY_COLORS = {
  red: `rgb(255,0,0, ${COLOR_OPACITY})`,
  orange: `rgb(254,154,118, ${COLOR_OPACITY})`,
  yellow: `rgb(255,215,0, ${COLOR_OPACITY})`,
  green: `rgb(33,186,69, ${COLOR_OPACITY})`,
  teal: `rgb(0,128,128, ${COLOR_OPACITY})`,
  blue: `rgb(14,110,184, ${COLOR_OPACITY})`,
  violet: `rgb(238,130,238, ${COLOR_OPACITY})`,
  purple: `rgb(180,19,236, ${COLOR_OPACITY})`,
  pink: `rgb(255,20,147, ${COLOR_OPACITY})`,
  brown: `rgb(165,42,42, ${COLOR_OPACITY})`,
  darkgrey: `rgb(51,51,51, ${COLOR_OPACITY})`,
  darkpurple: `rgb(48,25,52, ${COLOR_OPACITY})`,
  darkblue: `rgb(0,51,153, ${COLOR_OPACITY})`,
  lightpink: `rgb(255,153,153, ${COLOR_OPACITY})`,
  grey: `rgb(160,160,160, ${COLOR_OPACITY})`,
  blackForText: `rgb(64,64,64, ${TEXT_COLOR_OPACITY})`,
  black: `rgb(0,0,0, ${TEXT_COLOR_OPACITY})`,
  white: `rgb(255,255,255, ${TEXT_COLOR_OPACITY})`,
};

export const DISPLAY_COLORS_FLAT = {
  red: "#ff0000",
  orange: "#fe9a0b",
  yellow: "#ffd700",
  green: "#21ba45",
  teal: "#008080",
  blue: "#0e6eb8",
  violet: "#ee82ee",
  purple: "#b413ec",
  pink: "#ff1493",
  brown: "#a52a2a",
  darkgrey: "#333333",
  lightgrey: "#f2f2f2",
  lightblue: "#5f6ad4",
  lightbrown: "#825757",
  darkpurple: "#301934",
  darkblue: "#283399",
  lightpink: "#ff9999",
  grey: "#a0a0a0",
};



