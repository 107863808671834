import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useSimpleLinearProgressStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "320px",
    position: "absolute",
    bottom: theme.spacing(0.625),
  },
  linearProgress: {
    width: "100%",
    position: "absolute",
    height: theme.spacing(2),
  },
}));
