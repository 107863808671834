import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { myWhite, myWhiteShade0, tableAlternativeColor } from "../../constants/colors";
import { Theme } from "@mui/material";

const useStyles = makeStyles((_: Theme) => ({
  root: {
    alignItems: "stretch",
    justifyContent: "stretch",
    padding: "0 0",
    margin: "0",

    "&:nth-child(odd)": {
      backgroundColor: myWhite,
    },
    "&:nth-child(even)": {
      backgroundColor: tableAlternativeColor,
    },
    display: "grid",
  },
  readonly: {
    backgroundColor: myWhiteShade0,
  },
}));

const StyledTableGridRow = ({ readonly, extraClasses, ...rest }: any) => {
  const classes = useStyles();
  return extraClasses ? (
    <div
      className={classNames(classes.root, ...extraClasses, {
        [classes.readonly]: readonly,
      })}
      {...rest}
    />
  ) : (
    <div
      className={classNames(classes.root, { [classes.readonly]: readonly })}
      {...rest}
    />
  );
};

export default StyledTableGridRow;
