import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useSiteSelectionComponentStyles = makeStyles((theme: Theme) => ({

  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    
  },
  filterContainer: {
    display: "flex",
    width: "100%",
  },

  formControl: {
    display: "flex",
    width: "100%",
  },
  logType: {
    marginTop: theme.spacing(0.2)
  }
}));