
import {
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import classNames from 'classnames';

import { Clear } from '@mui/icons-material';
import { useSimpleDropdownStyles } from "./SimpleDropdown.css";
import StyledSelect from "../StyledSelector/StyledSelector";
import { useGlobalStyles } from "../../../constants/globalstyles";
import { useVrsTranslationState } from '../../../context/AppContext/AppContext';

interface SimpleDropdownProps {
  id?: string;
  disabled?: boolean;
  required?: boolean;
  labelId?: string;
  label: string;
  name: string;
  placeholder: string;
  options: Array<any>;
  value: any;
  onChange: any;
  canRemoveAll: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  variant?: string;
  size?: string;
}

const SimpleDropdown = ({
  id,
  disabled,
  required,
  labelId,
  label,
  name,
  placeholder,
  options,
  value,
  onChange,
  canRemoveAll,
  error,
  helperText,
  className,
  variant,
  size
}: SimpleDropdownProps) => {
  const globalStyle = useGlobalStyles();
  const { _T } = useVrsTranslationState();
  const classes = useSimpleDropdownStyles();
  const hasAnyValue = (): boolean => {
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    if (String(value).length > 0) {
      return true;
    }

    return false;
  };

  const removeAll = () => {
    onChange("");
  };

  const noOptionAvailableText =
    options && options.length === 0
      ? ` (${_T("NoItemExitsToSelect")})`
      : "";

  const iconComponent: any =
    !noOptionAvailableText && canRemoveAll
      ? {
          /* eslint-disable-next-line react/display-name */
          IconComponent: () => (
            <IconButton onClick={removeAll}>
              <Clear />
            </IconButton>
          ),
        }
      : {};

  return (
    <>
      <InputLabel
        className={classNames({
          [classes.inputLabel]: true,
          [classes.dimmedLabel]: !required && !error && !hasAnyValue(),
          [globalStyle.required]: required && error && !hasAnyValue(),
        })}
        id={labelId}
      >{`${label}${noOptionAvailableText}`}</InputLabel>
      <StyledSelect
        id={id}
        required={required}
        variant={variant ? variant : undefined}
        multiple={false}
        name={name}
        value={value}
        label={
          label || noOptionAvailableText
            ? `${label}${noOptionAvailableText}`
            : undefined
        }
        onChange={(event) => onChange(event.target.value)}
        {...iconComponent}
        placeholder={placeholder}
        disabled={(options && options.length === 0) || disabled}
        classes={{ root: className ? className : classes.root }}
        error={error}
        size={size}
      >
        {options &&
          options.map((el) => {
            return (
              <MenuItem value={el.value} key={el.value}>
                {el.icon ? (
                  <div className={classes.valueContainer}>
                    <div className={classes.icon}>{el.icon}</div>
                    <ListItemText
                      primary={el.text}
                      classes={{ root: classes.itemText }}
                    />
                  </div>
                ) : (
                  <ListItemText
                    primary={el.text}
                    classes={{ root: classes.itemText }}
                  />
                )}
              </MenuItem>
            );
          })}
      </StyledSelect>
      {helperText && (
        <div className={classes.errorText}>
          {helperText}
        </div>
      )}
    </>
  );
};

export { SimpleDropdown };
