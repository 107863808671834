import { useMemo, useEffect, useState, useCallback } from "react";
import { Button, Grid } from "@mui/material";
import toastr from "toastr";
import { Logger } from "../../utilities/Logger/Logger";
import { useSetTitleAction } from "../../actions/useActions/useSetTitleAction/useSetTitleAction";
import StyledContentCard from "../../components/Basic/StyledContentCard/StyledContentCard";
import { useVrsDeviceActions } from "../../actions/vrsDeviceActions";
import { useResRequestPrinterLogsActions } from "../../actions/resRequestPrinterLogsActions";
import { useVrsLoadPrinterLogsActions } from "../../actions/vrsListPrinterLogsActions";
import { useVrsGetPrinterLogActions } from "../../actions/vrsGetPrinterLogActions";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import {
  useAppCompanyState,
  useAppGlobalState,
} from "../../context/AppContext/AppContext";
import { useLogManagementStyles } from "./LogManagement.css";
import SiteSelectionComponent from "./components/SiteSelectionComponent/SiteSelectionComponent";
import { useParams } from "react-router";
import FileUploadDialog from "./components/FileUploadDialog/FileUploadDialog";
import { ConfigFileData } from "./components/FileUploadDialog/ConfigFileData";
import StyledTableGrid from "../../components/StyledTableGrid/StyledTableGrid";
import StyledTableGridHeadRow from "../../components/StyledTableGridHeadRow/StyledTableGridHeadRow";
import StyledTableGridHeadCell from "../../components/StyledTableGridHeadCell/StyledTableGridHeadCell";
import StyledTableGridRow from "../../components/StyledTableGridRow/StyledTableGridRow";
import StyledTableGridCell from "../../components/StyledTableGridCell/StyledTableGridCell";
import { EmptyItem } from "../../components/Basic/EmptyItem/EmptyItem";
import ActionContainer from "../../components/Basic/ActionContainer/ActionContainer";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { RequestLogsDialog } from "./components/RequestLogsDialog/RequestLogsDialog";
import Utils from "../../utilities/utils";
import { CogIcon } from "../../components/CogIcon/CogIcon";
export default function LogManagement() {
  const setTitle = useSetTitleAction();

  const { printerId } = useParams<{ printerId: string }>();
  const { appFilters } = useAppGlobalState();
  const vrsDeviceActions = useVrsDeviceActions();
  const vrsListPrinterLogsActions = useVrsLoadPrinterLogsActions();
  const vrsGetPrinterLogActions = useVrsGetPrinterLogActions();
  const resRequestPrinterLogsActions = useResRequestPrinterLogsActions();
  const companyState = useAppCompanyState();
  const classes = useLogManagementStyles();
  const { _T } = useVrsTranslationState();
  const [devices, setDevices] = useState<any>(null);
  const [logs, setLogs] = useState<any>(null);
  const [selectedPrinterId, setSelectedPrinterId] = useState<string>(
    printerId ? printerId.toLowerCase() : ""
  );
  const [selectedResName, setSelectedResName] = useState<string>("");
  const [selectedDeviceSerialNumber, setSelectedDeviceSerialNumber] =
    useState<string>("");
  const [selectedLogType, setSelectedLogType] = useState<string>("");
  const [selectedRequestLogType, setSelectedRequestLogType] =
    useState<string>("");

  const [logRequested, setLogRequested] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [requestLogsDialogOpen, setRequestLogsDialogOpen] =
    useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (value: ConfigFileData) => {
    setOpen(false);
    if (value) {
      setSelectedFile(value);
      resRequestPrinterLogsActions
        .sendAppSyncLogConfig(
          value.FileData,
          value.FileName,
          selectedPrinterId,
          selectedResName
        )
        .then((resultBody) => {
          const response = resultBody?.results?.sendLogConfig;
          if (response?.Result) {
            toastr.success(_T("Config successfully sent."));
          } else {
            toastr.error(_T("Config not sent.") + ` ${response?.Reason || ""}`);
          }
        });
    }
  };

  const [dataInitiallyLoaded, setDataInitiallyLoaded] =
    useState<boolean>(false);

  const activeSiteId = useMemo(
    () =>
      companyState.selectedSiteIdForCompany === "0"
        ? ""
        : companyState.selectedSiteIdForCompany,
    [companyState.selectedSiteIdForCompany]
  );
  const activeCompanyId = useMemo(
    () => companyState.selectedCompanyId,
    [companyState.selectedCompanyId]
  );

  const activeSerialNumber = appFilters.deviceFilter.serialNumber;
  const activeTimeRange = appFilters.deviceFilter.timeRange;

  useEffect(() => {
    setTitle(
      _T("Device Logs"),
      CogIcon,
      {
        ignoreItself: true,
        parents: [
          {
            id: _T("Configuration"),
            textOnly: true,
          },
          {
            id: _T("Device Logs"),
            textOnly: true,
          },
        ],
      },
      true
    );
  }, [setTitle, _T]);

  const refreshLogList = useCallback(() => {
    try {
      Logger.of("App.LogManagement").info("Refreshing Logs");
      setLogs(null);
      let logType = selectedLogType;
      if (logType == "All") {
        logType = "";
      }
      if (activeSiteId !== null && parseInt(activeSiteId) > 0) {
        vrsListPrinterLogsActions
          .loadAppSyncListPrinterLogs(activeSiteId, selectedPrinterId, logType)
          .then((logs) => {
            setLogs(logs.sort(function(a,b){
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            }));
          });
      }
    } catch (error) {
      Logger.of("App.LogManagement").info("Error Refreshing logs", error);
    }
  }, [
    vrsListPrinterLogsActions,
    activeSiteId,
    selectedPrinterId,
    selectedLogType,
  ]);

  function startLogDownload(key) {
    try {
      Logger.of("App.LogManagement").info("Log Download");
      vrsGetPrinterLogActions.loadAppSyncGetPrinterLog(key).then((response) => {
        const link = document.createElement("a");
        link.download = response.data.logDownload.FileName;
        link.href = response.data.logDownload.SecureLink;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      Logger.of("App.LogManagement").error(error);
      toastr.error(error);
    }
  }

  function performSetSelectedPrinterId(newPrinterId) {
    if (newPrinterId == null || newPrinterId === undefined) {
      return;
    }

    vrsDeviceActions
      .loadAppSyncVrsDevicesSimple(
        activeCompanyId,
        activeSiteId,
        activeTimeRange,
        activeSerialNumber,
      )
      .then((devices) => {
        const dev = devices.find(
          (p) =>
            p.printerSerialNumber.toLowerCase() === newPrinterId.toLowerCase()
        );
        if (dev && dev.printerIpAddress) {
          const ipArray = dev.printerIpAddress.split("|");
          if (ipArray.length > 1) {
            const resid = ipArray[ipArray.length - 2];
            const stage: string = Utils.getStage();
            setSelectedResName(
              stage.replace("production", "prod") + "-" + resid
            );
          }
          setSelectedDeviceSerialNumber(dev.printerSerialNumber);
        }
      });
    setSelectedPrinterId(newPrinterId);
  }

  function performRequestLogs() {
    try {
      Logger.of("App.LogManagement").info("Log Request");
      setLogRequested(true);
    } catch (error) {
      Logger.of("App.LogManagement").error(error);
    }
  }

  function performSetSelectedLogType(newLogType) {
    const tempLogType = newLogType;
    setDataInitiallyLoaded(false);
    setSelectedLogType(tempLogType);
  }
  useEffect(() => {
    if (selectedPrinterId && !dataInitiallyLoaded) {
      setDataInitiallyLoaded(true);
    }
  }, [selectedPrinterId, dataInitiallyLoaded]);

  useEffect(() => {
    refreshLogList();
  }, [activeCompanyId, activeSiteId, selectedPrinterId, selectedLogType]);

  useEffect(() => {
    if (logRequested === true) {
      resRequestPrinterLogsActions
        .loadAppSyncRequestPrinterLogs(
          selectedResName,
          selectedDeviceSerialNumber,
          selectedRequestLogType
        )
        .then((resultBody) => {
          const response = resultBody?.results?.logUploadRequest;
          if (response?.Result) {
            toastr.success(
              _T("Logs Requested!") + ` ${response?.Reason || ""}`
            );
          } else {
            toastr.error(
              _T("Logs request not sent.") + ` ${response?.Reason || ""}`
            );
          }
        });

      setLogRequested(false);
    }
  }, [logRequested]);

  useEffect(() => {
    (async () => {
      const devices = await vrsDeviceActions.loadAppSyncVrsDevicesSimple(
        activeCompanyId,
        activeSiteId,
        {
          value: "1",
          start: "",
          end: "",
        },
        activeSerialNumber,
      );
      setDevices(devices);
    })();
  }, [
    activeSiteId,
    activeCompanyId,
    activeSerialNumber,
    activeTimeRange,
    vrsDeviceActions,
  ]);
  function RequestLogs() {
    setRequestLogsDialogOpen(true);
  }

  function RequestDialogCancel() {
    setRequestLogsDialogOpen(false);
  }
  function RequestDialogSave(logTypeArray: number[]) {
    const result = logTypeArray.reduce(function (a, b) {
      return a | b;
    }, 0);
    setSelectedRequestLogType(result.toString());
    setRequestLogsDialogOpen(false);
    performRequestLogs();
  }
  function DownloadLog(_, key) {
    startLogDownload(key);
  }
  const fileTypes = [
    { text: _T("All"), value: "All" },
    { text: _T("User File"), value: "UserLog" },
    { text: _T("Application Log"), value: "ApplicationLog" },
    { text: _T("Core Dump"), value: "CoreDump" },
  ];
  const getGridStyles = useCallback(() => {
    return [
      classes.grid7ColumnsClasses,
      classes.row7ColumnsClasses,
      classes.cell7ColumnsClasses,
    ];
  }, [classes]);
  const [gridClasses, rowClasses, cellClasses] = getGridStyles();
  function humanFileSize(size: number): string {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    const sizeCalc: string = (size / Math.pow(1024, i)).toFixed(2);
    return sizeCalc + " " + ["B", "kB", "MB", "GB", "TB"][i];
  }

  return (
    <>
      <Grid>
        <Grid item xs={12} className={classes.gridSpacer}>
          <StyledContentCard classes={{ root: classes.removeMargin }}>
            <SiteSelectionComponent
              fileTypes={fileTypes}
              selectedPrinterId={selectedPrinterId}
              performSetSelectedPrinterId={performSetSelectedPrinterId}
              performSetSelectedLogType={performSetSelectedLogType}
              devices={devices}
              printerModelName={""}
            />
          </StyledContentCard>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              disabled={
                !(selectedPrinterId != null && selectedPrinterId !== "")
              }
              onClick={RequestLogs}
              variant="contained"
            >
              +&nbsp;{_T("Request Logs")}
            </Button>
            &nbsp;
            <Button
              color="primary"
              disabled={
                !(selectedPrinterId != null && selectedPrinterId !== "")
              }
              onClick={handleClickOpen}
              variant="contained"
            >
              +&nbsp;{_T("Send Log Config")}
            </Button>
          </div>
          <FileUploadDialog
            selectedValue={selectedFile}
            open={open}
            onClose={handleDialogClose}
          />
          <RequestLogsDialog
            open={requestLogsDialogOpen}
            onClose={RequestDialogCancel}
            onSave={RequestDialogSave}
          />
        </Grid>
      </Grid>
      {logs != null && logs.length > 0 ? (
        <StyledTableGrid
          classes={{ root: gridClasses }}
          className={classes.table}
          aria-label="simple table"
        >
          <StyledTableGridHeadRow extraClasses={[rowClasses]}>
            <StyledTableGridHeadCell align="left">
              {_T("File Name")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="left">
              {_T("Name")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="left">
              {" "}
              {_T("Serial Number")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="left">
              {" "}
              {_T("Model Name")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="left">
              {" "}
              {_T("Size")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="right">
              {" "}
              {_T("Date")}
            </StyledTableGridHeadCell>
            <StyledTableGridHeadCell align="right">
              {" "}
              {_T("Actions")}
            </StyledTableGridHeadCell>
          </StyledTableGridHeadRow>
          <>
            {logs.map((row) => (
              <StyledTableGridRow
                key={row.filePath}
                extraClasses={[rowClasses]}
              >
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{row.name}</div>
                </StyledTableGridCell>
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{row.printerName}</div>
                </StyledTableGridCell>
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{row.printerId}</div>
                </StyledTableGridCell>
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{row.modelName}</div>
                </StyledTableGridCell> 
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{humanFileSize(row.size)}</div>
                </StyledTableGridCell>
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <div>{row.date}</div>
                </StyledTableGridCell>
                <StyledTableGridCell classes={{ root: cellClasses }}>
                  <ActionContainer>
                    <ActionButton
                      onClick={(event) => DownloadLog(event, row.filePath)}
                    >
                      <CloudDownloadIcon />
                    </ActionButton>
                  </ActionContainer>
                </StyledTableGridCell>
              </StyledTableGridRow>
            ))}
          </>
        </StyledTableGrid>
      ) : (
        <EmptyItem text={_T("No Results Found")} />
      )}
    </>
  );
}
