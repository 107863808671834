const AddressLabel = (props: {
  address: string
}) => {
  const { address } = props;

  if (address) {
    return (
      <>
        {address}
      </>
    )
  }
  return <></>;
};


export default AddressLabel;
