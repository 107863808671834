import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import {
  mySecondaryColor,
  mySecondaryColorDimmed,
} from "../../constants/colors";

export default makeStyles((theme: Theme) => ({
  link: {
    padding: '2px 5px',
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: mySecondaryColorDimmed,
    },
  },

  linkIcon: {
    color: mySecondaryColor,
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
  },

  linkText: {
    padding: 0,
    color: mySecondaryColor,
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: "1.06rem",
  },
}));
