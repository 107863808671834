import moment from "moment-timezone";
import { invokeApig, invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import { ICompany } from "../interfaces/Company/ICompany";
import { IPlant } from "../interfaces/Site/IPlant";
import { IAllCompany } from "../interfaces/Company/IAllCompany";
import { ISaveCompanyInput } from "../interfaces/Company/ISaveCompanyInput";
import utils from "../utilities/utils";
import { ICompanyV2 } from "./SummaryPage/ICompanyV2";

const baseUrl = () => `${getApiPrefix()}/companies`;
const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_COMPANY_URL"];
};

class CompanyApi {
  static getCompanies() {
    return invokeApig({ gatewayName: "COMPANIES", path: baseUrl() });
  }

  static getCompany(companyId) {
    const apiUrl = getApiPrefix() + `/companies/${companyId}`;
    return invokeApig({ gatewayName: "COMPANIES", path: apiUrl });
  }

  static getSitesForCompany(companyId) {
    const apiUrl = getApiPrefix() + `/companies/${companyId}/sites`;
    return invokeApig({ gatewayName: "COMPANIES", path: apiUrl });
  }

  static migrateCompanies(companies) {
    const apiUrl = `${getApiPrefix()}/migratecompanies`;
    return invokeApig({
      gatewayName: "COMPANIES",
      method: "POST",
      path: apiUrl,
      body: companies,
    });
  }

  static getCompaniesAppSync(ignoreFavorites = false) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const graphQLBody = {
      query: `
          query CompaniesQuery {
            companies (ignoreFavorites: ${ignoreFavorites}) {
              CompanyId
              CompanyName
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_COMPANY",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.companies) {
        const companies: Array<ICompany> = results?.data?.companies.map(
          (el) => ({
            companyId: el.CompanyId.toString(),
            companyName: el.CompanyName,
          })
        );

        //Fix multiple names
        const companyObj: any = {};
        const multipleCompanies: any = {};
        for (const company of companies) {
          const { companyName } = company;
          if (companyObj[companyName]) {
            companyObj[companyName] = {
              companies: [...companyObj[companyName].companies, company],
              count: companyObj[companyName].count + 1,
            };
            multipleCompanies[companyName] = companyObj[companyName];
          } else {
            companyObj[companyName] = { companies: [company], count: 1 };
          }
        }

        for (const key of Object.keys(multipleCompanies)) {
          const { companies } = multipleCompanies[key];
          let index = 0;
          for (const company of companies) {
            if (index > 0) {
              company.companyName = `${company.companyName} (${index})`;
            }
            index++;
          }
        }

        return companies;
      }
      return [];
    });
  }

  static getPlantsAppSync(
    companyId: string,
    allSitesForAdministrator: boolean,
    ignoreFavorites = false
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const graphQLBody = {
      query: `
          query PlantsQuery {
            plants(companyId: "${companyId}", ignoreFavorites: ${ignoreFavorites}, allSitesForAdministrator: ${allSitesForAdministrator}) {
              CompanyId
              CompanyName
              PlantId
              PlantName
              CountryId
              SupportPhoneNumber
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SITE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.plants) {
        const plants: Array<IPlant> = results?.data?.plants.map((el) => ({
          companyId: el.CompanyId.toString(),
          supportPhoneNumber: el.SupportPhoneNumber || "",
          companyName: el.CompanyName,
          id: el.PlantId.toString(),
          name: el.PlantName,
          countryId:
            el.CountryId || el.CountryId === 0 ? el.CountryId.toString() : "",
        }));
        return plants;
      }
      return [];
    });
  }

  static getCompanyAppSync(companyId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query CompanyQuery {
            company(companyId: "${companyId}") {
              CompanyId
              CompanyName
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_COMPANY",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      const company: Array<ICompany> = [];
      if (results.data?.company) {
        const item = results.data?.company;
        company.push({
          companyId: item.CompanyId.toString(),
          companyName: item.CompanyName,
        });
      }
      return company;
    });
  }

  static getCompanyListAppSync() {
    const allCompany: IAllCompany = {
      companyList: [],
      deletedCompanyList: [],
      isSuperUser: false,
    };
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve(allCompany);
    }

    const graphQLBody = {
      query: `
          query CompanyListQuery {
            companyList {
              CompanyList {
                CompanyId
                CompanyName
                IsDeleted
                PlantCount
                PrinterCount
                UserCount
              }
              DeletedCompanyList {
                CompanyId
                CompanyName
                IsDeleted
                PlantCount
                PrinterCount
                UserCount
              }
              IsSuperUser
              UniqueId
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_COMPANY",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.companyList) {
        const allData = results.data?.companyList;
        if (allData.CompanyList) {
          for (const company of allData.CompanyList) {
            allCompany.companyList.push({
              companyId: company.CompanyId,
              companyName: company.CompanyName,
              plantCount: company.PlantCount,
              userCount: company.UserCount,
              printerCount: company.PrinterCount,
              isDeleted: company.isDeleted,
            });
          }
        }

        if (allData.DeletedCompanyList) {
          for (const company of allData.DeletedCompanyList) {
            allCompany.deletedCompanyList.push({
              companyId: company.CompanyId,
              companyName: company.CompanyName,
              plantCount: company.PlantCount,
              userCount: company.UserCount,
              printerCount: company.PrinterCount,
              isDeleted: company.isDeleted,
            });
          }
        }
        allCompany.isSuperUser = allData.IsSuperUser;
      }
      return allCompany;
    });
  }

  static saveCompanyAppSync(saveCompanyInput: ISaveCompanyInput) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation SaveCompanyMutation
      {
        saveCompany(saveCompanyInput: {
          CompanyId: "${saveCompanyInput.CompanyId}"
          CompanyName: "${saveCompanyInput.CompanyName}"
          cbUsePrinterLicense: "${saveCompanyInput.cbUsePrinterLicense}"
          PrinterLicenseStartDate: ${
            saveCompanyInput.PrinterLicenseStartDate
              ? '"' +
                utils.evaluateAsUtc(
                  moment(saveCompanyInput.PrinterLicenseStartDate),
                  true
                ) +
                '"'
              : null
          }
          PrinterLicenseEndDate: ${
            saveCompanyInput.PrinterLicenseEndDate
              ? '"' +
                utils.evaluateAsUtc(
                  moment(saveCompanyInput.PrinterLicenseEndDate),
                  true
                ) +
                '"'
              : null
          }
          IsDeleted: "${saveCompanyInput.IsDeleted}"
          hdIsUptimeGuaranteed: "${saveCompanyInput.hdIsUptimeGuaranteed}"
          UptimePercentageThreshold: "${
            saveCompanyInput.UptimePercentageThreshold
          }"
          HealthscorePercentageThreshold: "${
            saveCompanyInput.HealthscorePercentageThreshold
          }"
        }) {
          CompanyId
          CompanyName
          CreationDate
          IsDeleted
          PlantCreationDate
          UploadedDataToServer
          UploadedDateTime
          }
        }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_COMPANY",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveCompany) {
        return {
          error: null,
          data: results.data?.saveCompany,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static restoreCompanyAppSync(companyId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation RestoreCompanyMutation
      {
        restoreCompany(companyId: "${companyId}")
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_COMPANY",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.restoreCompany) {
        return {
          error: null,
          data: results.data?.restoreCompany,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static getPlantAppSync(plantId: number) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
          query PlantQuery {
            plant(plantId: ${plantId}) {
              CompanyId
              CompanyName
              PlantId
              PlantName
              CountryId
              IsExternal
              SupportPhoneNumber
              Webhooks {
                WebhookId
                WebhookType
                Url
                Secret
                Active
              }
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SITE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      const plants: Array<IPlant> = [];
      if (results.data?.plant) {
        const item = results.data?.plant;
        plants.push({
          companyId: item.CompanyId.toString(),
          supportPhoneNumber: item.SupportPhoneNumber || "",
          companyName: item.CompanyName,
          id: item.PlantId.toString(),
          name: item.PlantName,
          isExternal: item.IsExternal,
          countryId:
            item.CountryId || item.CountryId === 0
              ? item.CountryId.toString()
              : "",
          webhooks: item.Webhooks
        });
      }
      return plants;
    });
  }

  
static getCompaniesV2AppSync() {
  if (!checkAppSyncUrlExists()) {
    return Promise.resolve([]);
  }

  const graphQLBody = {
    query: `
        query CompaniesV2Query {
          company_v2(companyInput: {}) {
            Name
            Id
            Deleted
          }
        }
      `,
  };

  return invokeAppSync({
    gatewayName: "VRS_APPSYNC_COMPANY",
    path: baseAppSyncUrl(),
    method: "POST",
    body: graphQLBody,
  }).then((results) => {
    if (results.data?.company_v2) {
      const companies: Array<ICompanyV2> = results?.data?.company_v2.map(
        (el) => ({
          Name: el.Name.toString(),
          Id: el.Id,
          Deleted: el.Deleted
        })
      );
      return companies;
    }
    return [];
  });
}
}

export default CompanyApi;
