import { ROLE_NONE, ROLE_ADMIN } from "../actions/actionTypes";

export const initalState: any = {
  ajaxCallsInProgress: 0,
  assessments: {},
  cirrus: {},
  config: {
    downtimeTypes: [
      { id: '1', name: 'Planned' },
      { id: '2', name: 'Unplanned' },
    ],
    inspectionStepTypes: [
      { value: 0, text: 'Visual Verify' , transId: 'VisualVerify'},
      { value: 1, text: 'Compare to Reference', transId: 'CompareToReference'},
      { value: 2, text: 'Add Image', transId: 'AddImage'},
      { value: 3, text: 'Capture Value',  transId: 'CaptureValue'},
    ],
    inspectionFreqTypes: [
      { value: 0, text: 'One Time' , transId: 'OneTime'},
      { value: 1, text: 'Hourly', transId: 'Hourly'},
      { value: 2, text: 'Minutes Between', transId: 'MinutesBetween'},
      { value: 3, text: 'On Shift Changeover', transId: 'OnShiftChangeover' },
      { value: 4, text: 'On Demand', transId: 'OnDemand' },
      { value: 5, text: 'On Product Changeover', transId: 'OnProductChangeover' },
    ],
    inspectionRefCompareTypes: [
      { value: 0, text: '>' },
      { value: 1, text: '>=' },
      { value: 2, text: '<' },
      { value: 3, text: '<=' },
      { value: 4, text: '==' },
      { value: 5, text: 'between' },
    ],
    monthNames: [
      { text: 'January', value: 1 },
      { text: 'February', value: 2 },
      { text: 'March', value: 3 },
      { text: 'April', value: 4 },
      { text: 'May', value: 5 },
      { text: 'June', value: 6 },
      { text: 'July', value: 7 },
      { text: 'August', value: 8 },
      { text: 'September', value: 9 },
      { text: 'October', value: 10 },
      { text: 'November', value: 11 },
      { text: 'December', value: 12 },
    ],
    printerRoles: [
      { id: '1', name: 'Each', transId: 'Each' },
      { id: '2', name: 'Case', transId: 'Case' },
      { id: '3', name: 'Pallet', transId: 'Pallet' },
    ],
    quarterNames: [
      { text: 'Q1 Jan-Mar', value: 1 },
      { text: 'Q2 Apr-Jun', value: 2 },
      { text: '03 Jul-Sep', value: 3 },
      { text: 'Q4 Oct-Dec', value: 4 },
    ],
    shiftRepeats: [
      { value: 0, text: 'Never', transId: 'Never' },
      { value: 1, text: 'Daily', transId: 'Daily'  },
      { value: 2, text: 'Weekly', transId: 'Weekly' },
    ],
    shiftSetupRoles: [
      { id: '1', name: 'Shift' },
      { id: '3', name: 'Planned Downtime' },
    ],
    title: '',
    userRoles: [
      { id: ROLE_NONE, name: 'No Access' },
      { id: ROLE_ADMIN, name: 'Admin' },
    ],
    weekDaysShort: [
      { 0: 'SU' },
      { 1: 'MO' },
      { 2: 'TU' },
      { 3: 'WE' },
      { 4: 'TH' },
      { 5: 'FR' },
      { 6: 'SA' },
    ],
    operationRoles: [
      { value: 'Videojet-ContractAdmin', text: 'Videojet-ContractAdmin'},
      { value: 'Videojet-Service', text: 'Videojet-Service'},
      { value: 'Videojet', text: 'Videojet' },
      { value: 'Videojet-CSE', text: 'Videojet-CSE' },
      { value: 'Videojet-SalesUser', text: 'Videojet-SalesUser' }
    ],
  },
  downtimeCategories: [],
  downtimeEvents: [],
  inspections: [],
  lines: [],
  lineUtilization: [],
  products: [],
  roles: [],
  sites: [],
  shifts: [],
  users: [],
};

export default initalState;
