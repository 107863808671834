import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAboutDialogStyles = makeStyles((_: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  }
}));