import { styled } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#333333',
    fontSize: theme.typography.h6.fontSize,
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    color: 'white',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-virtualScroller': {
    borderRadius: '0px !important',
  },
  [`& .${gridClasses.row}`]: {
    backgroundColor: 'white',
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#e3f1fb',
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-menuIconButton': {
    color: 'white',
  },
  '& .MuiDataGrid-filterIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-columnSeparator': {
    opacity: '1 !important',
    color: 'white',
  },
  '& .MuiDataGrid-cell--textCenter': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
