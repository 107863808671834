import { OeeUtil } from 'vccm-common/src/calc';
import { Logger } from './Logger/Logger';
import moment from "moment-timezone";

export function getPerformance(data) {
  if (data.reportedMinutes && data.plannedMinutes && data.currentTargetCount) {
    return OeeUtil.getPerformance({
      totalCount: data.count,
      runTime: data.plannedMinutes,
      currentTargetCount: data.currentTargetCount,
      totalTime: data.reportedMinutes,
      ingestVersion: data.ingestVersion,
      plannedTime: data.reportedMinutes - data.plannedStoppedMinutes
    });
  }
  return 0;
}

export function getQuality(data) {
  if (data.count) {
    return OeeUtil.getQuality(data.count, data.badPieceCount);
  }
  return 1;
}

export function getOee(data) {
  const quality = getQuality(data);
  const availability = OeeUtil.getRecordAvailability(data);
  const performance = getPerformance(data);
  return quality * availability * performance;
}

export function reduceHours(oeeHours, keepSRM = false) {
  return oeeHours.reduce(
    (sums, event) => {
      Object.keys(sums).forEach((key) => {
        if (event.hasOwnProperty(key) && key !== 'ingestVersion') sums[key] += event[key]; // eslint-disable-line no-prototype-builtins
      });
      sums.runningHours += event.count > 0 ? 1 : 0;
      if(event.plannedStoppedMinutes == null || event.plannedStoppedMinutes == undefined) sums.plannedStoppedMinutes += (event.reportedMinutes - event.scheduledRunMinutes);
      sums.ingestVersion = sums.ingestVersion || event.ingestVersion;
      if (!keepSRM) {
        sums.scheduledRunMinutes = null;
      }
      
      return sums;
    },
    {
      count: 0,
      runningHours: 0,
      runningMinutes: 0,
      reportedMinutes: 0,
      plannedMinutes: 0,
      plannedStoppedMinutes: 0,
      unplannedStoppedMinutes: 0,
      currentTargetCount: 0,
      badPieceCount: 0,
      ingestVersion: undefined,
      scheduledRunMinutes: 0
    },
  );
}

export function getOeeHoursWithinShifts(currentShifts, lines) {
  const oeeHoursToDisplay: Array<any> = [];
  const scheduleShifts = currentShifts.filter(shift => shift.typeId === 1 || shift.typeId === '1');

  scheduleShifts.forEach((shiftItem) => {
    const hours:any = [];
    const numberOfHours = Math.abs(shiftItem.end - shiftItem.start) / 36e5;
    for (let index = 0; index < numberOfHours; index++) {
      const startMoment = moment.utc(shiftItem.start);
      const hour = startMoment.clone().add(index, "hours");
      hours.push(hour);
    }
    
    const linesInShift = lines.filter(line => shiftItem.lineIds && shiftItem.lineIds.indexOf(line.id) > -1);

    const oeeHoursFromLines = linesInShift.flatMap(line => (line.oeeHour ? line.oeeHour : []));

    oeeHoursToDisplay.push(oeeHoursFromLines.filter((lineHour) => {
      if (hours.find(hour => hour.format('YYYY-MM-DD-HH') === lineHour.dateHour)) {
        return lineHour;
      }
      return false;
    }));
  });

  // i dont know why... yet.. but my system always has a problem with 'flatten()'
  const finalHours = oeeHoursToDisplay.flat(5);

  Logger.of('App.oeeCalculations.getOeeHoursWithinShifts').info('Final hours to calculated Oee with:');
  Logger.of('App.oeeCalculations.getOeeHoursWithinShifts').info(finalHours);

  return finalHours;
}

export const oeeCalculations = {
  getOee,
  reduceHours,
  getQuality,
  getPerformance
}

