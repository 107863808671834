import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import {
  myCorporatePrimaryColor,
  myDarkBackground,
  myPrimaryColor,
  myWhite,
  myWhiteColorDimmed,
} from "../../constants/colors";

export default makeStyles((theme: Theme) => ({
  logotype: {
    color: myWhite,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 600,
    fontSize: 22,
    whiteSpace: "nowrap",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  icon: {
    minWidth: '30px',
  },
  logo: {
    minHeight: theme.spacing(3.75),
    width: theme.spacing(25),
    marginTop: theme.spacing(1.25),
  },
  headerTop: {
    position: 'relative'
  },
  appBarBreadcrumb: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: myCorporatePrimaryColor,
    top: '70px',
    position: 'fixed'
  },

  appBarOutage: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'fixed',
    opacity: 0.4
  },
  outagePosition1: {
    top: '70px',
  },
  outagePosition2: {
    top: '90px',
  },
  appBarOutageToolBarOpen: {
    left: "320px",
  },
  appBarOutageRed: {
    backgroundColor: '#e8120b',
  },
  appBarOutageOrange: {
    backgroundColor: '#f78d1b',
  },
  appBarOutageYellow: {
    backgroundColor: '#dbde28',
    color: 'black'
  },
  outageTextParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  outageTextParentOpen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 320px)',
  },
  outageText: {
    textAlign: "center",
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: myCorporatePrimaryColor,
  },
  toolBarBreadcrumb: {
    minHeight: "unset",
  },
  outageBar: {
    minHeight: "unset",
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  mainMenu: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    minHeight: "30px",
  },
  hide: {
    display: "none",
  },
  logoContainerParent: {
    position: "relative",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "320px",
    backgroundColor: myDarkBackground,
  },
  logoContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "320px",
    backgroundColor: myDarkBackground,
  },
  outageEmptyParent: {
    position: "relative",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "320px",
    backgroundColor: "transparent",
  },
  outageEmpty: {
    position: "relative",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "320px",
    backgroundColor: "transparent",
  },
  addressContainerCommon: {
    display: "flex",
    flexDirection: "column",
    color: myWhite,
    alignItems: "flex-end",
  },
  addressTopContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  addressContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  addressContainerAsButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  addressContainerSmallAsButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  siteTitle: {
    fontSize: "15px",
    fontWeight: 600,
  },
  email: {
    fontSize: "15px",
    fontWeight: 600,
  },
  addressLines: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    alignItems: "flex-end",
    minWidth: theme.spacing(22.5),
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: myWhite,
    },
  },
  profileBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerMenuButton: {
    padding: theme.spacing(0.5),
    color: theme.palette.primary.main,
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  headerAccountIcon: {
    fontSize: 40,
    color: theme.palette.common.white,
    "&:hover": {
      color: myWhiteColorDimmed,
    },
  },
  headerIcon: {
    fontSize: 40,
    color: myCorporatePrimaryColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  headerIconCollapse: {
    color: myCorporatePrimaryColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  siteSelectorMenu: {
    minWidth: 265,
  },
  siteSelectorMenuItem: {
    display: "flex",
    padding: theme.spacing(2),
  },
  siteSelectorMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  siteSelectorMenuLink: {
    fontSize: 16,
    color: myPrimaryColor,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  profileMenu: {
    minWidth: 265,
    padding: '5px 20px'
  },
  profileMenuItem: {
    display: "flex",
    padding: theme.spacing(2),
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  profileMenuLink: {
    fontSize: 16,
    color: myPrimaryColor,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.default,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
  },
}));
