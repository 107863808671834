import { useMemo } from "react";
import VrsGetPrinterLogApi from "../api/prodGetPrinterLogApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";

import { GET_PRINTER_LOG_SUCCESS } from "./actionTypes";

export function loadGetPrinterLogSuccess(payload) {
  return { type: GET_PRINTER_LOG_SUCCESS, payload };
}

function loadAppSyncGetPrinterLog(
  key: string
) {
  return (_) => {
    return VrsGetPrinterLogApi.getAppSyncGetPrinterLog(
      key
    )
      .then((logDownload) => {
        return logDownload;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

export const useVrsGetPrinterLogActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      loadAppSyncGetPrinterLog: (
        key: string
      ) => loadAppSyncGetPrinterLog(key)(dispatch),
    }),
    [dispatch]
  );
};
