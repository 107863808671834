import { Card } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { standardBoxShadow } from "../../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(1.5, 0),
    borderRadius: theme.spacing(0.5),
    boxShadow: standardBoxShadow,
    padding: theme.spacing(1.5, 1.875),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 0.125),
    },
  },
}));

const StyledContentCard = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(commonClasses.root, classes.root)
    : commonClasses.root;
  return (
    <Card classes={{ ...classes, root: activeRootClasses }} {...props}>
      {children}
    </Card>
  );
};

export default StyledContentCard;
