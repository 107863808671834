import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import classNames from "classnames";
import {
  Inbox as InboxIcon,
  ExpandMore as ExpandMoreIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";
import classnames from "classnames";

// styles
import useStyles from "./SidebarLink.css";

// components
import { useLocation } from "react-router-dom";
import Utils from "../../../../utilities/utils";
import {
  useAppGlobalState,
  useAppSiteState,
} from "../../../../context/AppContext/AppContext";
import StyledIconButton from "../../../Basic/StyledIconButton/StyledIconButton";

const checkIsDisabledOrHidden = (children) => {
  return (
    children &&
    children.length > 0 &&
    children.length ===
      children.filter(
        (el) => el.disabled || el.hidden || checkIsDisabledOrHidden(el.children)
      ).length
  );
};

export interface ISidebarLinkProps {
  id: string;
  link?: string;
  icon?: any;
  label?: string;
  children?: any;
  disabled?: boolean;
  internalCall?: () => void;
  isSidebarOpened: boolean;
  nested?: any;
  type?: string;
  setActiveLink: any;
  status?: number;
  hidden?: boolean;
  capitalise?: boolean;
  siteModule?: string;
  extraModule?: string;
  site: any;
  activeLinkIndicators?: Array<string>;
  nonActiveLinkIndicators?: Array<string>;
  nonActiveLinkIndicators2?: Array<string>;
  hideIfModuleNotEnabled?: boolean;
  hideIfNotEnabled?: boolean;
  showAlways?: boolean;
  requireAdmin?: boolean;
  linkCollapseObject: { [key: string]: boolean };
  setLinkCollapseObject: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

export default function SidebarLink({
  link,
  icon,
  label,
  hidden,
  children,
  disabled,
  isSidebarOpened,
  nested,
  type,
  setActiveLink,
  capitalise,
  internalCall,
  siteModule,
  extraModule,
  site,
  activeLinkIndicators,
  nonActiveLinkIndicators,
  nonActiveLinkIndicators2,
  hideIfModuleNotEnabled,
  hideIfNotEnabled,
  showAlways,
  requireAdmin,
  linkCollapseObject,
  setLinkCollapseObject,
  id,
}: ISidebarLinkProps) {
  const classes = useStyles();
  const location = useLocation();
  const siteState = useAppSiteState();
  const { isAdmin } = useAppGlobalState();

  const isAnyChildrenActive = () => {
    if (children) {
      return !!children.find((el) => {
        const nonActive =
          !el.nonActiveLinkIndicators ||
          !!el.nonActiveLinkIndicators.find(
            (elm) => location.pathname.indexOf(elm) === -1
          );
        return (
          nonActive &&
          !!el.activeLinkIndicators &&
          !!el.activeLinkIndicators.find(
            (elm) => location.pathname.indexOf(elm) > -1
          )
        );
      });
    }

    return false;
  };

  useEffect(() => {
    if (linkCollapseObject[id] === undefined) {
      linkCollapseObject[id] = isAnyChildrenActive();
    }
  }, [linkCollapseObject, setLinkCollapseObject]);

  const LinkElementRef = useRef(null);
  const nonActive =
    !nonActiveLinkIndicators ||
    !!nonActiveLinkIndicators.find(
      (el) => location.pathname.indexOf(el) === -1
    );
  const nonActive2 =
    !nonActiveLinkIndicators2 ||
    !nonActiveLinkIndicators2.find((el) => location.pathname.indexOf(el) > -1);
  const isLinkActive =
    nonActive &&
    nonActive2 &&
    !!activeLinkIndicators &&
    !!activeLinkIndicators.find((el) => location.pathname.indexOf(el) > -1);
  const titleText = type === "titleText";

  const goToAbsoluteLink = (absLink) => {
    // ReactGA.event({
    //   category: 'WFX',
    //   action: 'Workflow Portal Launched',
    // });
    window.open(absLink, "_blank");
  };

  const CustomLink: any = useMemo(
    // eslint-disable-line react/display-name
    () => {
      const isAbsolute =
        link && (link.indexOf("http://") > -1 || link.indexOf("https://") > -1);
      if (link && !isAbsolute) {
        // eslint-disable-next-line react/display-name
        return forwardRef((linkProps, _) => (
          <div
            ref={LinkElementRef}
            {...linkProps}
            onClick={() => setActiveLink(link)}
          />
        ));
      }

      return "li";
    },
    [link, setActiveLink]
  );

  const linkLabel = label || "";

  const siteModuleMissing =
    siteModule && Utils.hasModuleMissing(site, siteModule);
  const extraModuleMissing =
    extraModule &&
    !!Utils.hasExtraModuleMissing(
      siteState.vrsAbilities.vrsInternalAccessAbilities,
      extraModule
    );

  const moduleMissing = !!(
    (siteModule && !extraModule && siteModuleMissing) ||
    (!siteModule && extraModule && extraModuleMissing) ||
    (siteModule && extraModule && siteModuleMissing && extraModuleMissing)
  );

  const hidingLogicForModule = hideIfModuleNotEnabled && moduleMissing;

  const hidingLogicForDisability =
    hideIfNotEnabled && (disabled || checkIsDisabledOrHidden(children));
  const hidingLogic =
    !showAlways && (hidingLogicForModule || hidingLogicForDisability);
  if (hidingLogic) {
    return null;
  }

  if (requireAdmin && !isAdmin) {
    return null;
  }

  if (!hidden && type === "title" && !children) {
    return (
      <div
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
          [classes.linkTextDisabled]: moduleMissing,
        })}
      >
        {capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel}
      </div>
    );
  }

  if (!showAlways && (hidden || (!titleText && moduleMissing))) {
    return null;
  }

  if (type === "divider") {
    return <Divider className={classes.divider} />;
  }

  const checkInternalCall = () => {
    const isAbsolute =
      link && (link.indexOf("http://") > -1 || link.indexOf("https://") > -1);
    if (isAbsolute) {
      return () => goToAbsoluteLink(link);
    }

    if (internalCall) {
      return internalCall;
    }

    return null;
  };

  if (!children)
    return (
      <ListItemButton
        id={id}
        dense={true}
        disabled={disabled || moduleMissing}
        className={classes.link}
        component={CustomLink}
        onClick={checkInternalCall()}
        classes={{
          root: classnames({
            [classes.linkActive]: isLinkActive,
            [classes.linkNested]: type !== "level3Menu" && nested,
            [classes.level3Menu]: type === "level3Menu",
          }),
        }}
        disableRipple
      >
        {!titleText && (
          <StyledIconButton>
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]: isLinkActive,
              })}
            >
              {icon}
            </ListItemIcon>
          </StyledIconButton>
        )}

        <ListItemText
          classes={{
            primary: classnames(
              classes.linkText,
              titleText ? classes.linkSectionTitleText : classes.largeText,
              {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
                [classes.level3MenuText]: type === "level3Menu",
              }
            ),
          }}
          primary={
            capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel
          }
        />
      </ListItemButton>
    );

  return (
    <>
      <ListItemButton
        id={id}
        dense={true}
        disabled={
          (type !== "title" &&
            !!children.filter((el) => el.disabled).length ===
              children.length) ||
          moduleMissing
        }
        onClick={toggleCollapse}
        className={classnames(classes.link, {
          [classes.collapsableSectionTitle]: type === "title",
        })}
        disableRipple
      >
        {type !== "title" ? (
          <Tooltip title={isSidebarOpened ? "" : linkLabel} placement="right">
            <StyledIconButton>
              <ListItemIcon className={classes.linkIcon}>
                {icon ? icon : <InboxIcon />}
              </ListItemIcon>
            </StyledIconButton>
          </Tooltip>
        ) : null}

        <ListItemText
          classes={{
            primary: classnames(
              classes.linkText,
              titleText ? classes.linkSectionTitleText : classes.largeText,
              {
                [classes.collapsableSectionTitleText]: type === "title",
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }
            ),
          }}
          primary={
            capitalise && linkLabel ? linkLabel.toLocaleUpperCase() : linkLabel
          }
        />

        {!(
          (type !== "title" &&
            !!children.filter((el) => el.disabled).length ===
              children.length) ||
          moduleMissing
        ) && (
          <div
            className={classNames(classes.collapseIcon, {
              [classes.collapseInSmall]: !isSidebarOpened,
            })}
          >
            {linkCollapseObject[id] ? <ExpandMoreIcon /> : <NavigateNextIcon />}
          </div>
        )}
      </ListItemButton>
      {children && (
        <Collapse
          in={linkCollapseObject[id]}
          timeout="auto"
          unmountOnExit
          className={classNames({
            [classes.nestedList]: isSidebarOpened,
            [classes.nestedListSmall]: !isSidebarOpened,
          })}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                linkCollapseObject={linkCollapseObject}
                setLinkCollapseObject={setLinkCollapseObject}
                key={childrenLink && childrenLink.id}
                disabled={childrenLink.disabled}
                isSidebarOpened={isSidebarOpened}
                setActiveLink={setActiveLink}
                classes={classes}
                site={site}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  function toggleCollapse(e) {
    e.preventDefault();
    setLinkCollapseObject((s) => ({ ...s, [id]: !s[id] }));
  }
}
