import { useMutation, useQueryClient } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';

/**
 * Custom hook that handles saving device tags.
 * Uses the `useMutation` hook from `react-query` to handle asynchronous
 * operations for saving device tags via the `VrsTagsApi`.
 *
 * The mutation on success will invalidate the 'deviceTags' query to ensure
 * the cache is up to date with the latest data.
 *
 * @returns {object} The mutation object returned by `useMutation`, containing methods
 *                   to execute the mutation and track its status.
 */
const useSaveDeviceTags = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ tagId, deviceTags }: { tagId: number, deviceTags: string[] }) =>
      await VrsTagsApi.saveDeviceTags(tagId, deviceTags),
    { onSuccess: async () => await queryClient.invalidateQueries('deviceTags'), }
  );
};

export default useSaveDeviceTags;
