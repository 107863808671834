import toastr from "toastr";
import { useEffect, useState } from "react";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import config from "../../config";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
} from "@mui/material";

import { usePasswordResetDialogStyle } from "./PasswordResetDialog.css";
import { NewPasswordWithCodeDialog } from "../NewPasswordWithCodeDialog/NewPasswordWithCodeDialog";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useConfigActions } from "../../actions/configActions";
import { useUserActions } from "../../actions/userActions";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

const simpleEmailValidate =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

interface PasswordResetDialogProps {
  open: boolean;
  username;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
  ignoreCancel?: boolean;
}

const PasswordResetDialog = ({
  open,
  username,
  maxWidth,
  onClose,
  ignoreCancel,
}: PasswordResetDialogProps) => {
  const classes = usePasswordResetDialogStyle();
  const [email, setEmail] = useState<string>(username);
  const [requestError, setRequestError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const configActions = useConfigActions();
  const userActions = useUserActions();
  const { _T } = useVrsTranslationState();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError("");
    }
  }, [requestError]);

  const passwordReset = (email) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });

    const user = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: userPool,
    });
    return new Promise((resolve, reject) =>
      user.forgotPassword({
        onSuccess: function (_) {
          setIsLoading(false);
          setShowPassword(true);
          toastr.success(_T("Verification code sent to the email"));
          resolve(true);
        },
        onFailure: async function (err: any) {
          if (err.code === "NotAuthorizedException") {
            await sendWelcomeEMail(email.toLowerCase());
          } else {
            console.log("PasswordReset onFailure=>", err);
            toastr.error(err.message, "Password Reset", { timeOut: 10000 });
          }
          reject(err);
        },
        inputVerificationCode(_) {
          setIsLoading(false);
          setShowPassword(true);
          toastr.success(_T("Verification code sent to the email"));
          resolve(true);
        },
      })
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await passwordReset(email.toLowerCase());
    } catch (e) {
      console.log(e);
    }
  };

  const sendWelcomeEMail = async (email: string) => {
    try {
      configActions.setIsLoading(true);
      await userActions.sendWelcomeEmail({
        email,
      });
      toastr.success(_T("New email sent with a temporary password."));
      configActions.setIsLoading(false);
      handleCancel();
    } catch (error) {
      toastr.error(error.message);
    } finally {
      configActions.setIsLoading(false);
    }
  };

  const onCloseNewPasswordDialog = async (verificationCode, password) => {
    if (verificationCode && password) {
      setIsLoading(true);
      try {
        const userPool = new CognitoUserPool({
          UserPoolId: config.cognito.USER_POOL_ID,
          ClientId: config.cognito.APP_CLIENT_ID,
        });
        const user = new CognitoUser({
          Username: email.toLowerCase(),
          Pool: userPool,
        });

        return new Promise((_1, _2) => {
          user.confirmPassword(verificationCode, password, {
            onSuccess: function () {
              toastr.success("Password reset");
              setIsLoading(false);
              onClose(false);
            },
            onFailure: function (_) {
              toastr.error("Error reset password");
              setIsLoading(false);
              onClose(false);
            },
          });
        });
      } catch (e) {
        alert(e);
        setIsLoading(false);
      }
    } else {
      setShowPassword(false);
    }
  };

  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        classes={{ paper: classes.root }}
        fullWidth={true}
        maxWidth={maxWidth || "sm"}
        disableEscapeKeyDown
        aria-labelledby="password-reset"
        open={open}
      >
        <DialogTitle id="terms-dialog-title">
          <div className={classes.title}>
            <div>
              <PersonAddIcon className={classes.titleIcon} />
            </div>
            <div>
              <div className={classes.titleText}>{_T("Password Reset")}</div>
            </div>
          </div>
          {!ignoreCancel && (
            <IconButton
              aria-label="close"
              className={classNames(classes.closeButton, "terms-close-btn")}
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <FormControl required className={classes.formControl}>
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label={_T("Email")}
              onChange={(event) => setEmail(event.target.value)}
              id="email"
              value={email}
              error={false}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button
            autoFocus
            onClick={handleSubmit}
            className="reset-ok-btn"
            variant="contained"
            disabled={!simpleEmailValidate.test(email)}
            color="primary"
          >
            {_T("Submit")}
          </Button>
        </DialogActions>
      </DialogWithBackdropClick>
      {showPassword && (
        <NewPasswordWithCodeDialog
          loading={isLoading}
          open={showPassword}
          onClose={onCloseNewPasswordDialog}
        />
      )}
    </>
  );
};

export { PasswordResetDialog };
