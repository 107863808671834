import { SimpleMessage } from "../SimpleMessage/SimpleMessage";
import { useNotFoundStyle } from "./NotFound.css";
import LinkToUrl from "../Basic/LinkToUrl/LinkToUrl";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface NotFoundProps {
  goBackLink?: string;
}
function NotFound({ goBackLink }: NotFoundProps) {
  const classes = useNotFoundStyle();
  const { _T } = useVrsTranslationState();
  return (
    <div>
      <SimpleMessage
        title="404 - Not found"
        subtitle="The page you are looking for cannot be found."
        extraContent={
          goBackLink && (
            <div className={classes.buttonContainer}>
              <LinkToUrl to={goBackLink}>{_T("GoBack")}</LinkToUrl>
            </div>
          )
        }
      />
    </div>
  );
}

NotFound.displayName = "NotFound";

export { NotFound };
