import { useMemo } from "react";
import ResRequestPrinterLogsApi from "../api/prodRequestPrinterLogsApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";

import { REQUEST_PRINTER_LOGS_SUCCESS } from "./actionTypes";

export function requestPrinterLogsSuccess(payload) {
  return { type: REQUEST_PRINTER_LOGS_SUCCESS, payload };
}

function loadAppSyncRequestPrinterLogs(
  resName: string,
  printerId: string,
  logTypes: string,
) {
  return (_) => {
    return ResRequestPrinterLogsApi.getAppSyncRequestPrinterLogs(
      resName,
      printerId,
      logTypes,
    )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

function sendAppSyncLogConfig (
  base64String: any,
  resName: string,
  fileName: string,
  target: string,
  ){
  return (_) => {
    return ResRequestPrinterLogsApi.sendAppSyncLogConfig(base64String, 
      fileName, target, resName)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        throw error;
      });
  };
}

export const useResRequestPrinterLogsActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      loadAppSyncRequestPrinterLogs: (
        resName: string,
        printerId: string,
        logTypes: string
      ) => loadAppSyncRequestPrinterLogs(resName, printerId, logTypes)(dispatch),
      sendAppSyncLogConfig: (
        base64String: any,
        fileName: string,
        target: string,
        resName: string
        ) => sendAppSyncLogConfig(base64String, resName, fileName, target)(dispatch),
    }),
    [dispatch]
  );
};
