import toastr from "toastr";
import { useCallback, useEffect, useState } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { cognitoFields, validation } from "vccm-common";
import config from "../../config";
import countries from "../../utilities/countries";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { InfoDialog } from "../InfoDialog/InfoDialog";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
} from "@mui/material";

import { NewPasswordDialog } from "../NewPasswordDialog/NewPasswordDialog";
import { useSignUpStyle } from "./Signup.css";
import { SimpleDropdown } from "../Basic/SimpleDropdown/SimpleDropdown";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

const countriesArray = Object.entries(countries["en-US"].detail).map(
  ([k, v]) => {
    const lowerKey = k.toLowerCase();
    return {
      flag: lowerKey,
      key: lowerKey,
      label: lowerKey,
      text: v,
      value: lowerKey,
    };
  }
);

interface SignupProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
}

const Signup = ({ open, maxWidth, onClose }: SignupProps) => {
  const { _T } = useVrsTranslationState();
  const classes = useSignUpStyle();

  const [companyName, setCompanyName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [requestError, setRequestError] = useState<string>("");
  const [first, setFirst] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [last, setLast] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [, setSignedUpUser] = useState<any>();
  const [siteId, setSiteId] = useState<string>();
  const [results, setResults] = useState<string>("");
  const [status, setStatus] = useState<any>("");
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const validateSignup = useCallback(() => {
    let errs: any = {};
    const user = {
      company: companyName,
      country,
      email,
      firstName: first,
      lastName: last,
    };
    const userValidate = validation.schemas.user.validate(user, {
      abortEarly: false,
    });

    errs = userValidate.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, "_") : "";
      return agg;
    }, {});

    setErrors({ ...errs });
    return Object.keys(user).every((key) => errs[key] == null);
  }, [setErrors, companyName, country, email, first, last]);

  const handleOnChange = useCallback(
    (name, value) => {
      switch (name) {
        case "companyName":
          setCompanyName(value);
          break;
        case "country":
          setCountry(value);
          break;
        case "email":
          setEmail(value);
          break;
        case "first":
          setFirst(value);
          break;
        case "last":
          setLast(value);
          break;
        case "siteId":
          setSiteId(value);
          break;
        default:
          break;
      }
    },
    [setCompanyName, setCountry, setEmail, setFirst, setLast, setSiteId]
  );

  useEffect(() => {
    if (showErrors) {
      validateSignup();
    }
  }, [
    showErrors,
    first,
    last,
    email,
    country,
    companyName,
    siteId,
    validateSignup,
  ]);

  const signup = useCallback((username, pwd, attributeList) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });

    return new Promise((resolve, reject) =>
      userPool.signUp(username, pwd, attributeList || [], [], (err, result) => {
        if (!result || err) {
          reject(err);
          return;
        }
        resolve(result.user);
      })
    );
  }, []);

  // toast on error
  useEffect(() => {
    if (requestError) {
      toastr.error(requestError);
      setRequestError("");
    }
  }, [requestError]);

  const onCloseNewPasswordDialog = async (password) => {
    if (password) {
      setIsLoading(true);
      try {
        const attributeList = [
          {
            Name: "given_name",
            Value: first,
          },
          {
            Name: "family_name",
            Value: last,
          },
          {
            Name: cognitoFields.COMPANY_NAME,
            Value: companyName,
          },
          {
            Name: cognitoFields.COUNTRY,
            Value: country,
          },
        ];
        const newUser = await signup(
          email.toLowerCase(),
          password,
          attributeList
        );
        setSignedUpUser(newUser);
        setShowPassword(false);
        setStatus("success");
        setResults(_T("AccountCreated"));
        setShowInfo(true);
      } catch (e) {
        setStatus("error");
        setResults(e.message);

        setIsLoading(false);
        setShowPassword(false);
        setShowInfo(true);
      }
    } else {
      setShowPassword(false);
    }
  };

  const handleSubmitForm = async () => {
    setShowErrors(true);
    if (validateSignup()) {
      setShowPassword(true);
    }
  };

  const closeInfoDialog = () => {
    setShowInfo(false);
    setShowPassword(false);
    handleOk();
  };

  return (
    <>
      {!showPassword && !showInfo && (
        <DialogWithBackdropClick
          disableBackdropClick
          classes={{ paper: classes.root }}
          fullWidth={true}
          maxWidth={maxWidth || "sm"}
          disableEscapeKeyDown
          aria-labelledby="sign-up"
          open={open}
        >
          <DialogTitle id="terms-dialog-title">
            <div className={classes.title}>
              <div>
                <PersonAddIcon className={classes.titleIcon} />
              </div>
              <div>
                <div className={classes.titleText}>{_T("Create Account")}</div>
                <div>{_T("Design")}</div>
              </div>
            </div>
            <IconButton
              aria-label="close"
              className={classNames(classes.closeButton, "terms-close-btn")}
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="first"
                label={_T("First_Name")}
                onChange={(event) =>
                  handleOnChange("first", event.target.value)
                }
                id="first"
                value={first}
                error={errors && !!errors.firstName}
                helperText={errors && errors.firstName && _T(errors.firstName)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="last"
                label={_T("Last_Name")}
                onChange={(event) => handleOnChange("last", event.target.value)}
                id="last"
                value={last}
                error={errors && !!errors.lastName}
                helperText={errors && errors.lastName && _T(errors.lastName)}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="companyName"
                label={_T("Company_Name")}
                onChange={(event) =>
                  handleOnChange("companyName", event.target.value)
                }
                id="companyName"
                value={companyName}
                error={errors && !!errors.company}
                helperText={errors && errors.company && _T(errors.company)}
              />
            </FormControl>

            <FormControl
              variant="outlined"
              required
              className={classes.formControlInner}
            >
              <SimpleDropdown
                id="country"
                name="country"
                required={true}
                label={_T("Country")}
                canRemoveAll={false}
                placeholder={_T("Select_Ellipsis")}
                onChange={(value) => handleOnChange("country", value)}
                options={countriesArray}
                value={country}
                error={errors && !!errors.country}
                helperText={errors && errors.country && _T(errors.country)}
              />
            </FormControl>

            <FormControl required className={classes.formControl}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label={_T("Email")}
                onChange={(event) =>
                  handleOnChange("email", event.target.value)
                }
                id="email"
                value={email}
                error={errors && !!errors.email}
                helperText={errors && errors.email && _T(errors.email)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Button
              autoFocus
              onClick={handleSubmitForm}
              className="terms-ok-btn"
              variant="contained"
              color="primary"
            >
              {_T("EnterNewPassword")}
            </Button>
          </DialogActions>
        </DialogWithBackdropClick>
      )}
      {showPassword && !showInfo && (
        <NewPasswordDialog
          loading={isLoading}
          open={showPassword}
          onClose={onCloseNewPasswordDialog}
        />
      )}
      {showInfo && (
        <InfoDialog
          title={_T(
            status === "success" ? "VideojetConnectUserCreated" : "SignupError"
          )}
          open={showInfo}
          onClose={closeInfoDialog}
          content={
            <div className={classes.infoContainer}>
              <div className={classes.successIcon}>
                {status === "success" && <CheckIcon fontSize="large" />}
              </div>
              <div className={classes.errorIcon}>
                {status === "error" && <ErrorIcon fontSize="large" />}
              </div>
              <div>{results}</div>
            </div>
          }
        />
      )}
    </>
  );
};

export default Signup;
