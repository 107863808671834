import { useEffect, useState, useCallback, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { cognitoFields } from "vccm-common";
import queryString from "query-string";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import authLib, {
    checkIsAdmin,
    isVrsSuperUser,
    isPureDesignUser,
} from "./libs/authLib";
import { setAbilitiesForSite } from "./actions/siteActions";

// components
import Layout from "./components/Layout/Layout";

// pages
import Login from "./components/Login/Login";

// context
import {
    restoreUserFromSession,
    useAppGlobalDispatch,
    useAppUserState,
    useAppSiteState,
    useLogOut,
    useConfigState,
    useVrsTranslationState,
} from "./context/AppContext/AppContext";
import { Logger } from "./utilities/Logger/Logger";
import UserHelper from "./helpers/UserHelper";
import {
    APP_INITIALISED,
    APP_INITIALISED_WITH_VRS_OPERATIONS,
} from "./actions/actionTypes";
import { useSiteActions } from "./actions/siteActions";
import { useUserActions } from "./actions/userActions";
import { useConfigActions } from "./actions/configActions";
import Utils from "./utilities/utils";
import { vrsOperationsSite } from "./constants/global";
import { InfoDialog } from "./components/InfoDialog/InfoDialog";
import { useAppStyles } from "./App.css";
import { useCompanyActions } from "./actions/companyActions";
import { IntlProvider } from "react-intl";
import {
    hasIdentities,
    checkIsCSEUser,
    checkIsServiceUser,
    checkIsVJInternalUser,
    getAuthClaims,
} from "./libs/authLib-aws";
import { bool } from "aws-sdk/clients/signer";
import { TrackerProvider } from "./context/TrackerContext/TrackerContext";
import ErrorBoundary from "./vrspages/servicedashboard/assignment/ErrorBoundary";

const IntlProviderComp: any = IntlProvider;

const queryClient = new QueryClient();

const getTargetUrlAsSearch = () => {
    const pathname = window.location.pathname;
    const search = window.location.search;
    let searchWithReturnUrl = "";
    if (pathname !== "login" && search.indexOf("targetUrl=") === -1) {
        if (!search) {
            searchWithReturnUrl = `?targetUrl=${pathname}`;
        } else {
            searchWithReturnUrl = `${search}&targetUrl=${pathname}`;
        }
    } else {
        searchWithReturnUrl = search;
    }

    return searchWithReturnUrl;
};

export default function App() {
    const classes = useAppStyles();
    const { isAuthenticated } = useAppUserState();
    const { selectedSiteId } = useAppSiteState();
    const dispatch = useAppGlobalDispatch();

    const siteActions = useSiteActions();
    const companyActions = useCompanyActions();
    const userActions = useUserActions();
    const configActions = useConfigActions();

    const configState = useConfigState();
    const { loadVrsTranslations, loadVrsUserProfile, _T } =
        useVrsTranslationState();

    const logOut = useLogOut();
    const { cognitoUserLoaded } = useAppUserState();

    const [retrievingError] = useState(false);
    const [keepAliveTimer, setKeepAliveTimer] = useState<ReturnType<
        typeof setInterval
    > | null>(null);
    const [keepAliveUpdate] = useState(0);

    const [mounted, setMounted] = useState<boolean>(false);

    const authKeepAlive = useCallback(() => {
        (async () => {
            Logger.of("App.authKeepAlive").trace(
                "auth keep alive",
                new Date().toISOString(),
                { isAuthenticated }
            );
            if (isAuthenticated && !(await authLib.authUser())) {
                Logger.of("App.authKeepAlive").warn(
                    "User became unauthenticated"
                );
                await logOut(dispatch);
            }
        })();
    }, [isAuthenticated, logOut, dispatch]);

    const setCompanyAndPlants = useCallback(
        (companyId, plantId) => {
            companyActions.setVrsCompanyAndSiteId(companyId, plantId);
        },
        [companyActions]
    );

    const checkQueryStringForCompanyAndSiteId = useCallback(async () => {
        let activeCompanyId;
        let activeSiteId;

        const { companyId, siteId } =
            Utils.getTrackingValuesFromQueryParameters(window.location.search);

        if (companyId) {
            // Check company and site Access

            if (Utils.IsIdGuid(companyId) || companyId === "0") {
                if (
                    siteId === "VrsInternal" ||
                    siteId === "0" ||
                    siteId === ""
                ) {
                    setCompanyAndPlants("0", "0");
                    activeCompanyId = 0;
                    activeSiteId = 0;
                } else {
                    companyActions.setVrsCompanyAndSiteId("0", siteId);
                    activeCompanyId = 0;
                    activeSiteId = siteId;
                }
            } else {
                if (
                    siteId === "VrsInternal" ||
                    siteId === "0" ||
                    siteId === "" ||
                    Utils.IsIdGuid(siteId)
                ) {
                    const company = await companyActions.getCompanyAppSync(
                        companyId
                    );
                    configActions.setHasCompanyAccess(company !== null);
                    if (company) {
                        setCompanyAndPlants(companyId, "0");
                    } else {
                        setCompanyAndPlants("0", "0");
                    }
                    activeCompanyId = companyId;
                    activeSiteId = 0;
                } else {
                    const company = await companyActions.getCompanyAppSync(
                        companyId
                    );
                    const site = await companyActions.getPlantAppSync(siteId);
                    configActions.setHasCompanyAccess(company !== null);
                    configActions.setHasSiteAccess(site !== null);

                    if (company && site) {
                        setCompanyAndPlants(companyId, siteId);
                        activeSiteId = siteId;
                    } else if (company && !site) {
                        setCompanyAndPlants(companyId, "0");
                        activeSiteId = 0;
                    } else {
                        setCompanyAndPlants("0", "0");
                        activeSiteId = 0;
                    }
                    activeCompanyId = companyId;
                }
            }
        } else {
            activeCompanyId = Utils.getCompanyCookie() || "0";
            activeSiteId = Utils.getPlantCookie() || "0";
            if (!Utils.IsIdGuid(activeSiteId)) {
                setCompanyAndPlants(activeCompanyId, activeSiteId);
            }
        }

        configActions.setQueryStringChecked(true);

        return {
            activeCompanyId,
            activeSiteId,
        };
    }, [companyActions, configActions, setCompanyAndPlants]);

    // initialization
    useEffect(() => {
        (async () => {
            try {
                if (await authLib.authUser()) {
                    console.log("restoring user....");
                    if (
                        window.location.pathname.includes("/welcome/vrs/user")
                    ) {
                        Logger.of("App").trace("trying to migrate user");
                        await authLib.signOutUser();
                    }
                    const currentUser = await authLib.getCurrentUser();

                    const { activeSiteId } =
                        await checkQueryStringForCompanyAndSiteId();

                    if (currentUser) {
                        await authLib.userRefreshSession(currentUser);
                        const userProfile = {
                            email:
                                currentUser["email"] ||
                                UserHelper.getEmailAddressBySession(
                                    currentUser
                                ),
                            selectedSiteId:
                                activeSiteId === "0" ? "" : activeSiteId,
                        };

                        restoreUserFromSession(dispatch, userProfile);
                        Logger.of("App.Initialise").trace(
                            "Current user is =>",
                            currentUser
                        );
                    }
                }

                userActions.setCognitoUserLoaded(true);
            } catch (e) {
                Logger.of("App.Initialise").warn("error", e);
            }
        })();
        return () => {
            // cleanup
            if (keepAliveTimer != null) {
                clearInterval(keepAliveTimer);
                setKeepAliveTimer(null);
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        authKeepAlive();
    }, [keepAliveUpdate, authKeepAlive]);

    const createSite = useCallback(
        (currentUser) => {
            Logger.of("App.createSite").info("Creating site");
            siteActions
                .createSite({
                    id: currentUser.username,
                    title: "My Site",
                    companyId: currentUser.username,
                    subscriptionStatus: "trial",
                    modules: ["Design"],
                })
                .then(async (site) => {
                    if (site) {
                        Logger.of("App.createSite").info("Site created:", site);
                        Logger.of("App.createSite").info(
                            "Refreshing user session:",
                            currentUser
                        );
                        // refreshing the token will get any updates that may have happened on Cognito fields due to new site access
                        const refreshUser = await authLib.userRefreshSession(
                            currentUser
                        );
                        const sites = await siteActions.loadSites();
                        Logger.of("App.createSite").info("current:", {
                            currentUser,
                            refreshUser,
                        });
                        await siteActions.setSite(site.id, currentUser);
                        const targetSite = sites.find((s) => s.id === site.id);
                        if (targetSite) {
                            companyActions.setVrsCompanyId(
                                site.companyId ? targetSite.companyId : ""
                            );
                        }

                        const vrsAbilities = currentUser
                            ? await Utils.checkVrsAbilities(currentUser)
                            : {};

                        dispatch({
                            type: APP_INITIALISED,
                            payload: {
                                selectedSiteId: site.id,
                                currentUser,
                                isAdmin:
                                    checkIsAdmin(currentUser) ||
                                    (await isVrsSuperUser(currentUser)),
                                isPureDesignUser: await isPureDesignUser(
                                    currentUser
                                ),
                                isIdentityUser: hasIdentities(currentUser),
                                isCSEUser: await checkIsCSEUser(currentUser),
                                isServiceUser: await checkIsServiceUser(
                                    currentUser
                                ),
                                isVJInternalUser: await checkIsVJInternalUser(
                                    currentUser
                                ),
                                authClaims: await getAuthClaims(currentUser),
                                vrsAbilities,
                            },
                        });
                    } else {
                        Logger.of("App.createSite").warn(
                            "Could not create site"
                        );
                    }
                })
                .catch(async (error) => {
                    if (
                        error &&
                        error.message &&
                        error.message.includes("Already exists")
                    ) {
                        const sites = await siteActions.loadSites();
                        // we use self signed user id as newly created site
                        const siteId = currentUser.username;
                        await siteActions.setSite(siteId, currentUser);
                        const targetSite = sites.find(
                            (site) => site.id === siteId
                        );
                        if (targetSite) {
                            companyActions.setVrsCompanyId(
                                targetSite.companyId ? targetSite.companyId : ""
                            );
                        }

                        const vrsAbilities = currentUser
                            ? await Utils.checkVrsAbilities(currentUser)
                            : {};

                        dispatch({
                            type: APP_INITIALISED,
                            payload: {
                                selectedSiteId: siteId,
                                currentUser,
                                isAdmin:
                                    checkIsAdmin(currentUser) ||
                                    (await isVrsSuperUser(currentUser)),
                                isPureDesignUser: await isPureDesignUser(
                                    currentUser
                                ),
                                isIdentityUser: hasIdentities(currentUser),
                                isCSEUser: await checkIsCSEUser(currentUser),
                                isServiceUser: await checkIsServiceUser(
                                    currentUser
                                ),
                                isVJInternalUser: await checkIsVJInternalUser(
                                    currentUser
                                ),
                                authClaims: await getAuthClaims(currentUser),
                                vrsAbilities,
                            },
                        });
                    } else {
                        Logger.of("App.createSite").warn(
                            "Could not create site",
                            error
                        );
                    }
                });
        },
        [siteActions, companyActions, dispatch]
    );

    const initiateSiteLoading = useCallback(() => {
        (async () => {
            const currentUser: any = await authLib.getCurrentUser();
            let userSite: any = null;
            let sites: Array<any> = [];
            try {
                await checkQueryStringForCompanyAndSiteId();
                if (selectedSiteId && selectedSiteId !== "0") {
                    userSite = await siteActions.loadSingleSite(selectedSiteId);
                    const vrsSite = await companyActions.getPlantAppSync(selectedSiteId);
                    if (userSite) {
                        configActions.setAppDataInitialization(
                            "firstSiteData",
                            (state) => ({
                                ...state,
                                loaded: true,
                                isExternal: vrsSite?.isExternal,
                            })
                        );
                        companyActions.setVrsCompanyId(userSite.companyId);
                        if (selectedSiteId !== "0") {
                            companyActions.setVrsCompanySiteId(selectedSiteId);
                        }
                        sites = [userSite];
                    }
                }
            } catch (err) {
                Logger.of("App.initiateSiteLoading").warn(
                    "User does not have a site",
                    err
                );
            }

            const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
            Logger.of("App.initiateSiteLoading").trace(
                "decoded id token",
                tokenDecoded
            );
            if (tokenDecoded.authClaims) {
                console.log(
                    JSON.stringify(JSON.parse(tokenDecoded.authClaims), null, 2)
                );
            }

            const isadmin =
                checkIsAdmin(currentUser) ||
                (await isVrsSuperUser(currentUser));
            const vrsAbilities = currentUser
                ? await Utils.checkVrsAbilities(currentUser)
                : {};

            const vrsInternalAccessAbilities = currentUser
                ? await Utils.checkVrsInternalAccess(currentUser)
                : [];

            let siteId = "";

            if (
                isadmin ||
                vrsInternalAccessAbilities.length > 0 ||
                Object.keys(vrsAbilities).length > 0
            ) {
                const localeStr = await loadVrsUserProfile(false);
                const activeLocal =
                    localeStr || navigator.language.split(/[-_]/)[0];
                configActions.setLocale(activeLocal);
                await loadVrsTranslations(activeLocal, false);
            }

            if (!selectedSiteId && (await isPureDesignUser(currentUser))) {
                try {
                    const userSite = await siteActions.loadSingleSite(
                        currentUser.username
                    );

                    if (userSite) {
                        configActions.setAppDataInitialization(
                            "firstSiteData",
                            (state) => ({
                                ...state,
                                loaded: true,
                            })
                        );
                        sites = [userSite];
                        siteId = sites[0].id;
                    }
                } catch (err) {
                    Logger.of("App.initiateSiteLoading").warn(
                        "User does not have a site",
                        err
                    );
                }
            }

            if (sites !== undefined && sites.length > 0) {
                if (currentUser && siteId) {
                    Logger.of("App.initiateSiteLoading").trace(
                        "Current user is =>",
                        currentUser
                    );

                    await siteActions.setSite(siteId, currentUser);
                }

                if (vrsInternalAccessAbilities.length > 0) {
                    dispatch({
                        type: APP_INITIALISED_WITH_VRS_OPERATIONS,
                        payload: {
                            selectedSiteId: siteId,
                            currentUser,
                            isAdmin:
                                checkIsAdmin(currentUser) ||
                                (await isVrsSuperUser(currentUser)),
                            isPureDesignUser: await isPureDesignUser(
                                currentUser
                            ),
                            vrsInternalAccessAbilities,
                            vrsAbilities,
                            isIdentityUser: hasIdentities(currentUser),
                            isCSEUser: await checkIsCSEUser(currentUser),
                            isServiceUser: await checkIsServiceUser(
                                currentUser
                            ),
                            isVJInternalUser: await checkIsVJInternalUser(
                                currentUser
                            ),
                            authClaims: await getAuthClaims(currentUser),
                        },
                    });
                } else {
                    dispatch({
                        type: APP_INITIALISED,
                        payload: {
                            selectedSiteId: siteId,
                            currentUser,
                            isAdmin:
                                checkIsAdmin(currentUser) ||
                                (await isVrsSuperUser(currentUser)),
                            isPureDesignUser: await isPureDesignUser(
                                currentUser
                            ),
                            vrsAbilities,
                            isIdentityUser: hasIdentities(currentUser),
                            isCSEUser: await checkIsCSEUser(currentUser),
                            isServiceUser: await checkIsServiceUser(
                                currentUser
                            ),
                            isVJInternalUser: await checkIsVJInternalUser(
                                currentUser
                            ),
                            authClaims: await getAuthClaims(currentUser),
                        },
                    });
                }
            } else {
                // Check VRS Operations Access
                const vrsInternalAccessAbilities = currentUser
                    ? await Utils.checkVrsInternalAccess(currentUser)
                    : [];
                if (vrsInternalAccessAbilities.length > 0) {
                    const vrsAbilities = currentUser
                        ? await Utils.checkVrsAbilities(currentUser)
                        : {};

                    if (currentUser) {
                        try {
                            Logger.of("Setting vrsInternal site").info(
                                "Token decoded during this feature",
                                tokenDecoded
                            );
                            setAbilitiesForSite(
                                "VrsInternal",
                                tokenDecoded,
                                configActions
                            );
                            dispatch({
                                type: APP_INITIALISED_WITH_VRS_OPERATIONS,
                                payload: {
                                    selectedSiteId: vrsOperationsSite.id,
                                    siteInitialised: true,
                                    vrsInternalAccessAbilities,
                                    vrsAbilities,
                                    currentUser,
                                    isAdmin:
                                        checkIsAdmin(currentUser) ||
                                        (await isVrsSuperUser(currentUser)),
                                    isPureDesignUser: await isPureDesignUser(
                                        currentUser
                                    ),
                                    isIdentityUser: hasIdentities(currentUser),
                                    isCSEUser: await checkIsCSEUser(
                                        currentUser
                                    ),
                                    isServiceUser: await checkIsServiceUser(
                                        currentUser
                                    ),
                                    isVJInternalUser:
                                        await checkIsVJInternalUser(
                                            currentUser
                                        ),
                                    authClaims: await getAuthClaims(
                                        currentUser
                                    ),
                                },
                            });
                        } catch (err) {
                            // Prompt the user to reauthenticate by hand...?
                            Logger.of("Setting vrsInternal").warn(
                                "Error getting abilities",
                                err
                            );
                            return null;
                        }
                    } else {
                        dispatch({
                            type: APP_INITIALISED_WITH_VRS_OPERATIONS,
                            payload: {
                                selectedSiteId: vrsOperationsSite.id,
                                siteInitialised: true,
                                vrsInternalAccessAbilities,
                                vrsAbilities,
                                currentUser,
                                isAdmin:
                                    checkIsAdmin(currentUser) ||
                                    (await isVrsSuperUser(currentUser)),
                                isPureDesignUser: await isPureDesignUser(
                                    currentUser
                                ),
                                isIdentityUser: hasIdentities(currentUser),
                                isCSEUser: await checkIsCSEUser(currentUser),
                                isServiceUser: await checkIsServiceUser(
                                    currentUser
                                ),
                                isVJInternalUser: await checkIsVJInternalUser(
                                    currentUser
                                ),
                                authClaims: await getAuthClaims(currentUser),
                            },
                        });
                    }
                } else if (
                    selectedSiteId !== "0" &&
                    tokenDecoded &&
                    !tokenDecoded[cognitoFields.COMPANY_ID_LIST]
                ) {
                    // create a site since we don't have any.
                    // This means we must be a self sign-up user
                    await createSite(currentUser);
                } else {
                    let selectedSiteId = "0";
                    if (
                        !checkIsAdmin(currentUser) &&
                        !(await isVrsSuperUser(currentUser))
                    ) {
                        const sites = await siteActions.loadSites();
                        const site = sites.length > 0 ? sites[0] : null;
                        if (site !== null) {
                            const refreshUser =
                                await authLib.userRefreshSession(currentUser);
                            Logger.of("App.createSite").info("current:", {
                                currentUser,
                                refreshUser,
                            });
                            await siteActions.setSite(site.id, currentUser);
                            const targetSite = sites.find(
                                (s) => s.id === site.id
                            );
                            if (targetSite) {
                                companyActions.setVrsCompanyId(
                                    targetSite.companyId
                                        ? targetSite.companyId
                                        : ""
                                );

                                Utils.setCompanyCookie(
                                    targetSite.companyId
                                        ? targetSite.companyId
                                        : "0"
                                );
                            }

                            selectedSiteId = site.id;
                        }
                    }

                    const vrsAbilities = currentUser
                        ? await Utils.checkVrsAbilities(currentUser)
                        : {};
                    dispatch({
                        type: APP_INITIALISED,
                        payload: {
                            selectedSiteId,
                            currentUser,
                            isAdmin:
                                checkIsAdmin(currentUser) ||
                                (await isVrsSuperUser(currentUser)),
                            isPureDesignUser: await isPureDesignUser(
                                currentUser
                            ),
                            isIdentityUser: hasIdentities(currentUser),
                            isCSEUser: await checkIsCSEUser(currentUser),
                            isServiceUser: await checkIsServiceUser(
                                currentUser
                            ),
                            isVJInternalUser: await checkIsVJInternalUser(
                                currentUser
                            ),
                            authClaims: await getAuthClaims(currentUser),
                            vrsAbilities,
                        },
                    });
                }
            }
        })();
    }, [
        selectedSiteId,
        createSite,
        companyActions,
        siteActions,
        configActions,
        dispatch,
        loadVrsTranslations,
        loadVrsUserProfile,
        checkQueryStringForCompanyAndSiteId,
    ]);

    useEffect(() => {
        (async () => {
            if (
                isAuthenticated &&
                !configState.appDataInitialization.firstSiteData.initiated
            ) {
                configActions.setAppDataInitialization(
                    "firstSiteData",
                    (state) => ({
                        ...state,
                        initiated: true,
                    })
                );
                userActions.setCognitoUserLoaded(true);
                initiateSiteLoading();
            }
        })();
    }, [
        isAuthenticated,
        keepAliveTimer,
        userActions,
        initiateSiteLoading,
        configActions,
        configState.appDataInitialization.firstSiteData.initiated,
    ]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            console.log("Application mounted");
        }

        return () => {
            if (mounted) {
                console.log("Application unmounted");
            }
        };
    }, [mounted]);

    const { locale } = configState;

    const activeMessages = useMemo(() => {}, []);

    const q: any = queryString.parse(window.location.search);
    const targetUrlAsSearch = getTargetUrlAsSearch();

    return (
        <QueryClientProvider client={queryClient}>
            <IntlProviderComp locale={locale} messages={activeMessages}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Navigate to="/home" />} />
                        {[
                            "/login/:token",
                            "/login",
                            `/login${targetUrlAsSearch}`,
                        ].map((path, index) => {
                            return (
                                <Route
                                    path={path}
                                    element={
                                        <PublicRoute
                                            isAuthenticated={isAuthenticated}
                                            pathname={q.targetUrl || "/home"}
                                        >
                                            <Login />
                                        </PublicRoute>
                                    }
                                    key={index}
                                />
                            );
                        })}

                        <Route
                            path="*"
                            element={
                                isAuthenticated ? (
                                    <TrackerProvider>
                                        <ErrorBoundary
                                            fallback={
                                                <p className={classes.error}>
                                                    A catastrophic error
                                                    occured. Refresh your
                                                    browser.
                                                </p>
                                            }
                                        >
                                            <Layout />
                                        </ErrorBoundary>
                                    </TrackerProvider>
                                ) : cognitoUserLoaded ? (
                                    <Navigate
                                        to={{
                                            pathname: "/login",
                                            search: targetUrlAsSearch,
                                        }}
                                    />
                                ) : null
                            }
                        />
                    </Routes>
                    {retrievingError && (
                        <InfoDialog
                            title={_T("Warning")}
                            open={retrievingError}
                            onClose={() => {
                                window.location.reload();
                            }}
                            content={
                                <div className={classes.infoContainer}>
                                    <div>{_T("Retrieving Issue")}</div>
                                </div>
                            }
                        />
                    )}
                </BrowserRouter>
            </IntlProviderComp>
        </QueryClientProvider>
    );
}

interface PublicRouteProps {
    isAuthenticated: bool;
    pathname: string;
    children: JSX.Element;
}

function PublicRoute({
    isAuthenticated,
    pathname,
    children,
}: PublicRouteProps) {
    return isAuthenticated ? (
        <Navigate
            to={{
                pathname,
            }}
        />
    ) : (
        children
    );
}
