import { lazy, ReactElement, Suspense, useEffect, useState } from 'react';
import toastr from 'toastr';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// styles
import useStyles from './Layout.css';

// components
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { Footer } from '../Footer/Footer';

import {
  useAppSelectedSite,
  useAppGlobalState,
  useConfigState,
  useAppUserState,
  useAppSiteState,
} from '../../context/AppContext/AppContext';
import { getLinkAccessInfo } from '../../RouteAccess';
import { CheckAuthorisedRoute } from '../CheckAuthorisedRoute/CheckAuthorisedRoute';
import { useConfigActions } from '../../actions/configActions';
import { useSiteActions } from '../../actions/siteActions';
import { getCurrentUser } from '../../libs/authLib';
import { useUserActions } from '../../actions/userActions';
import { useCompanyActions } from '../../actions/companyActions';
import Utils from '../../utilities/utils';
import { NotFound } from '../NotFound/NotFound';
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator';
import { NotAuthorised } from '../NotAuthorised/NotAuthorised';
import LogManagement from '../../vrspages/logmanagement/LogManagement';
import { useNavigateToLink } from '../../actions/NavigateActions';
import { useVrsUserActions } from '../../actions/vrsUserActions';
import { extractErrors } from '../../libs/getDynoErrors';
import SiteTagsPage from '../../vrspages/sitetags/SiteTagsPage/SiteTagsPage';

const SitePage = lazy(() => import('../../pages/sites/SitePage/SitePage'));
const DisplayModeSettingsSetupPage = lazy(
  () => import('../../pages/displaymode/DisplayModeSettingsSetupPage/DisplayModeSettingsSetupPage')
);
const InspectionSettingsPage = lazy(
  () => import('../../pages/inspections/InspectionSettingsPage/InspectionSettingsPage')
);
const InspectionSetupPage = lazy(
  () => import('../../pages/inspections/InspectionSetupPage/InspectionSetupPage/InspectionSetupPage')
);
const DeviceRoleSetupPage = lazy(() => import('../../pages/devicerole/DeviceRoleSetupPage/DeviceRoleSetupPage'));
const ReportsDashPage = lazy(() => import('../../pages/reports/ReportsDashPage/ReportsDashPage'));
const AdminPage = lazy(() => import('../../pages/admin/AdminPage/AdminPage'));
const OperationsPage = lazy(() => import('../../pages/admin/OperationsPage/OperationsPage'));
const RoleSetupMainPage = lazy(() => import('../../pages/role/RoleSetupMainPage/RoleSetupMainPage'));
const CompanyUserList = lazy(() => import('../../pages/users/CompanyUserList/CompanyUserList'));
const VrsCompanyUserList = lazy(() => import('../../vrspages/vrsusers/VrsCompanyUserList/VrsCompanyUserList'));

const VrsCompanyList = lazy(() => import('../../vrspages/vrscompany/vrsCompanyList/VrsCompanyList'));

const VrsPrinterList = lazy(() => import('../../vrspages/vrsprinter/vrsPrinterList/VrsPrinterList'));

const FavoritesManagement = lazy(
  () => import('../../vrspages/favoritesmanagement/FavoritesManagement/FavoritesManagement')
);

const ResManagement = lazy(() => import('../../vrspages/resmanagement/ResManagement/ResManagement'));

const ResRegister = lazy(() => import('../../vrspages/resregistration/Register/Register'));

const NotificationLogPage = lazy(
  () => import('../../vrspages/notificationlog/NotificationLogPage/NotificationLogPage')
);

const WorkSchedulePage = lazy(() => import('../../vrspages/profileandworkschedule/WorkSchedulePage/WorkSchedulePage'));

const AlertManagement = lazy(() => import('../../vrspages/alertmanagement/AlertManagement/AlertManagement'));

const ServiceDashboard = lazy(
  () => import('../../vrspages/servicedashboard/ServiceDashboardPage/ServiceDashboardPage')
);

const VrsSiteList = lazy(() => import('../../vrspages/vrssite/vrsSiteList/VrsSiteList'));

const DowntimeSetupMainPage = lazy(() => import('../../pages/downtime/DowntimeSetupMainPage/DowntimeSetupMainPage'));
const SiteForm = lazy(() => import('../../pages/sites/SiteForm/SiteForm'));
const DowntimeCategoryManagementPage = lazy(
  () => import('../../pages/downtime/category/DowntimeCategoryManagementPage/DowntimeCategoryManagementPage')
);
const DowntimeSubCategoryManagementPage = lazy(
  () => import('../../pages/downtime/subcategory/DowntimeSubCategoryManagementPage/DowntimeSubCategoryManagementPage')
);

const DeviceInformation = lazy(() => import('../../vrspages/printerInfo/DeviceInformation'));
const SiteMap = lazy(() => import('../../vrspages/sitemap/SiteMap'));
const DeviceManagementPage = lazy(
  () => import('../../vrspages/devicemanagement/DeviceManagementPage/DeviceManagementPage')
);

const SummaryPage = lazy(() => import('../../vrspages/summary/SummaryPage'));

const AvailabilityPage = lazy(
  () => import('../../vrspages/availabilitymanagement/AvailabilityManagementPage/AvailabilityManagementPage')
);

const ProductionPage = lazy(
  () => import('../../vrspages/productionmanagement/ProductionManagementPage/ProductionManagementPage')
);

const SalesforceArticleManagement = lazy(
  () => import('../../vrspages/salesforcearticlemanagement/SalesforceArticleManagement/SalesforceArticleManagement')
);

const DataTagConfigurationManagement = lazy(
  () => import('../../vrspages/datatagconfiguration/DataTagConfigurationManagement/DataTagConfigurationManagement')
);

const TranslationsManagement = lazy(
  () => import('../../vrspages/translations/TranslationsManagement/TranslationsManagement')
);

const EventConfigurationManagement = lazy(
  () => import('../../vrspages/eventconfiguration/EventConfigurationManagement/EventConfigurationManagement')
);

const ShiftManagementPage = lazy(
  () => import('../../pages/schedulerpage/shifts/ShiftManagementPage/ShiftManagementPage')
);
const LineManagementPage = lazy(() => import('../../pages/linesetup/LineManagementPage/LineManagementPage'));
const ProductManagementPage = lazy(
  () => import('../../pages/productsetup/ProductManagementPage/ProductManagementPage')
);
const InspectionSetupManagementPage = lazy(
  () =>
    import('../../pages/inspections/InspectionSetupPage/InspectionSetupManagementPage/InspectionSetupManagementPage')
);
const LineDetailPage = lazy(() => import('../../pages/linepage/LineDetailPage/LineDetailPage'));
const LinesDash = lazy(() => import('../../pages/dashboard/LinesDashPage/LinesDashPage'));
const ReportNotificationPage = lazy(
  () => import('../../pages/schedulerpage/ReportNotificationPage/ReportNotificationPage')
);

const GettingStarted = lazy(() => import('../../exchangepages/GettingStarted/GettingStarted'));

const Explorer = lazy(() => import('../../exchangepages/Explorer/Explorer'));

const LinePages = lazy(() => import('../../pages/linepage/LinesPage'));
const HomePage = lazy(() => import('../../pages/homepage/HomePage'));
const DesignPage = lazy(() => import('../../pages/design/DesignPage'));
const LineSetupPage = lazy(() => import('../../pages/linesetup/LineSetupPage/LineSetupPage'));
const ProductSetupPage = lazy(() => import('../../pages/productsetup/ProductSetupPage/ProductSetupPage'));
const SchedulerPage = lazy(() => import('../../pages/schedulerpage/SchedulerPage/SchedulerPage'));

const PerformanceReport = lazy(() => import('../../vrspages/reports/performancereport/PerformanceReport'));

const SnapshotReport = lazy(() => import('../../vrspages/reports/snapshotreport/SnapshotReport'));

const ConsumablesReport = lazy(() => import('../../vrspages/reports/consumablesreport/ConsumablesReport'));

const RapidRecoveryReport = lazy(() => import('../../vrspages/reports/rapidrecoveryreport/RapidRecoveryReport'));

const UsageReport = lazy(() => import('../../vrspages/reports/usagereport/UsagesReport'));

const DataDownload = lazy(() => import('../../vrspages/reports/datadownload/DataDownload'));

const UnitRequiringAttentionReport = lazy(
  () => import('../../vrspages/reports/unitrequiringattentionreport/UnitRequiringAttentionReport')
);

interface IRouteElementProps {
  access?: any;
  path: string;
  tsPath?: string;
  sqlPath?: string;
  component;
  vrsAbilities?: any;
  forceToSites?: boolean;
  extraPath?: string;
}
const RouteElement = ({ access, path, tsPath, sqlPath, component, forceToSites, extraPath }: IRouteElementProps) => {
  const forceToSitesValue = forceToSites ? { forceToSites: true } : {};
  const accessValue = access ? access : {};

  return (
    <>
      <Route
        path={`${path}${extraPath ? extraPath : ''}`}
        element={<CheckAuthorisedRoute {...accessValue} path={path} component={component} {...forceToSitesValue} />}
      />
      {tsPath && (
        <Route
          path={`${tsPath}${extraPath ? extraPath : ''}`}
          element={<CheckAuthorisedRoute {...accessValue} path={tsPath} component={component} {...forceToSitesValue} />}
        />
      )}
      {sqlPath && (
        <Route
          path={`${sqlPath}${extraPath ? extraPath : ''}`}
          element={<CheckAuthorisedRoute {...accessValue} path={tsPath} component={component} {...forceToSitesValue} />}
        />
      )}
    </>
  );
};

const Layout = (): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigateToLink();

  const siteActions = useSiteActions();
  const companyActions = useCompanyActions();

  const { selectedSiteId, sites, isAdmin, isPureDesignUser, initialised, defaultPageDisplayRequired } =
    useAppGlobalState();
  const { cognitoUserLoaded, outageStatus, userProfileLarge } = useAppUserState();
  const selectedSite = useAppSelectedSite();
  const configState = useConfigState();
  const siteState = useAppSiteState();
  const configActions = useConfigActions();
  const userActions = useUserActions();
  const vrsUserActions = useVrsUserActions();

  const [salesforceTraceSent, setSalesforceTraceSent] = useState(false);

  const [isRedirected, setIsRedirected] = useState<boolean>(false);

  const [mounted, setMounted] = useState<boolean>(false);

  // For now only Config importers can use this feature
  const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
    (el) => el.Id === 'config_import' && el.MemberType === 'admin'
  );

  const site = sites.find((el) => el.id === selectedSiteId);
  const vrsAbilities = siteState.vrsAbilities;
  const accessInfo = getLinkAccessInfo({
    site,
    isAdmin,
    isPureDesignUser,
    selectedSiteId,
    vrsAbilities,
    isUserConfigImporter,
    useForAuthorizationCheck: true,
    isCurrentSiteExternal: configState.appDataInitialization.firstSiteData.isExternal,
  });

  useEffect(() => {
    if (location.pathname.indexOf('/dashboard/lines') > -1) {
      configActions.setDashboardMode(true);
    } else if (configState.dashboardMode) {
      configActions.setDashboardMode(false);
    }
  }, [location.pathname, configActions]);
  const access = {
    accessInfo,
    params: {
      siteId: selectedSiteId,
    },
  };
  useEffect(() => {
    if (!isPureDesignUser && initialised && sites && sites.length > 0 && !isRedirected) {
      (async () => {
        const cookieContainsCompanyAndSite = Utils.companyAndSiteInCookies();
        const currentUser = await getCurrentUser();
        if (currentUser) {
          if (!cookieContainsCompanyAndSite) {
            siteActions.setSite('0', currentUser);
          } else {
            const targetSite = sites[0];
            siteActions.setSite(targetSite.id, currentUser);
            companyActions.setVrsCompanyId(targetSite ? targetSite.companyId : '0');
          }
        }
      })();

      setIsRedirected(true);
    }
  }, [sites, initialised, selectedSite, location, companyActions, isRedirected]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      console.log('layout mounted');
    }

    return () => {
      if (mounted) {
        console.log('layout unmounted');
      }
    };
  }, [mounted]);

  useEffect(() => {
    const purePath = location.pathname.replace(/\/r\/\d*$/, '');
    if (location.pathname !== purePath) {
      window.history.replaceState(null, '', purePath);
      navigate(purePath, { replace: true });
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    (async () => {
      if (configState.queryStringChecked) {
        const { companyId, siteId, TrafficSource, TrafficID, SFDCRecord } = Utils.getTrackingValuesFromQueryParameters(
          window.location.search
        );

        if (TrafficSource === 'SFDC' && !salesforceTraceSent) {
          setSalesforceTraceSent(true);

          let accessType = 'Unknown';
          if (window.location.pathname.includes('vrs/device')) {
            accessType = 'Device';
          } else if (window.location.pathname.includes('vrs/sitedashboard')) {
            accessType = 'Site';
          }

          try {
            const result = await vrsUserActions.saveSalesforceTrackerAppSync(
              TrafficSource.trim(),
              TrafficID.trim(),
              SFDCRecord.trim(),
              accessType
            );
            const saveResult = result?.data;
            if (saveResult) {
              console.log('saved to salesforce');
            } else if (result.error) {
              const errStr = extractErrors(result.error);
              if (errStr) {
                toastr.error(errStr);
              }
            }
          } catch (error) {
            const errStr = extractErrors(error);
            toastr.error(errStr || error);
          }
        }

        if (companyId) {
          let historyReplaced = false;
          const pathname = location.pathname;
          if (!Utils.IsIdGuid(companyId) && (siteId === '0' || siteId === '')) {
            try {
              if (!configState.hasCompanyAccess) {
                navigate('/unauthorized');
              } else {
                Utils.setCompanyCookie(companyId);
                navigate(pathname, { replace: true });
                historyReplaced = true;
              }
            } catch (err) {
              console.log('err');
            }
          } else if (!Utils.IsIdGuid(companyId) && siteId !== '0' && siteId !== '') {
            try {
              if (!configState.hasSiteAccess) {
                navigate('/unauthorized');
              } else {
                Utils.setCompanyAndPlantCookie(companyId, siteId);
                navigate(pathname, { replace: true });
                historyReplaced = true;
              }
            } catch (err) {
              console.log('err');
            }
          }
          userActions.setPageRequired(false);

          if (!historyReplaced) {
            navigate(location.pathname, { replace: true });
          }
        }
      }
    })();
  }, [
    companyActions,
    userActions,
    location,
    navigate,
    initialised,
    defaultPageDisplayRequired,
    configState.queryStringChecked,
    configState.hasCompanyAccess,
    configState.hasSiteAccess,
    salesforceTraceSent,
  ]);

  return initialised ? (
    <>
      {!configState.dashboardMode && <Header />}
      <div className={classes.root}>
        {!configState.dashboardMode && <Sidebar />}

        {cognitoUserLoaded && (
          <div className={configState.dashboardMode ? classes.displayModeContainer : classes.contentContainer}>
            {
              <div className={configState.dashboardMode ? classes.displayModeContent : classes.content}>
                <div className={outageStatus ? classes.headerSpaceExtra : classes.headerSpace}></div>
                <Suspense fallback={<ProgressIndicator />}>
                  <Routes>
                    <Route
                      // exact
                      path="/"
                      element={<Navigate to={'/home'} />}
                    />
                    {selectedSiteId && (
                      <Route
                        // exact
                        path="/site"
                        element={<Navigate to={`/site/${selectedSiteId}/lines`} />}
                      />
                    )}
                    <Route path="/unauthorized" element={<NotAuthorised />} />
                    {RouteElement({
                      access,
                      path: '/vrs/sitemap/',
                      tsPath: '/vrs/sitemap-ts/',
                      sqlPath: '/vrs/sitemap-sql/',
                      component: <SiteMap />,
                      vrsAbilities,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/logmanagement/',
                      component: <LogManagement />,
                      vrsAbilities,
                      extraPath: '*',
                    })}

                    {RouteElement({
                      access,
                      path: '/setup/site/:siteId/:setupMode',
                      component: <SiteForm />,
                    })}
                    {RouteElement({
                      access,
                      path: '/site/:siteId/lines/:lineMode',
                      component: <LinePages />,
                      forceToSites: true,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/lines',
                      component: <LinePages />,
                      forceToSites: true,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/dashboard/lines',
                      component: <LinesDash />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/line/:lineId/:actionMode',
                      component: <LineManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/line/:id',
                      component: <LineDetailPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/lines',
                      component: <LineSetupPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/home',
                      component: <HomePage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/design',
                      component: <DesignPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/summary',
                      component: <SummaryPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/sitedashboard',
                      component: <DeviceManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/availability',
                      component: <AvailabilityPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/production',
                      component: <ProductionPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/datadownload',
                      component: <DataDownload />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/workschedule',
                      component: <WorkSchedulePage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/alertmanagement',
                      component: <AlertManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/servicedashboard',
                      component: <ServiceDashboard />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/salesforcearticles',
                      component: <SalesforceArticleManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/companymanagement',
                      component: <VrsCompanyList />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/sitemanagement',
                      component: <VrsSiteList />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/datatags',
                      component: <DataTagConfigurationManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/events',
                      component: <EventConfigurationManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/translations',
                      component: <TranslationsManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/devicemanagement',
                      component: <VrsPrinterList />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/favorites',
                      component: <FavoritesManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/notificationlog',
                      component: <NotificationLogPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/resmanagement',
                      component: <ResManagement />,
                    })}

                    {RouteElement({
                      access,
                      path: '/register',
                      component: Utils.isDev() ? <ResRegister /> : <NotFound />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/device/:printerId',
                      component: <DeviceInformation />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/product/:productId/:actionMode',
                      component: <ProductManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/products',
                      component: <ProductSetupPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/scheduledReport/:userId/:title/:action',
                      component: <ReportNotificationPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/displayMode',
                      component: <DisplayModeSettingsSetupPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/deviceroles',
                      component: <DeviceRoleSetupPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/inspection/:inspectionId/:actionMode',
                      component: <InspectionSetupManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/inspections',
                      component: <InspectionSetupPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/compliance',
                      component: <InspectionSettingsPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/downtimes/category/:setupParam/:action',
                      component: <DowntimeCategoryManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/downtimes/subcategory/:setupParam/:action',
                      component: <DowntimeSubCategoryManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/shifts/:shiftPage',
                      component: <SchedulerPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/shifts',
                      component: <SchedulerPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/shift/:id/:actionMode/:typeId/:fromSchedule',
                      component: <ShiftManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/shift/:id/:actionMode/:typeId',
                      component: <ShiftManagementPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/downtimes',
                      component: <DowntimeSetupMainPage />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      path: '/company/users',
                      component: <CompanyUserList />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/users',
                      component: <VrsCompanyUserList />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/performancereport',
                      component: <PerformanceReport />,
                    })}
                    {RouteElement({
                      access,
                      path: '/vrs/snapshotreport',
                      component: <SnapshotReport />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/rapidrecoveryreport',
                      component: <RapidRecoveryReport />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/usagereport',
                      tsPath: '/vrs/usagereport-ts',
                      sqlPath: '/vrs/usagereport-sql',
                      component: <UsageReport />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/unitrequiringattentionreport',
                      component: <UnitRequiringAttentionReport />,
                    })}

                    {RouteElement({
                      access,
                      path: '/vrs/consumablesreport',
                      component: <ConsumablesReport />,
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/setup/roles',
                      component: <RoleSetupMainPage />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/report',
                      component: <ReportsDashPage />,
                      extraPath: '/*',
                    })}

                    {RouteElement({
                      access,
                      path: '/site/:siteId/admin',
                      component: <AdminPage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/sites/operations',
                      component: <OperationsPage />,
                    })}

                    {RouteElement({
                      path: '/sites',
                      component: <SitePage />,
                    })}

                    {RouteElement({
                      access,
                      path: '/api/gettingstarted',
                      component: <GettingStarted />,
                    })}

                    {RouteElement({
                      access,
                      path: '/api/explorer',
                      component: <Explorer />,
                    })},
                    {RouteElement({
                      access,
                      path: '/vrs/sitetags',
                      component: <SiteTagsPage />,
                    })}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </div>
            }
            {!configState.dashboardMode && (
              <div className={classes.footer}>
                <Footer />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <ProgressIndicator fullScreen={true} />
  );
};

Layout.displayName = 'Layout';

export default Layout;
