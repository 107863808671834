import { useLoginStyles } from "./Login.css";
import { Typography, Avatar } from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

const LoginHeader = () => {
  const classes = useLoginStyles();
  const { _T } = useVrsTranslationState();
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {_T("WelcomeVideojetConnect")}
      </Typography>
    </div>
  );
};

export default LoginHeader;
