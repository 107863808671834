import { useCallback } from "react";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { myWhite } from "../../../constants/colors";
import { Theme } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export const useVrsTableHeadTextStyles = makeStyles((_: Theme) => ({
  textContainer: {
    margin: "0px",
    textAlign: "center",
    wordWrap: "break-word",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sortIcon: {
    color: myWhite,
    padding: 0,
  },
  selected: {
    textDecoration: "underline",
  },
}));

interface VrsTableHeadTextProps {
  text: any;
  fontSize?: string;
  textAlign?: any;
  extraClass?: string;
  dataId?: string;
  toggleSort?: (dataId: string) => void;
  sortedColumn?: string;
}

export const VrsTableHeadText = ({
  text,
  textAlign,
  fontSize,
  extraClass,
  dataId,
  toggleSort,
  sortedColumn,
}: VrsTableHeadTextProps) => {
  const classes = useVrsTableHeadTextStyles();

  const isSorted = useCallback(
    (columnId) => {
      return sortedColumn === columnId;
    },
    [sortedColumn]
  );

  const handleSort = useCallback(() => {
    if (toggleSort && dataId) {
      toggleSort(dataId);
    }
  }, [toggleSort, dataId]);

  return (
    <Typography component="div">
      <div className={classes.header}>
        <Box
          className={
            extraClass
              ? classNames(classes.textContainer, extraClass)
              : classes.textContainer
          }
          m={1}
          fontWeight="fontWeightBold"
          fontSize={fontSize || "h6.fontSize"}
          textAlign={textAlign || "center"}
        >
          {dataId && isSorted(dataId) ? (
            <span className={classes.selected}> {text}</span>
          ) : (
            text
          )}
        </Box>
        {dataId && handleSort && (
          <div>
            <IconButton
              onClick={handleSort}
              classes={{ root: classes.sortIcon }}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Typography>
  );
};
