import { GridSlots, GridRowHeightReturnValue } from '@mui/x-data-grid-pro';
import { GridToolbar } from '@mui/x-data-grid';
import { ProgressIndicator } from '../components/ProgressIndicator/ProgressIndicator';
import { useVrsTranslationState } from '../context/AppContext/AppContext';
import { EmptyItem } from '../components/Basic/EmptyItem/EmptyItem';
import {
  useCallback,
  useMemo
} from "react";

const useCommonStyledDataGridProps = (columns: any, rows: any, apiRef?: any, initialState?: any, showSearch = true) => {
  const { _T } = useVrsTranslationState();

  const emptyItemText = useMemo(() => _T('No Results Found'), [_T]);
  const defaultInitialState = useMemo(() => ({ pagination: { paginationModel: { pageSize: 50 } } }), []);

  const getRowClassName = useCallback((params: any) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd', []);
  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', []);

  const memoizedInitialState = useMemo(() => ({ ...defaultInitialState, ...initialState }), [defaultInitialState, initialState]);


  const memoizedSlots = useMemo(() => ({
    loadingOverlay: ProgressIndicator as GridSlots['loadingOverlay'],
    noResultsOverlay: () => <EmptyItem text={emptyItemText} />,
  noRowsOverlay: () => <EmptyItem text={emptyItemText} />,
  toolbar: showSearch ? GridToolbar : undefined,
}), [emptyItemText, showSearch]);

  const memoizedSlotProps = useMemo(() => showSearch ? {
    toolbar: {
      csvOptions: { disableToolbarButton: true },
      printOptions: { disableToolbarButton: true },
      showQuickFilter: true,
    },
  } : undefined, [showSearch]);

  const memoizedSx = useMemo(() => ({ border: 0 }), []);

  return {
    apiRef,
    autoHeight: true,
    columns,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    disableRowSelectionOnClick: true,
    getRowClassName,
    getRowHeight,
    hideFooterPagination: !rows || rows.length <= 25,
    initialState: memoizedInitialState,
    pagination: true,
    rows,
    slots: memoizedSlots,
    slotProps: memoizedSlotProps,
    sx: memoizedSx,
  };
};

export default useCommonStyledDataGridProps;
