import { makeStyles } from '@mui/styles';

const useSiteTagsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(0, '!important'),
  },
  rootContainer: {
    display: 'grid',
    width: '100%',
    position: 'relative',
  },
  barRoot: {
    display: 'flex',
    padding: theme.spacing(1.5, 0, '!important'),
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    wordBreak: 'normal',
  },
  formControl: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  actionButton: {
    padding: theme.spacing(1.25),
  },
  gridContainer: {
    padding: theme.spacing(1.25),
    justifyContent: 'center',
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  dropdownRoot: {
    padding: theme.spacing(1.5, 2.875),
    minHeight: theme.spacing(3.5),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  actionCells: {
    justifyContent: "flex-end !important",
    padding: theme.spacing(1),
  },
  nominalDeviceHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    fontWeight: 'bold',
  },
  tooltipOffset: {
    marginLeft: theme.spacing(0.5),
    color: '#FFF',
  },
  accordion: {
    width: '100%',
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.5),
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  taggedDevice: {
    width: '100%',
    padding: '0.5rem',
    borderRadius: '4px',
  },
  taggedAccordionWithBorder: {
    width: '100%',
    border: `2px solid`,
    padding: '0.5rem',
    borderRadius: '4px',
    backgroundColor: '#FFF',
  },
  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  parent:{
    height: '100rem',
  }
}));

export default useSiteTagsStyles;
