import { useEffect, useState } from "react";

import { DialogProps } from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { pdfjs, Page, Document } from "react-pdf";
import useDimensions from "react-use-dimensions";

import termsPdf from "../../static/VideojetConnect SaaS Terms and Conditions 080413.pdf";

import { useTermsDialogStyle } from "./TermsDialog.css";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";
import { green } from "@mui/material/colors";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TermsDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
}

const TermsDialog = ({ open, maxWidth, onClose }: TermsDialogProps) => {
  const classes = useTermsDialogStyle();
  const { _T } = useVrsTranslationState();

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [termsModalRef, { width }] = useDimensions();
  const [canAccept, setCanAccept] = useState<boolean>(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  useEffect(() => {
    if (canAccept) {
      return;
    }
    setCanAccept(pageNumber === numPages);
  }, [canAccept, pageNumber, numPages]);

  const onDocumentLoadSuccess = ({ numPages: num }) => {
    setNumPages(num);
  };

  return (
    <DialogWithBackdropClick
      disableBackdropClick
      classes={{ paper: classes.root }}
      fullWidth={true}
      maxWidth={maxWidth || "lg"}
      disableEscapeKeyDown
      aria-labelledby="terms"
      open={open}
    >
      <DialogTitle id="terms-dialog-title">
        <div className={classes.title}>
          <div>
            <HelpOutlineIcon className={classes.titleIcon} />
          </div>
          <div>
            <div className={classes.titleText}>TERMS and CONDITIONS</div>
            <div>{`By registering you agree to Videojet's TERMS and CONDITIONS ${pageNumber} / ${numPages}`}</div>
          </div>
        </div>
        <IconButton
          aria-label="close"
          data-testid="terms-close-btn"
          className={classes.closeButton}
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div ref={termsModalRef} className={classes.documentContainer}>
          <Document file={termsPdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={width} />
          </Document>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <div className={classes.backBtnContainer}>
          <Button
            color="primary"
            variant="contained"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            startIcon={<NavigateBeforeIcon />}
            className={"previous-btn"}
          >
            {_T("Back")}
          </Button>
        </div>
        <div className={classes.nextBtnContainer}>
          <Button
            color="primary"
            variant="contained"
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
            endIcon={<NavigateNextIcon />}
            data-testid="next-btn"
            className={classes.rightButton}
          >
            {_T("Next")}
          </Button>
          <Button
            autoFocus
            onClick={handleOk}
            data-testid="terms-ok-btn"
            className={classes.rightButton}
            variant="contained"
            disabled={!canAccept}
            sx={{ color: green }}
          >
            {_T("Accept")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const link = document.createElement("a");
              link.download = ".pdf";
              link.href = termsPdf;
              link.click();
              setCanAccept(true);
            }}
            startIcon={<GetAppIcon />}
            data-testid="download-btn"
            className={classes.rightButton}
          >
            {_T("Download")}
          </Button>
        </div>
      </DialogActions>
    </DialogWithBackdropClick>
  );
};

export { TermsDialog };
