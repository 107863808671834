import { useCallback, useState } from "react";
import { validation } from "vccm-common";
import { DialogProps } from "@mui/material/Dialog";
import { ProgressIndicator } from "../ProgressIndicator/ProgressIndicator";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@mui/material";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { green, red } from "@mui/material/colors";

import { useNewPasswordWithCodeDialogStyle } from "./NewPasswordWithCodeDialog.css";
import {
  containsLowercase,
  containsNumber,
  containsUppercase,
  isMinLength,
} from "../../utilities/validations";
import { KEYCODE_LENGTH } from "../../constants/global";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

const getIcon = (passed) => (
  <>
    {passed ? (
      <CheckIcon sx={{ color: green }} className="valid" />
    ) : (
      <CloseIcon sx={{ color: red }} className="invalid" />
    )}
  </>
);

interface NewPasswordWithCodeDialogProps {
  loading: boolean;
  open: boolean;
  onClose: (validationCode: string, password: string) => void;
  maxWidth?: DialogProps["maxWidth"];
}

const NewPasswordWithCodeDialog = ({
  loading,
  open,
  maxWidth,
  onClose,
}: NewPasswordWithCodeDialogProps) => {
  const classes = useNewPasswordWithCodeDialogStyle();

  const { _T } = useVrsTranslationState();

  const [errors, setErrors] = useState<any>({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [validationCode, setValidationCode] = useState("");

  const validateForm = useCallback(() => {
    // use the user schema to validate password rules
    const results = validation.schemas.user.validate(
      {
        password,
        confirmPassword,
      },
      { abortEarly: false }
    );
    const errs = results.error.details.reduce((agg, d) => {
      agg[d.context.key] = d.message ? d.message.replace(/\./g, "_") : "";
      return agg;
    }, {});
    setErrors(errs);

    return !(errs.confirmPassword || errs.password);
  }, [password, confirmPassword]);

  const handleCancel = () => {
    onClose("", "");
  };

  const handleOk = (password) => {
    onClose(validationCode, password);
  };

  const checks = {
    passwordMatches:
      password && confirmPassword && password === confirmPassword,
    isMinLength: isMinLength(password, 8),
    containsNumber: containsNumber(password),
    containsUppercase: containsUppercase(password),
    containsLowercase: containsLowercase(password),
  };

  const allChecksPass = Object.keys(checks).reduce(
    (acc, el) => acc && checks[el],
    true
  );

  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        classes={{ paper: classes.root }}
        fullWidth={true}
        maxWidth={maxWidth || "sm"}
        disableEscapeKeyDown
        aria-labelledby="new-password-with-code"
        open={open}
      >
        <DialogTitle id="password-dialog-title">
          <div className={classes.title}>
            <div>
              <PermIdentityIcon className={classes.titleIcon} />
            </div>
            <div>{_T("Password")}</div>
          </div>
          <IconButton
            aria-label="close"
            data-testid="password-close-btn"
            className={classes.closeButton}
            onClick={handleCancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.passwordCheck}>
            {_T("Password_Reset_Message")}
          </div>
          <FormControl className={classes.formControl}>
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="validationCode"
              label={_T("VerificationCode")}
              onChange={(event) => setValidationCode(event.target.value)}
              id="validationCode"
              disabled={loading}
              value={validationCode}
              error={errors && !!errors.validationCode}
              helperText={
                errors && errors.validationCode && _T(errors.validationCode)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              autoComplete="off"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={_T("Password")}
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              id="password"
              disabled={loading}
              value={password}
              error={errors && !!errors.password}
              helperText={errors && errors.password && _T(errors.password)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={_T("Confirm_Password")}
              onChange={(event) => setConfirmPassword(event.target.value)}
              type="password"
              id="confirmPassword"
              disabled={loading}
              value={confirmPassword}
              error={errors && !!errors.confirmPassword}
              helperText={
                errors && errors.confirmPassword && _T(errors.confirmPassword)
              }
            />
          </FormControl>
          <div>
            <div>{_T("PasswordRequirements")}</div>
            <List>
              <ListItem>
                <div className={classes.passwordCheck}>
                  {getIcon(checks.passwordMatches)}
                  {_T("PasswordsMustMatch")}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.passwordCheck}>
                  {getIcon(checks.isMinLength)}
                  {_T("PasswordIsMinLength")}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.passwordCheck}>
                  {getIcon(checks.containsNumber)}
                  {_T("PasswordContainsNumber")}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.passwordCheck}>
                  {getIcon(checks.containsUppercase)}
                  {_T("PasswordContainsUppercase")}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.passwordCheck}>
                  {getIcon(containsLowercase(password))}
                  {_T("PasswordContainsLowercase")}
                </div>
              </ListItem>
            </List>
          </div>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button
            autoFocus
            onClick={() => {
              if (validateForm()) {
                handleOk(password);
              }
            }}
            data-testid="password-ok-btn"
            variant="contained"
            disabled={
              loading ||
              !allChecksPass ||
              validationCode.length < KEYCODE_LENGTH
            }
            color="primary"
          >
            {_T("Ok")}
          </Button>
        </DialogActions>
      </DialogWithBackdropClick>
      {loading && <ProgressIndicator />}
    </>
  );
};

export { NewPasswordWithCodeDialog };
