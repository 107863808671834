import moment from "moment-timezone";
import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import Utils from "../utilities/utils";
import { IAllPrinter } from "../interfaces/Printer/IAllPrinter";
import { ISavePrinterInput } from "../interfaces/Printer/ISavePrinterInput";

const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_DEVICE_URL"];
};

class VrsPrinterApi {
  static getPrinters(companyId: string, plantId: string) {
    const allPrinter: IAllPrinter = {
      printers: [],
      disabledPrinters: [],
      plants: [],
      printerDisableReasons: []
    };
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve(allPrinter);
    }

    const graphQLBody = {
      query: `
          query PrintersQuery {
            printers(companyId: "${companyId}", plantId: "${plantId}") {
              Printers {
                PrinterId 
                SerialNumber 
                ModelId 
                FriendlyName 
                PlantId 
                PlantName 
                PrinterLicenseStartDate 
                PrinterLicenseEndDate 
                ModelName
                IsUptimeGuaranteed 
                UptimePercentageThreshold 
	              LicenseStatusImageName 
                LicenseStatus {
                  LicenseDatesText 
                  LicenseColor 
                  LicenseStatusText 
                  licenseImageString 
                }
              }
              DisabledPrinters {
                PrinterId 
                SerialNumber 
                ModelId 
                FriendlyName 
                PlantId 
                PlantName 
                PrinterLicenseStartDate 
                PrinterLicenseEndDate 
                ModelName
                CreationDate
              }
              Plants {
                CompanyName
                CountryId
                PlantCreationDate
                MaintenanceScheduleTime
                PlantId
                PlantName
                TimeZoneId
                ServiceTerritory
              }
              PrinterDisableReasons {
                ReasonName 
                ReasonId
              }
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.printers) {
        const allData = results.data?.printers;

        if (allData.PrinterDisableReasons) {
          allPrinter.printerDisableReasons = allData.PrinterDisableReasons;
        }

        if (allData.Plants) {
          for (const site of allData.Plants) {
            allPrinter.plants.push({
              plantName: site.PlantName,
              companyName: site.CompanyName,
              serviceTerritory: site.ServiceTerritory,
              countryName: site.CountryName,
              printerCount: site.PrinterCount,
              isDeleted: site.IsDeleted,
              isExternal: site.IsExternal,
              timezoneId: site.TimeZoneId,
              plantId: site.PlantId,
            });
          }
        }

        if (allData.Printers) {
          for (const printer of allData.Printers) {
            allPrinter.printers.push({
              printerId: printer.PrinterId,
              serialNumber: printer.SerialNumber,
              modelId: printer.ModelId,
              friendlyName: printer.FriendlyName,
              plantId: printer.PlantId,
              plantName: printer.PlantName,
              printerLicenseStartDate: printer.PrinterLicenseStartDate,
              printerLicenseEndDate: printer.PrinterLicenseEndDate,
              modelName: printer.ModelName,
              uptimeGuaranteed: printer.IsUptimeGuaranteed,
              uptimePercentageThreshold: printer.UptimePercentageThreshold,
              licenseStatusImageName: printer.LicenseStatusImageName,
              LicenseStatus: printer.LicenseStatus,
            });
          }
        }

        if (allData.DisabledPrinters) {
          for (const printer of allData.DisabledPrinters) {
            allPrinter.disabledPrinters.push({
              printerId: printer.PrinterId,
              serialNumber: printer.SerialNumber,
              modelId: printer.ModelId,
              friendlyName: printer.FriendlyName,
              plantId: printer.PlantId,
              plantName: printer.PlantName,
              printerLicenseStartDate: printer.PrinterLicenseStartDate,
              printerLicenseEndDate: printer.PrinterLicenseEndDate,
              modelName: printer.ModelName,
              creationDate: printer.CreationDate,
            });
          }
        }
      }
      return allPrinter;
    });
  }

  static getPrinterLicenseHistory(printerId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: "Invalid Url!",
        data: null,
      });
    }

    const graphQLBody = {
      query: `
          query LicenceHistoryQuery {
            licenseHistory(printerId: "${printerId}") {
              CreateDate
              LicenseEndDate
              LicenseStartDate
              PrinterId
              UserId
              Username
              UserEmail
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.licenseHistory) {
        return {
          error: null,
          data: results.data.licenseHistory.map((el) => ({
            user: el.Username,
            userEmail: el.UserEmail,
            startDate: el.LicenseStartDate,
            endDate: el.LicenseEndDate,
            created: el.CreateDate,
          })),
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static savePrinterAppSync(savePrinterInput: ISavePrinterInput) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation SavePrinterMutation
      {
        savePrinter(savePrinterInput: {
          PlantId: "${savePrinterInput.PlantId}"
          CompanyId: "${savePrinterInput.CompanyId}"
          PrinterId: "${savePrinterInput.PrinterId}"
          PrinterName: "${savePrinterInput.PrinterName}"
          EnablePrinter: "${savePrinterInput.EnablePrinter}"
          DisablePrinter: "${savePrinterInput.DisablePrinter}"
          hdPrinterLicenseStartDate: ${
            savePrinterInput.PrinterLicenseStartDate
              ? '"' +
                Utils.evaluateAsUtc(
                  moment(savePrinterInput.PrinterLicenseStartDate),
                  true
                ) +
                '"'
              : null
          }
          hdPrinterLicenseEndDate: ${
            savePrinterInput.PrinterLicenseEndDate
              ? '"' +
                Utils.evaluateAsUtc(
                  moment(savePrinterInput.PrinterLicenseEndDate),
                  true
                ) +
                '"'
              : null
          }
          hdIsUptimeGuaranteed: ${savePrinterInput.hdIsUptimeGuaranteed}
          hdIsHealthscoreGuaranteed: ${
            savePrinterInput.hdIsHealthscoreGuaranteed
          }
          UptimePercentageThreshold: ${
            savePrinterInput.UptimePercentageThreshold
              ? savePrinterInput.UptimePercentageThreshold
              : null
          }
          HealthscorePercentageThreshold: ${
            savePrinterInput.HealthscorePercentageThreshold
              ? savePrinterInput.HealthscorePercentageThreshold
              : null
          }
          PrinterDisableReasonId: ${
            savePrinterInput.PrinterDisableReasonId
              ? savePrinterInput.PrinterDisableReasonId
              : null
          }
        }) {
          PrinterId 
          SerialNumber 
          ModelId 
          FriendlyName 
          PlantId 
          PlantName 
          PrinterLicenseStartDate 
          PrinterLicenseEndDate 
          ModelName 
          }
        }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.savePrinter) {
        return {
          error: null,
          data: results.data?.savePrinter,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static restorePrinterAppSync(printerId: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation RestorePrinterMutation
      {
        restorePrinter(printerId: "${printerId}")
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_DEVICE",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.restorePrinter) {
        return {
          error: null,
          data: results.data?.restorePrinter,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
}

export default VrsPrinterApi;
