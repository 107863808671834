import makeStyles from "@mui/styles/makeStyles";
import {
  myGreen,
  myRed,
  mySecondaryColor,
  standardBoxShadow,
} from "../../constants/colors";
import { Theme } from "@mui/material";

export const usePasswordResetDialogStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.25, 1.875),
    margin: theme.spacing(1.25),
    borderRadius: theme.spacing(1.5),
    boxShadow: standardBoxShadow,
  },
  content: {
    display: "flex",
    flexFlow: "column",
    marginBottom: theme.spacing(2.5),
  },
  formControl: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    "&:nth-last-child(n + 2)": {
      marginBottom: theme.spacing(1.875),
    },
  },
  formControlInner: {
    display: "flex",
    flexFlow: "column",
    width: "100%",
    maxWidth: theme.spacing(70),
    alignItems: "flex-start",
    "&:nth-last-child(n + 2)": {
      marginBottom: theme.spacing(1.875),
    },
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleText: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
  },
  titleIcon: {
    fontSize: theme.spacing(6),
    color: mySecondaryColor,
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  backBtnContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  nextBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  documentContainer: {
    padding: theme.spacing(0.125),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rightButton: {
    marginRight: theme.spacing(1.5),
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
  successIcon: {
    color: myGreen,
    marginRight: theme.spacing(1.5),
  },
  errorIcon: {
    color: myRed,
    marginRight: theme.spacing(1.5),
  },
}));
