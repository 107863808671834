import makeStyles from "@mui/styles/makeStyles";
import { myGrayShade4, requiredColor } from "../../../constants/colors";
import { Theme } from "@mui/material";

export const useSimpleDropdownStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2.25, 2.875)} !important`,
  },
  dimmedLabel: {
    color: myGrayShade4,
  },
  inputLabel: {
    marginBottom: theme.spacing(0.5),
  },
  icon: {
    display: "flex",
    marginRight: theme.spacing(1.125),
  },
  valueContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemText: {
    whiteSpace: "pre-wrap",
  },
  errorText: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    color: requiredColor,
    fontSize: '0.75rem'
  },
}));
