import { useMemo } from "react";
import CompanyApi from "../api/prodCompanyApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import {
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANY_SUCCESS,
  LOAD_COMPANIES_INITIATED,
  LOAD_COMPANIES_FAILED,
  LOAD_SITES_FOR_COMPANY_INITIATED,
  LOAD_SITES_FOR_COMPANY_SUCCESS,
  LOAD_SITES_FOR_COMPANY_FAILED,
  VRS_COMPANY_MIGRATION_SUCCESS,
  SET_COMPANY_ID_AND_SITE_ID,
  SET_COMPANY_ID,
  SET_COMPANY_SITE_ID,
  SET_SITES_FOR_COMPANY,
  LOAD_SITE_SUCCESS,
  RESET_SITES_FOR_COMPANY,
} from "./actionTypes";

export function loadCompaniesInitiated(data) {
  return { type: LOAD_COMPANIES_INITIATED, data };
}

export function loadCompaniesSuccess(payload) {
  return { type: LOAD_COMPANIES_SUCCESS, payload };
}

export function loadCompaniesFailed(payload) {
  return { type: LOAD_COMPANIES_FAILED, payload };
}

function getCompaniesAppSync(ignoreFavorites: boolean) {
  return (dispatch) => {
    dispatch(loadCompaniesInitiated({}));
    return CompanyApi.getCompaniesAppSync(ignoreFavorites)
      .then((companies) => {
        dispatch(loadCompaniesSuccess(companies));
        return companies;
      })
      .catch((error) => {
        console.log("companyAction.getCompany error", error);
        loadCompaniesFailed({});
        throw error;
      });
  };
}

function getCompanyAppSync(companyId: string) {
  return (dispatch) => {
    dispatch({ type: LOAD_SITES_FOR_COMPANY_INITIATED, payload: {} });
    return CompanyApi.getCompanyAppSync(companyId)
      .then((companies: any) => {
        const company = companies.length === 1 ? companies[0] : null;
        if (company) {
          dispatch({ type: LOAD_COMPANY_SUCCESS, payload: company });
        }
        return company;
      })
      .catch((error) => {
        console.log("companyAction.getCompanyAppSync error", error);
        throw error;
      });
  };
}

function getPlantAppSync(plantId: number) {
  return (dispatch) => {
    return CompanyApi.getPlantAppSync(plantId)
      .then((plants: any) => {
        const plant = plants.length === 1 ? plants[0] : null;
        if (plant) {
          dispatch({ type: LOAD_SITE_SUCCESS, payload: plant });
        }
        return plant;
      })
      .catch((error) => {
        console.log("companyAction.getPlantAppSync error", error);
        throw error;
      });
  };
}

function getSiteForCompany(companyId) {
  return (dispatch) => {
    dispatch({ type: LOAD_SITES_FOR_COMPANY_INITIATED, payload: {} });
    return CompanyApi.getSitesForCompany(companyId)
      .then((sites) => {
        dispatch({ type: LOAD_SITES_FOR_COMPANY_SUCCESS, payload: sites });
        return sites;
      })
      .catch((error) => {
        console.log("companyAction.getCompany error", error);
        dispatch({ type: LOAD_SITES_FOR_COMPANY_FAILED, payload: {} });
        throw error;
      });
  };
}

function getPlantsAppSync(companyId: string, ignoreFavorites: boolean) {
  return (dispatch) => {
    dispatch({ type: LOAD_SITES_FOR_COMPANY_INITIATED, payload: {} });
    return CompanyApi.getPlantsAppSync(companyId, false, ignoreFavorites)
      .then((plants) => {
        dispatch({ type: LOAD_SITES_FOR_COMPANY_SUCCESS, payload: plants });
        return plants;
      })
      .catch((error) => {
        console.log("companyAction.getCompany error", error);
        dispatch({ type: LOAD_SITES_FOR_COMPANY_FAILED, payload: {} });
        throw error;
      });
  };
}

function resetCompanyPlants() {
  return (dispatch) => {
    dispatch({ type: RESET_SITES_FOR_COMPANY, payload: null });
  };
}

function getAllPlantsAppSync(companyId: string) {
  return (_) => {
    return CompanyApi.getPlantsAppSync(companyId, true)
      .then((plants) => {
        return plants;
      })
      .catch((error) => {
        console.log("companyAction.getCompany error", error);
        throw error;
      });
  };
}

function migrateVrsCompanies(companies) {
  return (dispatch) => {
    return CompanyApi.migrateCompanies(companies)
      .then((result) => {
        dispatch({ type: VRS_COMPANY_MIGRATION_SUCCESS, payload: result });
        return result;
      })
      .catch((error) => {
        console.log("companyActions.migrateVrsCompanies error", error);
        throw error;
      });
  };
}

function setVrsCompanyAndSiteId(companyId, siteId) {
  return (dispatch) => {
    dispatch({
      type: SET_COMPANY_ID_AND_SITE_ID,
      payload: { companyId, siteId },
    });
  };
}

function setVrsCompanyId(companyId) {
  return (dispatch) => {
    dispatch({ type: SET_COMPANY_ID, payload: { companyId } });
  };
}

function setVrsCompanySiteId(siteId) {
  return (dispatch) => {
    dispatch({ type: SET_COMPANY_SITE_ID, payload: { siteId } });
  };
}

function setVrsCompanySites(sites) {
  return (dispatch) => {
    dispatch({ type: SET_SITES_FOR_COMPANY, payload: sites });
  };
}

export const useCompanyActions = () => {
  const dispatch = useAppGlobalDispatch();

  return useMemo(
    () => ({
      getSiteForCompany: (companyId) => getSiteForCompany(companyId)(dispatch),
      migrateVrsCompanies: (companies) =>
        migrateVrsCompanies(companies)(dispatch),
      setVrsCompanyAndSiteId: (companyId, siteId) =>
        setVrsCompanyAndSiteId(companyId, siteId)(dispatch),
      setVrsCompanyId: (companyId) => setVrsCompanyId(companyId)(dispatch),
      setVrsCompanySiteId: (siteId) => setVrsCompanySiteId(siteId)(dispatch),
      setVrsCompanySites: (sites) => setVrsCompanySites(sites)(dispatch),

      getCompaniesAppSync: (ignoreFavorites = false) =>
        getCompaniesAppSync(ignoreFavorites)(dispatch),
      getPlantsAppSync: (companyId: string, ignoreFavorites = false) =>
        getPlantsAppSync(companyId, ignoreFavorites)(dispatch),
      getAllPlantsAppSync: (companyId: string) =>
        getAllPlantsAppSync(companyId)(dispatch),

      getCompanyAppSync: (companyId) => getCompanyAppSync(companyId)(dispatch),
      getPlantAppSync: (plantId) => getPlantAppSync(plantId)(dispatch),
      resetCompanyPlants: () => resetCompanyPlants()(dispatch),
    }),
    [dispatch]
  );
};
