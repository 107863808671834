import { useEffect, useState } from "react";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";


import {
  Button,
} from "@mui/material";
import { useRequestLogsDialogStyles } from "./RequestLogsDialog.css";
import {
  DialogActions,
  DialogContent,
} from "@mui/material";
import { DialogWithBackdropClick } from "../../../../components/DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../../../../components/StyledDialogTitle/StyledDialogTitle";


interface RequestLogsDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (value) => void;
}

const RequestLogsDialog = ({
  open,
  onClose,
  onSave,
}: RequestLogsDialogProps) => {
  const { _T } = useVrsTranslationState();
  const classes = useRequestLogsDialogStyles();
  const [valueArray, setValueArray] = useState([0,0,0]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    if (open) {
      setValueArray([0,0,0]);
      setSaveDisabled(true);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnChange(e, value){
    const tempArray = valueArray;
    const isChecked = e.target.checked;
    if (e.target.value >= 0 && e.target.value < 3) {
      isChecked ? tempArray[e.target.value] = value: tempArray[e.target.value] = 0;
    }
    setValueArray(tempArray);
    setSaveDisabled(tempArray.reduce((partialSum, a) => partialSum + a, 0) < 1);
  }
  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
      >
        <>
          <StyledDialogTitle id="request-logs-dialog-title">
             <div>{_T("Request Logs")}</div>
          </StyledDialogTitle>
          <DialogContent dividers>
            <h2>{_T("Select the types of logs to request")}</h2>
            <input 
              type="checkbox" 
              value={0} 
              id="userlog" 
              name="userlog"
              onChange={(e) => handleOnChange(e, 1)} /> <label htmlFor="userlog">{_T("User File")}</label><br/>
            <input 
              type="checkbox" 
              value={1} 
              id="applicationlog" 
              name="applicationlog" 
              onChange={(e) => handleOnChange(e, 2)} /><label htmlFor="applicationlog">{_T("Application Log")}</label><br />
            <input 
              type="checkbox" 
              value={2} 
              id="coredump" 
              name="coredump"
              onChange={(e) => handleOnChange(e, 4)} /><label htmlFor="coredump">{_T("Core Dump")}</label> 
           
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveDisabled}
              onClick={() => onSave(valueArray)}
            >
              {_T("Send")}
            </Button>
            <Button variant="contained" 
            onClick={onClose}>
              {_T("Cancel")}
            </Button>
          </DialogActions>
        </>
      </DialogWithBackdropClick>
    </>
  );
};

export { RequestLogsDialog };
