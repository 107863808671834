import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { forwardRef } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
}));


const StyledIconButton = forwardRef(({ children, classes, ...props }: any, ref) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(classes.root, commonClasses.root)
    : commonClasses.root;
  return (
    <IconButton classes={{ ...classes, root: activeRootClasses }} {...props} ref={ref}>
      {children}
    </IconButton>
  );
});

StyledIconButton.displayName = 'StyledIconButton';

export default StyledIconButton


