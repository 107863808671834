import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((_: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const ActionContainer = ({ breaks, ...props }: any) => {
  const classes = useStyles();
  return <div className={classes.root} {...props} />
}

export default ActionContainer;
