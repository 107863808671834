import { useMemo } from "react";
import configApi from "../api/mockConfigApi";
import { useAppGlobalDispatch } from "../context/AppContext/AppContext";
import { ILanguageItem } from "../interfaces/User/ILanguageItem";
import * as types from "./actionTypes";

function loadConfigSuccess(config) {
  return { type: types.LOAD_CONFIG_SUCCESS, config };
}

export function loadConfig() {
  return (dispatch) => {
    return configApi
      .getConfig()
      .then((config) => {
        dispatch(loadConfigSuccess(config));
      })
      .catch((error) => {
        throw error;
      });
  };
}

function setHomeSelectionMode(payload) {
  return (dispatch) => {
    dispatch({ type: types.SET_HOME_SELECTION_MODE, payload });
  };
}

export function setDashboardTitle(title, dashboardMode = false) {
  return (dispatch) => {
    dispatch({ type: types.SET_TITLE, title });
    dispatch({ type: types.SET_DASHBOARD_MODE, dashboardMode });
  };
}

export function setDashboardMode(dashboardMode) {
  return (dispatch) => {
    dispatch({ type: types.SET_DASHBOARD_MODE, payload: dashboardMode });
  };
}

function setIsLoading(isLoading) {
  return (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: isLoading });
  };
}

export function getActionDescription(actionMode) {
  let title = "";

  switch (actionMode) {
    case types.ACTION_ADD:
      title = "Create";
      break;
    case types.ACTION_EDIT:
      title = "Edit";
      break;
    case types.ACTION_DELETE:
      title = "Delete";
      break;
    case types.ACTION_CLONE:
      title = "Clone";
      break;
    case types.ACTION_PREVIEW:
      title = "Preview";
      break;
    default:
  }
  return title;
}

export function setBarFilter(name, setFilter) {
  return function (dispatch) {
    dispatch({ type: types.SET_FILTER, payload: { name, setFilter } });
  };
}

function setLanguageItems(
  language: string,
  languageItems: Array<ILanguageItem>
) {
  return function (dispatch) {
    dispatch({
      type: types.SET_LANGUAGE_ITEMS,
      payload: { language, languageItems },
    });
  };
}

function setVrsUserLocale(locale: string) {
  return function (dispatch) {
    dispatch({
      type: types.SET_VRS_USER_LOCALE,
      payload: { locale },
    });
  };
}

function setLocale(locale: string) {
  return function (dispatch) {
    dispatch({
      type: types.SET_LOCALE,
      payload: locale,
    });
  };
}

function setPrefer24HourTimeFormat(format: boolean) {
  return function (dispatch) {
    dispatch({
      type: types.SET_PREFERRED_24_HOUR_FORMAT,
      payload: format,
    });
  };
}

function setHasCompanyAccess(hasAccess: boolean) {
  return function (dispatch) {
    dispatch({
      type: types.SET_HAS_COMPANY_ACCESS,
      payload: hasAccess,
    });
  };
}

function setHasSiteAccess(hasAccess: boolean) {
  return function (dispatch) {
    dispatch({
      type: types.SET_HAS_SITE_ACCESS,
      payload: hasAccess,
    });
  };
}

function setQueryStringChecked(value: boolean) {
  return function (dispatch) {
    dispatch({
      type: types.SET_QUERY_STRING_CHECKED,
      payload: value,
    });
  };
}

function setTrackerActionObject(value: any) {
  return function (dispatch) {
    dispatch({
      type: types.SET_TRACKER_ACTION_OBJECT,
      payload: value,
    });
  };
}

function setProfileChanged() {
  return function (dispatch) {
    dispatch({
      type: types.SET_PROFILE_CHANGED,
      payload: 1,
    });
  };
}


export function setAppCache(name, key, value) {
  return function (dispatch) {
    dispatch({ type: types.SET_CACHE, payload: { name, key, value } });
  };
}

export function setDataInitialization(name, setPageDataInitialization) {
  return function (dispatch) {
    dispatch({
      type: types.SET_DATA_INITIALIZATION,
      payload: { name, setPageDataInitialization },
    });
  };
}

export function setAppDataInitialization(name, setAppDataInitialization) {
  return function (dispatch) {
    dispatch({
      type: types.SET_APP_DATA_INITIALIZATION,
      payload: { name, setAppDataInitialization },
    });
  };
}

export function setInnerState(name, setAppInnerState) {
  return function (dispatch) {
    dispatch({
      type: types.SET_INNER_STATE,
      payload: { name, setAppInnerState },
    });
  };
}

export function resetInnerState() {
  return function (dispatch) {
    dispatch({ type: types.RESET_INNER_STATE, payload: null });
  };
}

export const useConfigActions = () => {
  const dispatch = useAppGlobalDispatch();
  return useMemo(
    () => ({
      loadConfig: () => loadConfig()(dispatch),
      setDashboardTitle: (title, dashboardMode) =>
        setDashboardTitle(title, dashboardMode)(dispatch),
      setDashboardMode: (dashboardMode) =>
        setDashboardMode(dashboardMode)(dispatch),
      setIsLoading: (isLoading) => setIsLoading(isLoading)(dispatch),
      setHomeSelectionMode: (selection) =>
        setHomeSelectionMode(selection)(dispatch),
      setBarFilter: (name, filterFn) => setBarFilter(name, filterFn)(dispatch),
      setAppCache: (name, key, value) =>
        setAppCache(name, key, value)(dispatch),
      setDataInitialization: (name, initializationFn) =>
        setDataInitialization(name, initializationFn)(dispatch),
      setAppDataInitialization: (name, initializationFn) =>
        setAppDataInitialization(name, initializationFn)(dispatch),
      setInnerState: (name, stateFn) => setInnerState(name, stateFn)(dispatch),
      resetInnerState: () => resetInnerState()(dispatch),
      setLanguageItems: (
        language: string,
        languageItems: Array<ILanguageItem>
      ) => setLanguageItems(language, languageItems)(dispatch),
      setVrsUserLocale: (locale: string) => setVrsUserLocale(locale)(dispatch),
      setLocale: (locale: string) => setLocale(locale)(dispatch),
      setPrefer24HourTimeFormat: (format: boolean) => setPrefer24HourTimeFormat(format)(dispatch),
      setHasCompanyAccess: (hasAccess: boolean) => setHasCompanyAccess(hasAccess)(dispatch),
      setHasSiteAccess: (hasAccess: boolean) => setHasSiteAccess(hasAccess)(dispatch),
      setQueryStringChecked: (value: boolean) => setQueryStringChecked(value)(dispatch),
      setTrackerActionObject: (value: any) => setTrackerActionObject(value)(dispatch),
      setProfileChanged: () => setProfileChanged()(dispatch),
    }),
    [dispatch]
  );
};
