import { getPrinterStatus } from "vccm-common/src/printerHelper";
import Moment from "moment-timezone";

import {
  APP_INITIALISED,
  APP_INITIALISED_WITH_VRS_OPERATIONS,
  DELETE_SITE_SUCCESS,
  LOAD_ANOTHER_SITE_SUCCESS,
  LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS,
  LOAD_SITE_DOWNTIME_DAY_SUMMARY_SUCCESS,
  LOAD_SITE_DOWNTIME_SUMMARY_FAILURE,
  LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS,
  LOAD_SITE_OEE_SUMMARY_FAILURE,
  LOAD_SITE_OEE_SUMMARY_SUCCESS,
  LOAD_SITE_PRINTERS_SUCCESS,
  LOAD_SITE_PRODUCT_SUMMARY_SUCCESS,
  LOAD_SITE_SUCCESS,
  LOAD_SITE_SUMMARY,
  LOGIN_SUCCESS,
  RESET_SELECTED_SITE_ID,
  RESET_SITE_DATA,
  RESTORE_USER_FROM_SESSION_SUCCESS,
  SELECT_SITE,
  SET_SINGLE_SITE,
  SET_SITE,
  SET_SITES,
  SET_SITE_ID,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_SITE_INITIALISATION,
  UPDATE_SITE_SUCCESS,
} from "../../actions/actionTypes";
import { IAppGlobalAction } from "../../interfaces/AppGlobal/IAppGlobalAction";
import SiteReducerHelpers from "../../helpers/siteReducerHelpers";
import { Logger } from "../../utilities/Logger/Logger";
import Utils from "../../utilities/utils";
import { DEFAULT_TIME_ZONE } from "../../constants/global";
import { VrsAbilities } from "../../interfaces/User/VrsAbilities";

export interface ISiteState {
  sites: Array<any>;
  selectedSiteId: string;
  siteInitialised: boolean;
  getSelectedSite: () => any;
  vrsAbilities: VrsAbilities;
}
export const initialSiteState: ISiteState = {
  sites: [],
  selectedSiteId: "",
  siteInitialised: false,
  getSelectedSite: function () {
    return this.sites.find((site) => site.id === this.selectedSiteId);
  },
  vrsAbilities: {
    vrsInternalAccessAbilities: [],
    vrsReportingAccessAbilities: [],
    vrsSuperUserAbilities: [],
    vrsExternalAbilities: [],
  },
};

export function siteReducer(
  state: ISiteState = initialSiteState,
  action: IAppGlobalAction
): ISiteState {
  switch (action.type) {
    case LOAD_SITE_SUCCESS: {
      const currentSite = state.sites.find(
        (site) => site.id === action.payload.id
      );
      if (currentSite) {
        return {
          ...state,
          selectedSiteId: currentSite.id,
          sites: state.sites.map((site) => {
            if (site.id === action.payload.id) {
              return {
                ...site,
                ...action.payload,
              };
            }
            return site;
          }),
        };
      }

      return {
        ...state,
        sites: state.sites.concat([action.payload]),
        selectedSiteId: action.payload.id,
      };
    }

    case LOAD_ANOTHER_SITE_SUCCESS: {
      const anotherSite = state.sites.find(
        (site) => site.id === action.payload.id
      );
      if (anotherSite) {
        return {
          ...state,
          sites: state.sites.map((site) => {
            if (site.id === action.payload.id) {
              return {
                ...site,
                ...action.payload,
              };
            }
            return site;
          }),
        };
      }

      return {
        ...state,
        sites: state.sites.concat([action.payload]),
      };
    }

    case RESET_SITE_DATA:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (action.payload && site.id === action.payload.id) {
            return {
              ...site,
              printers: [],
              downtimeCategories: [],
            };
          }
          return site;
        }),
      };

    case RESET_SELECTED_SITE_ID:
      return {
        ...state,
        selectedSiteId: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        selectedSiteId: action.payload.selectedSiteId,
      };
    case UPDATE_SITE_INITIALISATION:
      if (action.payload.status) {
        return {
          ...state,
          siteInitialised: action.payload.status,
          selectedSiteId: action.payload.selectedSiteId,
        };
      }

      return {
        ...state,
        siteInitialised: action.payload.status,
      };

    case RESTORE_USER_FROM_SESSION_SUCCESS:
      return {
        ...state,
        selectedSiteId: action.payload.selectedSiteId,
      };
    case APP_INITIALISED:
      return {
        ...state,
        ...action.payload,
        selectedSiteId: action.payload.selectedSiteId,
      };
    case APP_INITIALISED_WITH_VRS_OPERATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SITE_ID:
      return {
        ...state,
        selectedSiteId: action.payload,
      };
    case SET_SITES: {
      const currentSite =
        state.sites && state.selectedSiteId
          ? state.sites.find((el) => el.id === state.selectedSiteId)
          : null;
      return {
        ...state,
        sites: action.payload.map((el) => {
          if (currentSite && el.id === currentSite.id) {
            return currentSite;
          } else {
            return el.tz ? el : { ...el, tz: DEFAULT_TIME_ZONE };
          }
        }),
      };
    }
    case SET_SINGLE_SITE:
      return {
        ...state,
        sites: [
          action.payload.tz
            ? action.payload
            : { ...action.payload, tz: DEFAULT_TIME_ZONE },
        ],
      };
    case DELETE_SITE_SUCCESS:
      return {
        ...state,
        sites: state.sites.filter((el) => el.id !== action.payload),
      };
    case SELECT_SITE: {
      const site = state.sites.find(
        (site) => action.payload && site.id === action.payload
      );
      const selectedSiteId = site ? site.id : null;
      if (selectedSiteId && site) {
        const companyId = site.companyId;
        Utils.setCompanyAndPlantCookie(companyId || "0", selectedSiteId || "0");
      }
      return {
        ...state,
        sites: state.sites.map((site) => ({
          ...site,
          selected: site.id === action.payload,
        })),
        selectedSiteId,
        siteInitialised: false,
      };
    }

    case LOAD_SITE_DOWNTIME_DAY_SUMMARY_SUCCESS:
      console.log("LOAD_SITE_DOWNTIME_DAY_SUMMARY_SUCCESS", action);
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.siteId) {
            console.log("On the correct site", action);
            console.log("got data for days", action.payload.response);
            // copy to not change the immutable object
            const events = Object.assign([], action.payload.response.data);
            // this data is by day
            const dayEvents = events.map((data) => {
              console.log("hourEvent=>", data);
              const goods = {
                length: data.duration || 0,
                reasonId: data.details && data.details[0].reasonId,
                // change date from "2018-02-21T15" to "2018-02-21T15:00:00"
                timeStamp: Moment(data.dateHour, "YYYY-MM-DD-hh").toDate(),
                detailedResults: data.details || [],
              };
              console.log("Goods", goods);
              return goods;
            });

            return Object.assign({}, site, {
              downtimeDay: dayEvents,
            });
          }
          return site;
        }),
      };

    case LOAD_SITE_DOWNTIME_SUMMARY_FAILURE:
      console.log("LOAD_SITE_DOWNTIME_SUMMARY_FAILURE", action);
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.siteId) {
            console.log("On the correct site", action);

            return Object.assign({}, site, {
              downtimeReport: Object.assign(
                {},
                { error: action.payload.error }
              ),
            });
          }
          return site;
        }),
      };

    case LOAD_SITE_PRINTERS_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            return {
              ...site,
              printers: action.payload.printers
                .filter((printer) => printer.siteId === action.payload.id)
                .map((printer) =>
                  Object.assign({}, printer, {
                    status: getPrinterStatus(printer).Name,
                    description:
                      printer.modelName != null
                        ? printer.modelName
                        : printer.model != null
                        ? printer.model
                        : "",
                    title:
                      printer.friendlyName != null
                        ? printer.friendlyName
                        : printer.title != null
                        ? printer.title
                        : "",
                    printerId:
                      printer.serial != null
                        ? printer.serial
                        : printer.printerId != null
                        ? printer.printerId
                        : "",
                  })
                ),
            };
          }
          return site;
        }),
      };
    case LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            return {
              ...site,
              downtimeCategories: action.payload.downtimeCategories.map((c) =>
                c.display
                  ? c
                  : {
                      ...c,
                      display: { color: Utils.getColorFromString(c.title) },
                    }
              ),
            };
          }
          return site;
        }),
      };

    case LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.siteId) {
            let filteredDowntimeResponse;
            if (
              action.payload.interval === "w" ||
              action.payload.interval === "m"
            ) {
              filteredDowntimeResponse = {
                data: SiteReducerHelpers.filterAndSplitDowntimesForWeekOrMonth(
                  action.payload.response,
                  action.payload.shiftIds,
                  action.payload.tz,
                  action.payload.interval
                ),
              };
            } else {
              filteredDowntimeResponse = action.payload.response;
            }
            return {
              ...site,
              downtimeReport: { ...filteredDowntimeResponse },
            };
          }
          return site;
        }),
      };
    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return site;
        }),
      };
    case LOAD_SITE_OEE_SUMMARY_FAILURE:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.siteId) {
            let dataPropName = "data";

            if (
              !action.payload.intervalText ||
              action.payload.intervalText === "Hour" ||
              action.payload.intervalText === "Day"
            ) {
              if (
                action.payload.intervalText === "Hour" ||
                action.payload.interval === "h"
              ) {
                dataPropName = "oeeHour";
              }
              if (
                action.payload.intervalText === "Day" ||
                action.payload.interval === "d"
              ) {
                dataPropName = "oeeDay";
              }
            } else if (action.payload.intervalText === "Month") {
              dataPropName = "oeeMonth";
            } else if (action.payload.intervalText === "Week") {
              dataPropName = "oeeWeek";
            }

            return Object.assign({}, site, {
              [dataPropName]: { error: action.payload.error },
            });
          }
          return site;
        }),
      };

    case LOAD_SITE_OEE_SUMMARY_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.siteId) {
            const events = Object.assign([], action.payload.data.data);
            let dataPropName = "data";
            let dateName = "date";
            let dateFormat = "YYYY-MM-DD";
            let formattedEvents;

            if (
              !action.payload.intervalText ||
              action.payload.intervalText === "Hour" ||
              action.payload.intervalText === "Day"
            ) {
              if (action.payload.interval === "h") {
                dateName = "dateHour";
                dataPropName = "oeeHour";
                dateFormat = "YYYY-MM-DD-HH";
              }
              if (action.payload.interval === "d") {
                dataPropName = "oeeDay";
              }
              formattedEvents = SiteReducerHelpers.formatHourOrDayOEE(
                events,
                dateName,
                dateFormat,
                action.payload.interval,
                action.payload.tz
              );
            } else if (
              action.payload.intervalText === "Week" ||
              action.payload.intervalText === "Month"
            ) {
              if (action.payload.intervalText === "Month") {
                dataPropName = "oeeMonth";
              }
              if (action.payload.intervalText === "Week") {
                dataPropName = "oeeWeek";
              }
              formattedEvents = SiteReducerHelpers.formatWeekOrMonthOee(
                events,
                dateFormat,
                action.payload.productId,
                action.payload.shiftId,
                action.payload.shiftIds,
                action.payload.intervalText,
                action.payload.tz
              );
            }
            return {
              ...site,
              [dataPropName]: { data: formattedEvents },
            };
          }
          return site;
        }),
      };

    case LOAD_SITE_PRODUCT_SUMMARY_SUCCESS:
      return {
        ...state,
        sites: state.sites.map((site) => {
          Logger.of("App.siteReducer.LOAD_SITE_PRODUCT_SUMMARY_SUCCESS").info(
            "Success",
            action
          );
          if (site.id === action.payload.siteId) {
            // copy to not change the immutable object
            const events = Object.assign([], action.payload.data.data);
            let dateName = "date";
            let dateFormat = "YYYY-MM-DD";
            let dataPropName = "data";
            // shape the data

            switch (action.payload.interval) {
              case "h":
                dateName = "dateHour";
                dataPropName = "productHour";
                dateFormat = "YYYY-MM-DD-HH";
                break;
              case "d":
                dataPropName = "productDay";
                break;
              default:
            }
            // Sort by timeStamp
            const sortedFormattedEvents = events
              .map((e) => ({
                count: e.count || 0,
                date: e[dateName],
                timeStamp: Moment.utc(e[dateName], dateFormat).local().toDate(), // converting to local timestamp
                details: e.detailedResults || [e], // product pieces (w/slices)
                slices: e.slices, // aggregate slices (products, shifts, etc.)
              }))
              .sort((a, b) => a.timeStamp - b.timeStamp);

            return Object.assign({}, site, {
              [dataPropName]: { data: sortedFormattedEvents },
            });
          }
          return site;
        }),
      };

    case LOAD_SITE_SUMMARY:
      /*      console.log(`Site ${action.siteId} State`,state); */
      return state;

    case SET_SITE: {
      const currentSite = state.sites.find(
        (site) => site.id === action.payload
      );
      const siteIds: any = currentSite
        ? {
            selectedSiteId: currentSite.id,
          }
        : {};

      return {
        ...state,
        sites: state.sites.map((site) =>
          site.id === action.payload
            ? { ...site, selected: true }
            : { ...site, selected: false }
        ),
        ...siteIds,
      };
    }

    case UPDATE_DASHBOARD_SUCCESS: {
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            const itemToEdit =
              site.dashboards.find((s) => s.id === action.payload.id) || {};
            const updatedDashboardList = [
              ...site.dashboards.filter(
                (dashboard) => dashboard.id !== action.payload.id
              ),
              Object.assign(itemToEdit, action.payload),
            ];
            return { ...site, dashboards: updatedDashboardList };
          }
          return site;
        }),
      };
    }

    case UPDATE_DEVICE_SUCCESS: {
      return {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id === action.payload.id) {
            const itemToEdit =
              site.devices.find((s) => s.id === action.payload.id) || {};
            const updatedDeviceList = [
              ...site.devices.filter((site) => site.id !== action.payload.id),
              Object.assign(itemToEdit, action.payload),
            ];
            return Object.assign({}, site, { devices: updatedDeviceList });
          }
          return site;
        }),
      };
    }

    default: {
      return state;
    }
  }
}
