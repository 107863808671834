import { useQuery, UseQueryOptions } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';
import { TagTypeFields } from '../interfaces/Tags/TagTypeFields';

export const fetchTags = async (siteIds: string | string[], selectionSet: TagTypeFields[]) => {
  if (Array.isArray(siteIds)) {
    const tagRequests = siteIds.map(siteId => VrsTagsApi.getTags({ SiteId: Number(siteId) }, selectionSet));
    const tagsResponses = await Promise.all(tagRequests);
    return tagsResponses.flat();
  } else {
    return await VrsTagsApi.getTags({ SiteId: Number(siteIds) }, selectionSet);
  }
};

/**
 * Custom hook to fetch tags for a single site using react-query.
 *
 * @param {string} siteId - The ID of the site for which to fetch tags.
 * @param {TagTypeFields[]} selectionSet - The fields to select for each tag.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the tags data and query status.
 */
const useFetchTags = (siteId: string, selectionSet: TagTypeFields[], options?: UseQueryOptions<unknown, unknown, unknown, [string, string]>) => {
  return useQuery(['tags', siteId], () => fetchTags(siteId, selectionSet), {
    enabled: Boolean(siteId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

/**
 * Custom hook to fetch tags for multiple sites using react-query.
 *
 * @param {string[]} siteIds - The IDs of the sites for which to fetch tags.
 * @param {TagTypeFields[]} selectionSet - The fields to select for each tag.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the tags data and query status.
 */
const useFetchSiteIdsTags = (siteIds: string[], selectionSet: TagTypeFields[], options?: UseQueryOptions<unknown, unknown, unknown, [string, string[]]>) => {
  return useQuery(['tags', siteIds], () => fetchTags(siteIds, selectionSet), {
    enabled: siteIds.length > 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export { useFetchTags, useFetchSiteIdsTags };
