import { Grid } from "@mui/material";
import { wholeRow } from "../../../constants/global";
import StyledGridCell from "../StyledGridCell/StyledGridCell";
import { useEmptyItemStyles } from "./EmptyItem.css";
import { useEffect, useState } from "react";

const EmptyItem = ({ text }: { text: string }) => {
  const classes = useEmptyItemStyles();
  const [activeText, setActiveText] = useState("");
  useEffect(() => {
    if (text) {
      setTimeout(() => {
        setActiveText(text);
      }, 1000);
    } else {
      setActiveText(text);
    }
  }, [text]);

  return (
    <Grid container>
      <StyledGridCell {...wholeRow}>
        <div className={classes.noItemYet} style={{ textAlign: "center" }}>
          <b>{activeText}</b>
        </div>
      </StyledGridCell>
    </Grid>
  );
};

export { EmptyItem };
