import 'moment';
import momentTimeZone from "moment-timezone";
import { AbilityBuilder } from "@casl/ability";
import { authModules, claims } from "vccm-common";
import ability from "../ability";
import UserHelper from "../helpers/UserHelper";
import DowntimeApi from "../api/prodDowntimeApi";
import ProductApi from "../api/prodProductApi";
import ShiftApi from "../api/prodShiftApi";
import SiteApi from "../api/prodSiteApi";
import LineApi from "../api/prodLineApi";
import {
  loadLinesInitiated,
  loadLinesOverviewInitiated,
  loadLinesOverviewSuccess,
  loadLinesSuccess,
} from "./lineActions";
import { loadShiftsInitiated, loadShiftsSuccess } from "./shiftActions";
import { extendMoment } from "moment-range";
import {
  CREATE_SITE_SUCCESS,
  DELETE_SITE_SUCCESS,
  LOAD_JOB_SUCCESS,
  LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS,
  LOAD_SITE_DOWNTIME_SUMMARY_FAILURE,
  LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS,
  LOAD_SITE_OEE_SUMMARY_FAILURE,
  LOAD_SITE_OEE_SUMMARY_SUCCESS,
  LOAD_SITE_PRINTERS_SUCCESS,
  LOAD_SITE_SUCCESS,
  LOAD_SITE_SUMMARY,
  MIGRATE_SITES_SUCCESS,
  RESET_SITE_DATA,
  SELECT_SITE,
  SET_SITE,
  SET_SITES,
  SET_SITE_ID,
  UPDATE_SITE_INITIALISATION,
  UPDATE_SITE_SUCCESS,
  DESIGN_MODULE_UPDATE_SUCCESS,
  DELETE_UNUSED_ROLES_SUCCESS,
  LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED,
  LOAD_SITE_PRINTERS_INITIATED,
  LOAD_MULTI_SITES_INITIATED,
  LOAD_MULTI_SITES_FAILED,
  LOAD_MULTI_SITES_SUCCESS,
  RESET_MULTI_SITE_DATA,
  LOAD_ANOTHER_SITE_SUCCESS,
  RESET_SELECTED_SITE_ID,
} from "./actionTypes";
import { Logger } from "../utilities/Logger/Logger";
import { loadProductsInitiated, loadProductsSuccess } from "./productActions";
import { useAppGlobalDispatch, useAppGlobalState } from "../context/AppContext/AppContext";
import { useMemo } from "react";
import Utils from "../utilities/utils";
import { TOTAL_TRIAL_FOR_SITE_LOAD } from "../constants/global";
import { getUserIdAttributes } from "../libs/authLib";
import { useConfigActions } from "./configActions";

const moment = extendMoment(momentTimeZone as any);

export function createSite(site) {
  return function (dispatch) {
    return SiteApi.createSite(site)
      .then((site) => {
        console.log("Creating Site for self", site);
        if (site !== undefined) {
          console.log("Creating Site for self success", site);
          dispatch(createSiteSuccess(site));
          console.log("Returning site=>", site);
          return site;
        }

        throw Error(site);
      })
      .catch((error) => {
        console.log("siteActions.createSite", error);
        throw error;
      });
  };
}

export function createSiteSuccess(site) {
  return { type: CREATE_SITE_SUCCESS, site };
}

export function deleteSite(id) {
  return function (dispatch) {
    return SiteApi.deleteSite(id)
      .then(dispatch(deleteSiteSuccess(id)))
      .catch((error) => {
        console.log("siteActions.deleteSite error", error);
        /*      throw(error); */
      });
  };
}

export function deleteSiteSuccess(payload) {
  return { type: DELETE_SITE_SUCCESS, payload };
}

export function loadJob(jobId) {
  return (dispatch) => {
    return SiteApi.loadJob(jobId)
      .then((jobInfo) => {
        dispatch(loadJobSuccess(jobInfo));
        return jobInfo;
      })
      .catch((error) => {
        console.log("siteActions.loadJob error", error);
        throw error;
      });
  };
}

export function getJob(id, request) {
  return (_) => {
    return SiteApi.getJob(id, request)
      .then((jobInfo) => {
        console.log("siteActions.loadJob jobInfo", jobInfo);
        return jobInfo;
      })
      .catch((error) => {
        //
        console.log("siteActions.loadJob error", error);
        throw error;
      });
  };
}

export function loadJobSuccess(jobId) {
  return { type: LOAD_JOB_SUCCESS, jobId };
}

export function loadSite(siteId, updateStore) {
  return (dispatch) => {
    return SiteApi.getSite(siteId)
      .then((site) => {
        if (updateStore) {
          dispatch(loadSiteSuccess({ modules: { values: [] }, ...site }));
        }
        return { modules: { values: [] }, ...site };
      })
      .catch((error) => {
        console.log("siteActions.loadSite error", error);
        throw error;
      });
  };
}

export function loadAnotherSite(anotherSiteId) {
  return (dispatch) => {
    return SiteApi.getSite(anotherSiteId)
      .then((site) => {
        dispatch(loadAnotherSiteSuccess({ modules: { values: [] }, ...site }));
        return { modules: { values: [] }, ...site };
      })
      .catch((error) => {
        console.log("siteActions.loadAnotherSite error", error);
        throw error;
      });
  };
}

export function loadSingleSite(siteId) {
  return (dispatch) => {
    return SiteApi.getSite(siteId)
      .then((site) => {
        if (site) {
          dispatch(loadSiteSuccess({ modules: { values: [] }, ...site }));
        }
        return { modules: { values: [] }, ...site };
      })
      .catch((error) => {
        console.log("siteActions.loadSite error", error);
        /*      throw(error); */
      });
  };
}

export function migrateSites(syncType: string) {
  return (dispatch) => {
    return SiteApi.migrateSites(syncType)
      .then((result) => {
        dispatch(migrateSitesSuccess(result));
        return result;
      })
      .catch((error) => {
        console.log("siteActions.migrateSites error", error);
      });
  };
}

export function loadSiteSuccess(payload) {
  return { type: LOAD_SITE_SUCCESS, payload };
}

export function loadAnotherSiteSuccess(payload) {
  return { type: LOAD_ANOTHER_SITE_SUCCESS, payload };
}

export function migrateSitesSuccess(payload) {
  return { type: MIGRATE_SITES_SUCCESS, payload };
}

export function loadSites() {
  return (dispatch) => {
    let tryIndex = 1;
    const totalTrial = TOTAL_TRIAL_FOR_SITE_LOAD;
    dispatch(loadMultiSitesInitiated({}));
    const getSites = () =>
      SiteApi.getAllSites()
        .then((sites) => {
          dispatch(loadSitesSuccess(sites));
          dispatch(loadMultiSitesSuccess(sites));
          return sites;
        })
        .catch((error) => {
          console.log("load site error=", error);
          if (tryIndex < totalTrial) {
            tryIndex++;
            return getSites();
          } else {
            console.log("siteActions.loadSites error", error);
            loadMultiSitesFailed({});
          }
        });

    return getSites();
  };
}

export function loadSitesSuccess(payload) {
  return { type: SET_SITES, payload };
}

export function loadMultiSitesInitiated(payload) {
  return { type: LOAD_MULTI_SITES_INITIATED, payload };
}

export function loadMultiSitesSuccess(payload) {
  return { type: LOAD_MULTI_SITES_SUCCESS, payload };
}

export function loadMultiSitesFailed(payload) {
  return { type: LOAD_MULTI_SITES_FAILED, payload };
}

export function loadSiteDowntimeReasonsSuccess(payload) {
  return { type: LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS, payload };
}

export function loadSiteDowntimeReasonsInitiated(payload) {
  return { type: LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED, payload };
}

function loadSiteDowntimeSummary(
  endDt,
  interval,
  lineIds,
  shiftIds,
  siteId,
  startDt,
  includeMicrostops,
  includePrinterDisconnected,
  tz = null
) {
  const queryStart = moment(startDt);
  const queryEnd = moment(endDt);

  return (dispatch) => {
    const diff = queryEnd.diff(queryStart, "d");
    if (diff > 0) {
      // more than one day's worth of queries
      const queryRanges = getRanges(queryStart, queryEnd, interval, false);
      // execute all the API calls
      return Promise.all(
        queryRanges.map((qr: any, index) =>
          SiteApi.getSiteDowntimeSummary(
            qr.end,
            interval,
            lineIds,
            shiftIds,
            siteId,
            qr.start,
            includeMicrostops,
            includePrinterDisconnected,
            index === 0 ? "during" : "overlap"
          )
        )
      ).then((results) => {

        if (interval !== "h") {
          results.forEach((result)=> {
            const summedData = {
              count: 0,
              duration: 0,
              slices: [],
              details: [],
              startDt: "",
            };
            if (result.data && result.data.length > 0) {
              //sum the results
              for(let i = 0; i < result.data.length; i++){
                summedData.count += result.data[i].count;
                summedData.duration += result.data[i].duration;
                summedData.slices = (summedData.slices ||[]).concat(result.data[i].slices);
                summedData.details = (summedData.details || []).concat(result.data[i].details);
                summedData.startDt = summedData.startDt === ""? result.data[i].startDt: summedData.startDt;
              }
              result.data = new Array(summedData);
            }
          });          
        }


        // concatenate all the results
        const response = results.reduce((r, agg) => {
          if (results[0] && results[0].data)
            return { data: (agg.data || []).concat(r.data) };
          else return results.flat();
        });
        dispatch(
          loadSiteDowntimeSummarySuccess({
            siteId,
            shiftIds,
            interval,
            response,
            tz,
          })
        );
      });
    }
    return SiteApi.getSiteDowntimeSummary(
      endDt,
      interval,
      lineIds,
      shiftIds,
      siteId,
      startDt,
      includeMicrostops,
      includePrinterDisconnected,
      "during"
    )
      .then((response) => {
        dispatch(
          loadSiteDowntimeSummarySuccess({
            siteId,
            shiftIds,
            interval,
            response,
            tz,
          })
        );
      })
      .catch((error) => {
        console.log("siteActions.loadSiteDowntimeSummary error", error);
        dispatch(loadSiteDowntimeSummaryFailure({ siteId, error }));
      });
  };
}

export function loadSiteDowntimeSummarySuccess(payload) {
  return { type: LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS, payload };
}

export function loadSiteDowntimeSummaryFailure(payload) {
  return { type: LOAD_SITE_DOWNTIME_SUMMARY_FAILURE, payload };
}

export function loadSitePrinters(siteId) {
  return (dispatch) => {
    dispatch(loadSitePrintersInitiated({ id: siteId }));
    return SiteApi.getSitePrinters(siteId)
      .then((printers) => {
        dispatch(loadSitePrintersSuccess({ id: siteId, printers }));
      })
      .catch((error) => {
        Logger.of("App.siteActions.loadSitePrinters").error(
          "siteActions.loadSitePrinters error",
          error
        );
        /*     throw(error); */
      });
  };
}

export function loadSitePrintersSuccess(payload) {
  return { type: LOAD_SITE_PRINTERS_SUCCESS, payload };
}

export function loadSitePrintersInitiated(payload) {
  return { type: LOAD_SITE_PRINTERS_INITIATED, payload };
}

export function loadSiteOeeSummarySuccess(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_SUCCESS, payload };
}

export function loadSiteOeeSummaryFailure(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_FAILURE, payload };
}

function getRanges(
  queryStart,
  queryEnd,
  interval,
  dayFormat,
  exclusive = false
) {
  const range = moment.range(queryStart, queryEnd);
  const chunks = Array.from(range.by("day"));
  // convert the chunks into start / end pairs formatted either ISO or custom
  const isoFormat = !(interval === "d" && typeof dayFormat !== "undefined");
  const queryRanges = chunks.map((c) => ({
    start: isoFormat
      ? c.utc().toISOString()
      : c.clone().startOf(interval).format(dayFormat),
    end: isoFormat
      ? c.clone().add(1, "d").subtract(1, "ms").utc().toISOString()
      : c
          .clone()
          .add(exclusive ? 0 : 1, "d")
          .startOf(interval)
          .format(dayFormat),
  }));
  // set last chunk to original end
  queryRanges[queryRanges.length - 1].end = isoFormat
    ? queryEnd.toISOString()
    : queryEnd.endOf(interval).format(dayFormat);
  return queryRanges;
}

export function loadSiteOeeSummary(
  endDt,
  interval,
  lineIds,
  shiftIds,
  siteId,
  startDt,
  intervalText = "",
  tz,
  productId,
  useDayFormat
) {
  let raw = false;
  if (intervalText === "Week" || intervalText === "Month") {
    raw = true;
  }
  let queryStart: any = moment(startDt);
  let queryEnd: any = moment(endDt);
  const dayFormat = useDayFormat === false ? undefined : "YYYY-MM-DD";

  return (dispatch) => {
    const diff = queryEnd.diff(queryStart, "d");
    if (diff > 0) {
      // more than one day's worth of queries
      const queryRanges = getRanges(
        queryStart,
        queryEnd,
        interval,
        dayFormat,
        true
      );
      // execute all the API calls
      return Promise.all(
        queryRanges.map((qr) =>
          SiteApi.getSiteOeeSummary({
            endDt: qr.end,
            interval,
            lineIds,
            shiftIds,
            siteId,
            startDt: qr.start,
            raw,
          })
        )
      ).then((results) => {
        // concatenate all the results
        const data = results.reduce((r, agg) => ({
          data: agg.data.concat(r.data),
        }));
        dispatch(
          loadSiteOeeSummarySuccess({
            siteId,
            interval,
            data,
            shiftIds,
            intervalText,
            tz,
            productId,
          })
        );
      });
    }

    // if it is in days, then ignore the time part (VCCM-1851)
    if (interval === "d") {
      const dayFormat = "YYYY-MM-DD";
      queryStart = moment(startDt).startOf(interval).format(dayFormat);
      queryEnd = moment(endDt).startOf(interval).format(dayFormat);
    }
    return SiteApi.getSiteOeeSummary({
      endDt: queryEnd,
      interval,
      lineIds,
      shiftIds,
      siteId,
      startDt: queryStart,
      raw,
      productId,
    })
      .then((data) => {
        dispatch(
          loadSiteOeeSummarySuccess({
            siteId,
            interval,
            data,
            shiftIds,
            intervalText,
            tz,
          })
        );
      })
      .catch((error) => {
        console.log("siteActions.loadSiteOeeSummary error", error);
        dispatch(loadSiteOeeSummaryFailure({ error, siteId, intervalText }));
      });
  };
}

export function loadSiteProductSummarySuccess(payload) {
  return { type: LOAD_SITE_OEE_SUMMARY_SUCCESS, payload };
}

export function loadSiteProductSummary(
  endDt,
  interval,
  lineIds,
  shiftId,
  siteId,
  startDt,
  intervalText = "",
  productId,
  tz
) {
  const shiftIds = shiftId === "" || shiftId === "all" ? null : [shiftId];
  return loadSiteOeeSummary(
    endDt,
    interval,
    lineIds,
    shiftIds,
    siteId,
    startDt,
    intervalText,
    tz,
    productId,
    interval === "d"
  );
}

export function loadSiteSummary(siteId) {
  return { type: LOAD_SITE_SUMMARY, siteId };
}

export function setSiteId(siteId) {
  return (dispatch) => {
    dispatch({ type: SET_SITE_ID, payload: siteId });
  };
}

/**
 * Format and apply all abilities based on token data
 * @param {string} siteId
 * @param {*} data from token
 */
export function setAbilitiesForSite(siteId, data, configActions) {
  // build API access abilities
  let apiAccessAbilities: any = [];
  try {
    apiAccessAbilities = Utils.deserializeClaimsForAuthModule(
      data.authClaims,
      authModules.API_AUTH_MODULE
    );
    Logger.of("App.siteActions.setSite").info(
      "Api claims unpacked",
      apiAccessAbilities
    );
  } catch (e) {
    Logger.of("App.siteActions.setSite").error(
      "Failed to parse auth claims",
      e
    );
  }

  // build vccm claims from authClaims property
  let authClaimObj: any;
  try {
    authClaimObj = claims.deserializeClaims(data.authClaims);
    Logger.of("App.siteActions.setSite").info(
      "Auth claims unpacked",
      authClaimObj
    );
  } catch (e) {
    Logger.of("App.siteActions.setSite").error(
      "Failed to parse user claims",
      e
    );
  }

  const userClaimObj: any = Object.entries(authClaimObj).reduce(
    (obj: any, [siteId, modClaims]) => ({
      ...obj,
      // @ts-ignore
      [siteId]: modClaims[authModules.VCCM_AUTH_MODULE],
    }),
    {}
  );

  if (userClaimObj && userClaimObj[siteId] instanceof Error) {
    Logger.of("App.siteActions.setSite").error(userClaimObj[siteId]);
    userClaimObj[siteId] = [];
  }

  const userVccmSites =
    userClaimObj &&
    Object.entries(userClaimObj).reduce((agg: any, [key, val]) => {
      if (val !== false) return [...agg, key];
      return agg;
    }, []);

  const userSites = new Set(userVccmSites);
  Logger.of("App.siteActions.setSite").info("vccm auth", userSites);
  const abilitySet = new Set(
    (userClaimObj[siteId] || []).concat(userClaimObj["*"] || [])
  );
  const formattedAbilities = UserHelper.formatAbilities(Array.from(abilitySet));
  Logger.of("App.siteActions.setSite").info(
    `formatted abilities for site ${siteId}`,
    formattedAbilities
  );

  // generate foreign auth module abilities
  const multipleSites =
    userVccmSites.length > 0 ||
    Object.entries(authClaimObj).filter(
      ([_, v]: Array<any>) => v.vrs && v.vrs !== false
    ).length > 0;

  const sitesAvailable = AbilityBuilder.define((allow, forbid) => {
    if (multipleSites) allow("view", "sites");
    else {
      forbid("view", "sites");
    }
  });

  const foreignAbilities = authModules.foreign.reduce((abilities, mod) => {
    // get mod claims
    const modClaims =
      authClaimObj && authClaimObj[siteId] && authClaimObj[siteId][mod];
    if (!modClaims) return abilities;

    return [...abilities, ...UserHelper.formatAbilities(modClaims || [], mod)];
  }, []);
  Logger.of("App.siteActions.setSite").info(
    `foreignAbilities`,
    foreignAbilities
  );

  // construct basic abilities
  const saveSiteDetails = AbilityBuilder.define((allow, forbid) => {
    if (ability.can("edit", "siteDef") || ability.can("edit", "siteConfig")) {
      allow("click", "saveSiteDetails");
    } else {
      forbid("click", "saveSiteDetails");
    }
  });
  const siteDetails = AbilityBuilder.define((allow, forbid) => {
    if (
      ability.can("view", "siteDef") ||
      saveSiteDetails.can("click", "saveSiteDetails")
    ) {
      allow("click", "siteDetails");
    } else {
      forbid("click", "siteDetails");
    }
  });

  // apply all abilities
  const abilityArray = [
    ...formattedAbilities,
    ...sitesAvailable.rules,
    ...siteDetails.rules,
    ...saveSiteDetails.rules,
    ...apiAccessAbilities,
    ...foreignAbilities,
    ...(formattedAbilities.length > 0
      ? [{ actions: "vccm", subject: "authModule" }]
      : []),
  ];
  Logger.of("App.siteActions.setSite").info(`Ability array`, abilityArray);
  ability.update(abilityArray);
  configActions.setDataInitialization("abilityData", (state) => ({
    ...state,
    initialised: true,
    loaded: true,
  }));
}

async function dispatchSiteInit(siteId, dispatch) {
  const promises: Array<any> = [];
  return Promise.all(promises).then((results) => {
    dispatch({
      type: UPDATE_SITE_INITIALISATION,
      payload: {
        status: true,
        selectedSiteId: siteId,
      },
    });
    return results;
  });
}

function loadSiteData(siteId, dataKeys, isAdmin) {
  return async (dispatch) => {
    const promises: Array<any> = [];

    if (dataKeys.shiftData && (isAdmin || UserHelper.Can("view", "schedule"))) {
      dispatch(loadShiftsInitiated({ id: siteId }));
      promises.push(
        ShiftApi.getShifts(siteId)
          .then((shifts) => {
            dispatch(loadShiftsSuccess(shifts));
            return shifts;
          })
          .catch((_) => {})
      );
    }

    if (dataKeys.printerData && (isAdmin || UserHelper.Can("view", "siteDef"))) {
      dispatch(loadSitePrintersInitiated({ id: siteId }));
      promises.push(
        SiteApi.getSitePrinters(siteId)
          .then((printers) => {
            dispatch(loadSitePrintersSuccess({ id: siteId, printers }));
            return printers;
          })
          .catch((_) => {})
      );
    }

    if (dataKeys.productData && (isAdmin || UserHelper.Can("view", "product"))) {
      dispatch(loadProductsInitiated({ id: siteId }));
      promises.push(
        ProductApi.getProducts(siteId)
          .then((products) => {
            dispatch(loadProductsSuccess(products));
            return products;
          })
          .catch((_) => {})
      );
    }
    if (dataKeys.lineData && (isAdmin || UserHelper.Can("view", "line"))) {
      dispatch(loadLinesInitiated({ id: siteId }));
      promises.push(
        LineApi.getAllLines(siteId)
          .then((lines) => {
            dispatch(loadLinesSuccess({ id: siteId, lines }));
            if (dataKeys.lineOverviewData && lines && lines.length > 0) {
              loadLinesOverviewInitiated({ id: siteId });
              return LineApi.getLinesOverview(
                siteId,
                lines.map((l) => l.id),
                moment().toISOString()
              )
                .then((response) => {
                  const results = Array.isArray(response)
                    ? response.reduce((acc, val) => ({ ...acc, ...val }), {})
                    : response;

                  dispatch(
                    loadLinesOverviewSuccess({ id: siteId, response: results })
                  );
                })
                .catch((_) => {});
            }
            return lines;
          })
          .catch((_) => {})
      );
    }

    if (dataKeys.downtimeData && (isAdmin || UserHelper.Can("view", "downtimeReason"))) {
      dispatch(loadSiteDowntimeReasonsInitiated({ id: siteId }));
      promises.push(
        DowntimeApi.getDowntimeReasons(siteId)
          .then((downtimeCategories) => {
            dispatch(
              loadSiteDowntimeReasonsSuccess({ id: siteId, downtimeCategories })
            );
          })
          .catch((_) => {})
      );
    }

    return Promise.all(promises);
  };
}

export function resetSelectedSiteId() {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_SITE_ID,
      payload: {
        status: false,
        selectedSiteId: "",
      },
    });
  };
}

export function setSite(siteId, currentUser) {
  return (dispatch, configActions) => {
    dispatch({
      type: UPDATE_SITE_INITIALISATION,
      payload: {
        status: false,
        selectedSiteId: siteId,
      },
    });
    dispatch({ type: SET_SITE, payload: siteId });
    Utils.setPlantCookie(siteId || "0");

    // get the abilities for the current user.
    if (currentUser) {
      return getUserIdAttributes(currentUser)
        .then((tokenDecoded) => {
          Logger.of("App.siteActions.setSite").info(
            "Token decoded during this feature",
            tokenDecoded
          );
          setAbilitiesForSite(siteId, tokenDecoded, configActions);
          return dispatchSiteInit(siteId, dispatch);
        })
        .catch((err) => {
          // Prompt the user to reauthenticate by hand...?
          Logger.of("App.siteActions.setSite").warn(
            "Error getting abilities",
            err
          );
          return null;
        });
    }

    return Promise.resolve(null);
  };
}

function saveSite(site, isEditMode) {
  return (dispatch) => {
    if (isEditMode) {
      return SiteApi.updateSite(site)
        .then((site) => {
          Logger.of("Updating site").info(`site updated`, site);
          dispatch(updateSiteSuccess(site));
          return site;
        })
        .catch((error) => {
          Logger.of("Save site error").warn(`update site error`, error);
          throw error;
        });
    }
    return SiteApi.saveSite(site)
      .then((site) => {
        Logger.of("App.siteActions.saveSite").info(`site saved`, site);
        dispatch(createSiteSuccess(site));
        return site;
      })
      .catch((error) => {
        Logger.of("App.siteActions.saveSite").warn(`save site error`, error);
        throw error;
      });
  };
}

function updateSiteSuccess(payload) {
  return { type: UPDATE_SITE_SUCCESS, payload };
}

function generateApiKey(site: any) {
  return (dispatch) => {
    return SiteApi.generateApiKey(site)
      .then((updatedSite) => {
        if (updatedSite) {
          dispatch({ type: UPDATE_SITE_SUCCESS, payload: updatedSite });
          return updatedSite;
        } else {
          throw new Error("There is error while updating the site!");
        }
      })
      .catch((error) => {
        Logger.of("App.siteActions.generateApiKey").error(
          "siteActions.generateApiKey error",
          error
        );
        throw error;
      });
  };
}

function selectSite(siteId) {
  return (dispatch) => {
    dispatch({ type: SELECT_SITE, payload: siteId });
  };
}

function resetSiteData(siteId) {
  return (dispatch) => {
    dispatch({ type: RESET_SITE_DATA, payload: { id: siteId } });
  };
}

function resetMultiSiteData() {
  return (dispatch) => {
    dispatch({ type: RESET_MULTI_SITE_DATA, payload: {} });
  };
}

function createMissingDesignModule() {
  return (dispatch) => {
    return SiteApi.createMissingDesignModule()
      .then((result) => {
        dispatch({ type: DESIGN_MODULE_UPDATE_SUCCESS });
        return result;
      })
      .catch((error) => {
        console.log("siteActions.createMissingDesignModule error", error);
        throw error;
      });
  };
}

function deleteUnusedRoles(OrganisationalUnitNames) {
  return (dispatch) => {
    return SiteApi.deleteUnusedRoles(OrganisationalUnitNames)
      .then((result) => {
        dispatch({ type: DELETE_UNUSED_ROLES_SUCCESS });
        return result;
      })
      .catch((error) => {
        console.log("siteActions.deleteUnusedRoles error", error);
        throw error;
      });
  };
}

export const useSiteActions = () => {
  const dispatch = useAppGlobalDispatch();

  const configActions = useConfigActions();
  const { isAdmin } = useAppGlobalState();

  return useMemo(
    () => ({
      resetSiteData: (siteId) => resetSiteData(siteId)(dispatch),
      resetMultiSiteData: () => resetMultiSiteData()(dispatch),
      createSite: (site) => createSite(site)(dispatch),
      deleteSite: (id) => deleteSite(id)(dispatch),
      loadJob: (jobId) => loadJob(jobId)(dispatch),
      getJob: (id, request) => getJob(id, request)(dispatch),
      loadSite: (siteId, updateStore = false) =>
        loadSite(siteId, updateStore)(dispatch),
      loadAnotherSite: (siteId) => loadAnotherSite(siteId)(dispatch),
      loadSingleSite: (siteId) => loadSingleSite(siteId)(dispatch),
      migrateSites: (syncType) => migrateSites(syncType)(dispatch),
      loadSites: () => loadSites()(dispatch),
      loadSiteDowntimeSummary: (
        endDt,
        interval,
        lineIds,
        shiftIds,
        siteId,
        startDt,
        includeMicrostops,
        includePrinterDisconnected,
        tz = null
      ) =>
        loadSiteDowntimeSummary(
          endDt,
          interval,
          lineIds,
          shiftIds,
          siteId,
          startDt,
          includeMicrostops,
          includePrinterDisconnected,
          tz
        )(dispatch),
      loadSitePrinters: (siteId) => loadSitePrinters(siteId)(dispatch),
      loadSiteProductSummary: (
        endDt,
        interval,
        lineIds,
        shiftId,
        siteId,
        startDt,
        intervalText = "",
        productId,
        tz
      ) =>
        loadSiteProductSummary(
          endDt,
          interval,
          lineIds,
          shiftId,
          siteId,
          startDt,
          intervalText,
          productId,
          tz
        )(dispatch),
      loadSiteOeeSummary: (
        endDt,
        interval,
        lineIds,
        shiftIds,
        siteId,
        startDt,
        intervalText = "",
        tz,
        productId,
        useDayFormat
      ) =>
        loadSiteOeeSummary(
          endDt,
          interval,
          lineIds,
          shiftIds,
          siteId,
          startDt,
          intervalText,
          tz,
          productId,
          useDayFormat
        )(dispatch),
      saveSite: (site, isEditMode) => saveSite(site, isEditMode)(dispatch),
      setSite: (siteId, currentUser) =>
        setSite(siteId, currentUser)(dispatch, configActions),
      setSiteId: (siteId) => setSiteId(siteId)(dispatch),
      resetSelectedSiteId: () => resetSelectedSiteId()(dispatch),
      selectSite: (siteId) => selectSite(siteId)(dispatch),
      loadSiteData: (siteId, dataKeys) =>
        loadSiteData(siteId, dataKeys, isAdmin)(dispatch),
      generateApiKey: (site) => generateApiKey(site)(dispatch),
      createMissingDesignModule: () => createMissingDesignModule()(dispatch),
      deleteUnusedRoles: (OrganisationalUnitNames) =>
        deleteUnusedRoles(OrganisationalUnitNames)(dispatch),
    }),
    [dispatch, configActions,isAdmin]
  );
};
