//

import Util from '../../utilities/utils';
import Moment from 'moment-timezone';
import DateHelper from '../DateHelper/DateHelper';

class SchedulerHelper {
  static formatTimeInStateEvent(event) {
    const eventParsed = JSON.parse(event);
    const eventStart = eventParsed.timerange[0];
    const eventEnd = eventParsed.timerange[1];
    const diff = Moment(eventEnd)
      .diff(eventStart);
    const durationText = DateHelper.humanizeDuration(diff);
    const eventDetail = {
      diff,
      endDT: eventEnd,
      id: eventParsed.data.id,
      length: durationText,
      reasonId: eventParsed.data.reasonId,
      shiftId: eventParsed.data.shiftId,
      startDT: eventStart,
      status: eventParsed.data.status,
      type: eventParsed.data.type,
      typeId: eventParsed.data.typeId,
    };
    return { eventDetail };
  }

  static getEventsByIds(events, ids: Array<any> = []) {
    if (!ids || ids.length < 1) return undefined;
    return events.filter(e => ids.includes(e.id));
  }

  static getCurrentAndPastEventsForLineId(events, id) {
    if (!id || !events) return undefined;
    const currentAndPastSchedule1Events = events.filter(scheduleEvent => (scheduleEvent.typeId === 1 || scheduleEvent.typeId === '1') && scheduleEvent.start <= new Date());
    return currentAndPastSchedule1Events.filter(e => e.lineIds && e.lineIds.indexOf(id) > -1).sort((a, b) => b.start - a.start);
  }

  static getEventTypeById(id) {
    if (!id) return undefined;
    switch (id) {
      case 1:
        return 'running';
      case 2:
        return 'maintenance';
      case 3:
        return 'break';
      case 4:
        return 'autostandby';
      case 5:
        return 'remaining';
      default:
        return undefined;
    }
  }

  static getShiftById(shifts, id) {
    if (!shifts) return undefined;
    return shifts.find(item => item.id === id || item.shiftId === id);
  }

  static getEventsByLineIds(events, scheduleType, LineIds: Array<any> = []) {
    if (!events) {
      return [];
    }
    let returnArray: Array<any> = [];
    if (!LineIds || LineIds.length < 1) {
      returnArray = events.filter(e => e.typeId === scheduleType.value);
    } else {
      returnArray = events.filter(e => e.typeId === scheduleType.value && e.lineIds.some(l => LineIds.indexOf(l) >= -1));
    }
    return returnArray.sort(Util.sortByTitle);
  }
}

export const scheduleTypes = [
  { text: 'Shift', value: 1, color: '#56ca85' },
  { text: 'Maintenance', value: 2, color: '#f8a398' },
  { text: 'Break', value: 3, color: '#cac33b' },
];

export default SchedulerHelper;
