import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.125, 1.25),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.125, 0.125),
    },
    alignItems: "center",
  },
}));

const BarStyledGrid = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(commonClasses.root, classes.root)
    : commonClasses.root;
  return (
    <Grid
      container
      classes={{ ...classes, root: activeRootClasses }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default BarStyledGrid;
