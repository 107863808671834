import { Dispatch, SetStateAction, useState } from "react";
import { Divider, TextField, Button } from "@mui/material";
import classNames from "classnames";
import { PasswordResetDialog } from "../PasswordResetDialog/PasswordResetDialog";
import { useLoginStyles } from "./Login.css";
import LoginHeader from "./LoginHeader";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface EnterUsernameProps {
  isLoading;
  username;
  password;
  setPassword: (password) => void;
  onBack: () => void;
  loginWithUserDetails: () => void;
  setAuthError: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<boolean>>;
}

const LoginEnterPassword = ({
  isLoading,
  username,
  password,
  setPassword,
  onBack,
  loginWithUserDetails,
  setAuthError,
  setError,
}: EnterUsernameProps) => {
  const classes = useLoginStyles();
  const { _T } = useVrsTranslationState();
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  const onClosePasswordResetDialog = () => {
    setShowPasswordResetModal(false);
  };

  const isValid = () => {
    return username && password ? true : false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      loginWithUserDetails();
    }
  };

  const resetPassword = (value) => {
    setShowPasswordResetModal(value);
    if (value) {
      setPassword("");
      setError(false);
      setAuthError("");
    }
  };

  return (
    <>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <LoginHeader />
        <div className={classes.usernameText}>{username}</div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={_T("Password")}
          type="password"
          id="password"
          data-testid="password"
          autoComplete="current-password"
          autoFocus
          value={password}
          className={classNames(classes.password, "password-txt")}
          disabled={isLoading}
          onChange={(event) => {
            const value = event.target.value;
            setPassword(() => value);
          }}
        />
        <div className={classes.loginBtn}>
          <Button
            id="login-password-signin-btn"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid()}
            data-testid="signin-btn"
            className={classes.submit}
          >
            {_T("Login")}
          </Button>
        </div>
        <div className={classes.backBtn}>
          <Button
            id="login-password-back-btn"
            fullWidth
            variant="contained"
            color="primary"
            className={classNames(classes.submit, "back-btn")}
            onClick={() => onBack()}
          >
            {_T("Back")}
          </Button>
        </div>
        <Divider />
        <div className={classes.loginBtn}>
          <Button
            id="login-password-reset-btn"
            fullWidth
            variant="contained"
            color="primary"
            className={classNames(classes.submit, "reset-btn")}
            onClick={() => resetPassword(true)}
          >
            {_T("Password Reset")}
          </Button>
          <div className={classes.smallDiv}></div>
        </div>
      </form>
      {showPasswordResetModal && (
        <PasswordResetDialog
          open={showPasswordResetModal}
          username={username}
          onClose={onClosePasswordResetDialog}
        />
      )}
    </>
  );
};

export default LoginEnterPassword;
