import delay from './delay'

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const config = {
  printerRoles: [
    {
      id: '1',
      name: 'Each'
    },{
      id: '2',
      name: 'Case'
    },{
      id: '3',
      name: 'Pallet',
    }
  ],
  userRoles: [
    {
      id: '1',
      name: 'SiteAdmin'
    },{
      id: '2',
      name: 'Operator'
    },{
      id: '3',
      name: 'QA',
    }
  ]
}


class ConfigApi {
  static getConfig () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], config))
      }, delay)
    })
  }

}

export default ConfigApi
