import  { Context, createContext, useContext } from 'react';
import { IStorage } from '../../interfaces/Storage/IStorage';

const typedLocalStorage: IStorage = localStorage as IStorage;
export const LocalStorageContext: Context<IStorage> = createContext(typedLocalStorage)

export function useLocalStorage(): IStorage {
  try {
    const context = useContext(LocalStorageContext);
    return context;
  } catch (err) {
    throw new Error(`${useLocalStorage.name} must be used within a functional component`);
  }
}
