import { useQuery, UseQueryOptions } from 'react-query';
import VrsUserApi from '../api/prodVrsUserApi';

const fetchUsers = async (companyId: string, fields: string[]) => {
  return await VrsUserApi.getUsersAppSync(companyId, true, fields);
};

/**
 * Custom hook to fetch users using react-query.
 *
 * @param {string} companyId - The ID of the company to fetch users for.
 * @param {string[]} fields - The fields to be fetched for each user.
 * @param {UseQueryOptions} [options] - Optional configuration options for the useQuery hook.
 * @returns {object} The result of the useQuery hook, including the users data and query status.
 */
const useFetchUsers = (
  companyId: string,
  fields: string[],
  options?: UseQueryOptions<unknown, unknown, unknown, [string, string, string[]]>
) => {
  return useQuery(['users', companyId, fields], () => fetchUsers(companyId, fields), {
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
};

export default useFetchUsers;
