import { useCopyrightButtonStyle } from "./CopyrightButton.css";

const CopyrightButton = () => {
  const classes = useCopyrightButtonStyle();
  return (
    <div className={classes.root}>
      VideojetConnect © {new Date().getFullYear()}
    </div>
  );
};

export { CopyrightButton };
