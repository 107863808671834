import { Select } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.25),
    width: "100%",
  },
  outlined: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));

const StyledSelect = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(classes.root, commonClasses.root)
    : commonClasses.root;
  return (
    <Select classes={{ ...classes, root: activeRootClasses }} {...props}>
      {children}
    </Select>
  );
};

export default StyledSelect;
