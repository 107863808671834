import { DialogProps } from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { useInfoDialogStyle } from "./InfoDialog.css";
import { DialogWithBackdropClick } from "../DialogWithBackdropClick/DialogWithBackdropClick";
import { StyledDialogTitle } from "../StyledDialogTitle/StyledDialogTitle";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface InfoDialogProps {
  title: any;
  open: boolean;
  onClose: () => void;
  content: any;
  maxWidth?: DialogProps["maxWidth"];
  hideButton?: boolean;
}

const InfoDialog = ({
  title,
  open,
  maxWidth,
  onClose,
  content,
  hideButton,
}: InfoDialogProps) => {
  const classes = useInfoDialogStyle();
  const { _T } = useVrsTranslationState();

  return (
    <DialogWithBackdropClick
      disableBackdropClick
      classes={{ paper: classes.root }}
      fullWidth={true}
      maxWidth={maxWidth || "sm"}
      disableEscapeKeyDown
      aria-labelledby="info"
      open={open}
    >
      <StyledDialogTitle id="info-dialog-title">{title}</StyledDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      {!hideButton && (
        <DialogActions className={classes.actionButtons}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            data-testid="info-close-btn"
          >
            {_T("Close")}
          </Button>
        </DialogActions>
      )}
    </DialogWithBackdropClick>
  );
};

export { InfoDialog };
