import { useVrsTranslationState } from "../../../context/AppContext/AppContext";
import classNames from "classnames";
import { useConfirmSaveWorkScheduleDialogStyles } from "./ConfirmSaveWorkScheduleDialog.css";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";

import { IWorkSchedule } from "../../../interfaces/User/IWorkSchedule";
import { NoValue, YesValue } from "../../../constants/global";

interface ConfirmSaveWorkScheduleDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  workSchedule?: IWorkSchedule;
  saving: boolean;
}

const ConfirmSaveWorkScheduleDialog = ({
  open,
  onClose,
  workSchedule,
  saving,
}: ConfirmSaveWorkScheduleDialogProps) => {
  const { _T } = useVrsTranslationState();

  const classes = useConfirmSaveWorkScheduleDialogStyles();

  return (
    <ConfirmDialog
      saving={saving}
      title={_T("Please Confirm")}
      open={open}
      onClose={onClose}
      content={
        <div>
          <div className={classes.item}>
            {_T("Are you sure you want to save work schedule?")}
          </div>
          <div className={classes.item}>
            <strong>{_T("Notification Enabled")}: </strong>
            <span
              className={classNames(
                classes.notification,
                workSchedule?.EnableNotification
                  ? classes.notificationYes
                  : classes.notificationNo
              )}
            >
              {workSchedule?.EnableNotification
                ? _T(YesValue).toUpperCase()
                : _T(NoValue).toUpperCase()}
            </span>
          </div>
        </div>
      }
    />
  );
};

export { ConfirmSaveWorkScheduleDialog };
