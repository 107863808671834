import { ThreeDots } from "react-loader-spinner";
interface ThreeDotsCellLoaderProp {
  color: string;
  size: string;
  radius: string;
}
export const ThreeDotsCellLoader = ({
  size,
  radius,
  color,
}: ThreeDotsCellLoaderProp) => (
  <ThreeDots
    height={size}
    width={size}
    radius={radius}
    color={color}
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    visible={true}
  />
);
