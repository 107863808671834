import lzString from "lz-string";

export function getIdFromLink(link: string): number {
  return Number(getIdFromLinkAsString(link));
}

export function getIdFromLinkAsString(link: string): string {
  const parts = link.split("/");
  return parts.pop() || "";
}

export function truncateString(text = "", limit = 20, trail = "..."): string {
  if (limit > 0 && limit - trail.length > 0) {
    return text.length > limit
      ? text.substring(0, limit - trail.length) + trail
      : text;
  } else {
    return text;
  }
}

export function upperFirstLetter(value: string): string {
  return value.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function upperFirstLetterOfEachWord(
  value: string,
  separateWithSpace = true
): string {
  return value
    .split(" ")
    .map((word: string) => upperFirstLetter(word))
    .join(separateWithSpace ? " " : "");
}

export function compare(a, b, greater = true) {
  if (greater) {
    return a > b;
  }
  return a < b;
}

export function upperFirstLetterOfEachWordWithUnderscore(
  value: string,
  separateWithUnderscore = true
): string {
  return value
    .split("_")
    .map((word: string) => upperFirstLetter(word))
    .join(separateWithUnderscore ? "_" : "");
}

export function upperFirstLetterOnly(value: string): string {
  return value.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export function isUndefinedOrNullOrEmpty(value) {
    return value === undefined || value === null || value === "";
}

export function upperFirstLetterOfEachWordOnly(
  value: string,
  separateWithSpace = true
): string {
  return value
    .split(" ")
    .map((word: string) => upperFirstLetterOnly(word))
    .join(separateWithSpace ? " " : "");
}

export function removeSpace(value: string, separateWithSpace = true): string {
  return value.split(" ").join(separateWithSpace ? " " : "");
}

export function escapeCharacters(text: string) {
  const encodedText = text
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\r\n|\n|\r/g, "<br/>");
  return encodedText;
}


export const compressLZW = (data) => {
  if (typeof data === "object") {
    return lzString.compressToEncodedURIComponent(JSON.stringify(data));
  }

  return lzString.compressToEncodedURIComponent(data);
};


export const decompressLZW = (compressed) =>
  lzString.decompressFromEncodedURIComponent(compressed);


export const parseDecompressLZW = (compressed) =>
  JSON.parse(decompressLZW(compressed));
