import { useCallback, useState } from "react";

import {
  useVrsTranslationState,
} from "../../../context/AppContext/AppContext";
import { useEditProfileDialogStyles } from "./EditProfileDialog.css";
import { DialogWithBackdropClick } from "../../../components/DialogWithBackdropClick/DialogWithBackdropClick";
import { Button, Tab, Tabs } from "@mui/material";
import { StyledDialogTitle } from "../../../components/StyledDialogTitle/StyledDialogTitle";
import { DialogActions, DialogContent, DialogProps } from "@mui/material";

import { TabPanel } from "../../../components/Basic/TabPanel/TabPanel";
import WorkScheduleBar from "../workschedule/WorkScheduleBar/WorkScheduleBar";
import WorkScheduleTable from "../workschedule/WorkScheduleTable/WorkScheduleTable";
import EditProfileDetails from "../EditProfileDetails/EditProfileDetails";
import { useProfile } from "../../../hooks/ProfileHook/ProfileHook";
import { ConfirmSaveWorkScheduleDialog } from "../ConfirmSaveWorkScheduleDialog/ConfirmSaveWorkScheduleDialog";
import { useConfigActions } from "../../../actions/configActions";

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

interface EditProfileDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps["maxWidth"];
}

const EditProfileDialog = ({
  open,
  onClose,
  maxWidth,
}: EditProfileDialogProps) => {
  const { _T } = useVrsTranslationState();
  const configActions = useConfigActions();
  const performOnClose = useCallback(() => {
    configActions.setProfileChanged();
    onClose();
  }, [onClose, configActions]);

  const {
    currentProfile,
    setCurrentProfile,
    saveProfile,
    resetIndex,
    refreshIndex,
    setResetIndex,
    timeError,
    onTimeError,
    dataReady,
    dirty,
    scheduleDirty,
    errors,
    saving,
  } = useProfile({ onClose: performOnClose, includeAlerts: false });
  const classes = useEditProfileDialogStyles();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const [isSaveProfileConfirmVisible, setIsSaveProfileConfirmVisible] =
    useState(false);

  const onCloseRegisterConfirmDialog = useCallback(
    (result) => {
      if (result) {
        saveProfile();
      }
      setIsSaveProfileConfirmVisible(false);
    },
    [saveProfile]
  );

  const checkSaveProfileSite = useCallback((_) => {
    setIsSaveProfileConfirmVisible(true);
  }, []);

  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        fullWidth={true}
        open={open}
        maxWidth={maxWidth || "sm"}
      >
        {dataReady && currentProfile ? (
          <>
            <StyledDialogTitle id="edit-profile-dialog-title">
              <div>{_T("Edit Profile")}</div>
            </StyledDialogTitle>
            <DialogContent dividers classes={{ root: classes.content }}>

                <div className={classes.detailContainer}>
                  <div className={classes.detailTop}>
                    <div className={classes.detail}>
                      <div>
                        <div className={classes.tabContainer}>
                          <Tabs
                            value={activeTabIndex}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="primary"
                          >
                            <Tab
                              label={_T("Details")}
                              {...a11yProps(0)}
                              key={0}
                            />
                            <Tab
                              label={_T("Work Schedule")}
                              {...a11yProps(1)}
                              key={1}
                            />
                          </Tabs>
                        </div>

                        <TabPanel value={activeTabIndex} index={0} key={0}>
                          <EditProfileDetails
                            currentProfile={currentProfile}
                            setCurrentProfile={setCurrentProfile}
                            errors={errors}
                            saving={saving}
                          />
                        </TabPanel>
                        <TabPanel value={activeTabIndex} index={1} key={1}>
                          <WorkScheduleBar
                            currentProfile={currentProfile}
                            setCurrentProfile={setCurrentProfile}
                            setResetIndex={setResetIndex}
                          />
                          <WorkScheduleTable
                            currentProfile={currentProfile}
                            setCurrentProfile={setCurrentProfile}
                            resetIndex={resetIndex}
                            onTimeError={onTimeError}
                            refreshIndex={refreshIndex}
                            errors={errors}
                          />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
              <Button
                variant="contained"
                color="primary"
                disabled={saving || !dirty || (scheduleDirty && timeError)}
                onClick={scheduleDirty ? checkSaveProfileSite : saveProfile}
              >
                {_T("Save")}
              </Button>
              <Button variant="contained" onClick={onClose}>
                {_T("Cancel")}
              </Button>
            </DialogActions>
          </>
        ) : null}
      </DialogWithBackdropClick>
      <ConfirmSaveWorkScheduleDialog
        saving={saving}
        open={isSaveProfileConfirmVisible}
        workSchedule={currentProfile?.WorkSchedules}
        onClose={onCloseRegisterConfirmDialog}
      />
    </>
  );
};

export default EditProfileDialog;
