import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";

const baseUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["RES_APPSYNC_URL"];
};

class VrsGetPrinterLogApi {
  static getAppSyncGetPrinterLog(
    key: string,
    ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({});
    }

    const graphQLBody = {
      query: `
        query LogDownloadQuery {
          logDownload(FileKey: "${key}"){
                SecureLink
                FileName
                MimeType
          }
        }
        `,
    };

    return invokeAppSync({
      gatewayName: "RES_APPSYNC",
      path: baseUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data) {
        return {
          error: null,
          data: results.data,
        };
      }
    });
  }
}

export default VrsGetPrinterLogApi;
