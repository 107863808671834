import { useMutation, useQueryClient } from 'react-query';
import VrsTagsApi from '../api/prodVrsTagsApi';
import { ISaveTagInputType } from '../interfaces/Tags/ISaveTagInputType';
import { IDeleteTagInputType } from '../interfaces/Tags/IDeleteTagInputType';
import { ITagPriorities } from '../interfaces/ITagPriorities';

const isDeleteTagInputType = (tag: any): tag is IDeleteTagInputType => {
  return 'Id' in tag && 'SiteId' in tag && !('Name' in tag);
};


/**
 * Custom hook that handles saving or deleting tags.
 * Uses the `useMutation` hook from `react-query` to handle asynchronous
 * operations for saving or deleting tags via the `VrsTagsApi`.
 *
 * Depending on the type of `tag` passed, it differentiates between
 * an update, creation, or deletion operation.
 *
 * The mutation on success will invalidate the 'tags' query to ensure
 * the cache is up to date with the latest data.
 *
 * @returns {object} The mutation object returned by `useMutation`, containing methods
 *                   to execute the mutation and track its status.
 */
const useSaveTag = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (tag: ISaveTagInputType | IDeleteTagInputType ) => {
      if (isDeleteTagInputType(tag)) {
        return await VrsTagsApi.saveTag('Delete', tag);
      } else {
        return await VrsTagsApi.saveTag(tag.Id ? 'Update' : 'Create', tag);
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('tags');
      },
    }
  );
};



const useSaveTagPriorities = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (request: ITagPriorities ) => {
     return await VrsTagsApi.savePriorities(request.siteId, request.priorities);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('tags');
      },
    }
  );
}

export { useSaveTag, useSaveTagPriorities };
