import config from "../config";
import { UM_VCCM } from "../constants/global";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as awsLib from "./authLib-aws";

interface UMModule {
  invokeApig(param: any): Promise<any>;
  invokeApigAnonymously(param: any): Promise<any>;
  invokeAppSync(param: any): Promise<any>;
  authUser(): Promise<any>;
  signOutUser();
  userRefreshSession(user): Promise<any>;
  getCurrentUser(): CognitoUser | null;
  getUserIdAttributes(user: CognitoUser): Promise<any>;
  checkIsAdmin(user: CognitoUser): boolean;
  isVrsSuperUser(user: CognitoUser): Promise<boolean>;
  checkIsCSEUser(user: CognitoUser): Promise<boolean>;
  getAuthClaims(user: CognitoUser): Promise<any>;
  isPureDesignUser(user: CognitoUser): Promise<boolean>;
}

export function getUMModuleName(): string {
  if (awsLib.isLoggedIn()) return UM_VCCM;
  return config.settings.UM_MODULE;
}

export function modulesLoggedIn(): any {
  return {
    [UM_VCCM]: awsLib.isLoggedIn(),
  };
}

function NotImplemented() {
  throw new Error(`Unsupported by UM module ${getUMModuleName()}`);
}

const unimplemented: UMModule = {
  invokeApig: async (_) => NotImplemented(),
  invokeApigAnonymously: async (_) => NotImplemented(),
  invokeAppSync: async (_) => NotImplemented(),
  authUser: async () => NotImplemented(),
  signOutUser: () => NotImplemented(),
  userRefreshSession: async (_) => NotImplemented(),
  getCurrentUser: () => {
    NotImplemented();
    return null;
  },
  getUserIdAttributes: async (_) => {
    NotImplemented();
    return false;
  },
  checkIsAdmin: (_) => {
    NotImplemented();
    return false;
  },
  isVrsSuperUser: (_) => {
    NotImplemented();
    return Promise.resolve(false);
  },
  checkIsCSEUser: (_) => {
    NotImplemented();
    return Promise.resolve(false);
  },
  isPureDesignUser: (_) => {
    NotImplemented();
    return Promise.resolve(false);
  },
  getAuthClaims: (_) => {
    NotImplemented();
    return Promise.resolve({});
  },

};

function moduleFactory(): UMModule {
  const name = getUMModuleName();
  switch (name) {
    case UM_VCCM:
      return awsLib;
    default:
      return unimplemented;
  }
}

const umModuleWrapper: UMModule = {
  invokeApig: async (param) => moduleFactory().invokeApig(param),
  invokeApigAnonymously: async (param) => moduleFactory().invokeApigAnonymously(param),
  invokeAppSync: async (param) => moduleFactory().invokeAppSync(param),
  authUser: async () => moduleFactory().authUser(),
  signOutUser: () => moduleFactory().signOutUser(),
  userRefreshSession: async (user) => moduleFactory().userRefreshSession(user),
  getCurrentUser: () => moduleFactory().getCurrentUser(),
  getUserIdAttributes: async (user) =>
    moduleFactory().getUserIdAttributes(user),
  checkIsAdmin: (user) => moduleFactory().checkIsAdmin(user),
  isVrsSuperUser: (user) => moduleFactory().isVrsSuperUser(user),
  checkIsCSEUser: (user) => moduleFactory().checkIsCSEUser(user),
  getAuthClaims: (user) => moduleFactory().getAuthClaims(user),
  isPureDesignUser: (user) => moduleFactory().isPureDesignUser(user),
};

export const {
  invokeApig,
  invokeApigAnonymously,
  invokeAppSync,
  authUser,
  signOutUser,
  userRefreshSession,
  getCurrentUser,
  getUserIdAttributes,
  checkIsAdmin,
  isVrsSuperUser,
  isPureDesignUser,
} = umModuleWrapper;

const libDefaults = {
  getUMModuleName,
  modulesLoggedIn,
  ...umModuleWrapper,
};

export default libDefaults;
