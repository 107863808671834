import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import classNames from "classnames";
import { myLiteGreyExtra2 } from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: "normal",
    wordBreak: "break-word",

    textAlign: "center",
    borderRight: `1px solid ${myLiteGreyExtra2}`,
    padding: theme.spacing(0.5, 0.5, "!important"),
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

const StyledTableGridCell = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses =
    classes && classes.root
      ? classNames(classes.root, commonClasses.root)
      : commonClasses.root;
  return (
    <Grid item classes={{ ...classes, root: activeRootClasses }} {...props}>
      {children}
    </Grid>
  );
};

export default StyledTableGridCell;
