import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useWorkScheduleTableStyles } from "./WorkScheduleTable.css";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";

import StyledTableGridHeadRow from "../../../../components/StyledTableGridHeadRow/StyledTableGridHeadRow";
import StyledTableGridHeadCell from "../../../../components/StyledTableGridHeadCell/StyledTableGridHeadCell";
import { TableGridWithEmptyDataCheck } from "../../../../components/TableGridWithEmptyDataCheck/TableGridWithEmptyDataCheck";
import WorkScheduleRow from "../WorkScheduleRow/WorkScheduleRow";
import { VrsTableHeadText } from "../../../../components/vrs/VrsTableHeadText/VrsTableHeadText";
import { ISchedule } from "../../../../interfaces/User/ISchedule";
import { getEmptyScheduleList } from "../../../../utilities/utils";
import { IUserProfile } from "../../../../interfaces/User/IUserProfile";

interface IWorkScheduleTableProps {
  currentProfile: IUserProfile | null;
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>;
  resetIndex: number;
  refreshIndex: number;
  onTimeError: (day, label: string, value: boolean) => void;
  errors: any;
}

const WorkScheduleTable = ({
  currentProfile,
  setCurrentProfile,
  resetIndex,
  refreshIndex,
  onTimeError,
  errors,
}: IWorkScheduleTableProps) => {
  const classes = useWorkScheduleTableStyles();
  const tableHeadFontSize = "h6.fontSize";
  const tableHeadAlign = "center";

  const { _T } = useVrsTranslationState();

  const schedules = currentProfile?.WorkSchedules.Schedule || [];

  const scheduleList = useMemo(() => {
    const list = getEmptyScheduleList();
    if (schedules) {
      return list.map((el) => {
        const schedule = schedules.find((elm) => elm.Day === el.Day);
        return schedule || el;
      });
    }
    return list;
  }, [schedules]);

  const updateWorkSchedule = useCallback(
    (value: ISchedule) => {
      if (currentProfile) {
        setCurrentProfile((s) =>
          s
            ? {
                ...s,
                WorkSchedules: {
                  EnableNotification: s.WorkSchedules.EnableNotification,
                  Schedule: s.WorkSchedules.Schedule.map((el) =>
                    el.Day === value.Day ? value : el
                  ),
                },
              }
            : null
        );
      }
    },
    [currentProfile]
  );

  return (
    <>
      <TableGridWithEmptyDataCheck
        hasData={schedules && schedules.length > 0}
        hasFilteredData={true}
        gridClasses={classes.gridClasses}
        emptyText=""
        emptyFilteredText=""
      >
        <StyledTableGridHeadRow extraClasses={[classes.rowClasses]}>
          <StyledTableGridHeadCell classes={{ root: classes.cellHeadClasses }}>
            <VrsTableHeadText
              text={_T("Day")}
              fontSize={tableHeadFontSize}
              textAlign={tableHeadAlign}
            />
          </StyledTableGridHeadCell>
          <StyledTableGridHeadCell classes={{ root: classes.cellHeadClasses }}>
            <VrsTableHeadText
              text={`${_T("Start Time")} [hh:mm]`}
              fontSize={tableHeadFontSize}
              textAlign={tableHeadAlign}
            />
          </StyledTableGridHeadCell>
          <StyledTableGridHeadCell classes={{ root: classes.cellHeadClasses }}>
            <VrsTableHeadText
              text={`${_T("Shift Length")} [hh:mm]`}
              fontSize={tableHeadFontSize}
              textAlign={tableHeadAlign}
            />
          </StyledTableGridHeadCell>
        </StyledTableGridHeadRow>
        {scheduleList.map((schedule, index) => (
          <WorkScheduleRow
            key={schedule.Day + "_" + index}
            schedule={schedule}
            rowClasses={classes.rowClasses}
            cellClasses={classes.cellClasses}
            updateWorkSchedule={updateWorkSchedule}
            resetIndex={resetIndex}
            onTimeError={onTimeError}
            enableNotification={
              currentProfile?.WorkSchedules.EnableNotification || false
            }
            refreshIndex={refreshIndex}
          />
        ))}
      </TableGridWithEmptyDataCheck>
      {errors && (
        <div className={classes.errorContainer}>
          {Object.keys(errors).map((key) => (
            <div key={key} className={classes.errors}>
              {_T(errors[key])}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default WorkScheduleTable;
