import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useNotFoundStyle = makeStyles((_: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline'
  }
}));
