import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: "normal",
    wordBreak: "break-word",
    padding: theme.spacing(0.25, 1.25, "!important"),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 2, "!important"),
    },
  },
}));

const StyledGridCell = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(classes.root, commonClasses.root)
    : commonClasses.root;
  return (
    <Grid item classes={{ ...classes, root: activeRootClasses }} {...props}>
      {children}
    </Grid>
  );
};

export default StyledGridCell;
