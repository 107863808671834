import config from "../../config";
import deploymentInfoApi from "../../api/prodVrsDeploymentInfoApi";
import { DialogWithBackdropClick } from "../../components/DialogWithBackdropClick/DialogWithBackdropClick";
import {
  DialogActions,
  DialogContent,
  DialogProps,
  Button,
  Grid,
} from "@mui/material";
import { StyledDialogTitle } from "../../components/StyledDialogTitle/StyledDialogTitle";
import { useAboutDialogStyles } from "./AboutDialog.css";
import { useEffect, useState } from "react";
import { ProgressIndicator } from "../ProgressIndicator/ProgressIndicator";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface AboutDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps["maxWidth"];
}

const AboutDialog = ({ open, onClose, maxWidth }: AboutDialogProps) => {
  const classes = useAboutDialogStyles();
  const { _T } = useVrsTranslationState();

  const VRSVersionInfo = config.settings.VRS_VERSION;
  const clientVersion = config.settings.CLIENT_VERSION;

  const [aboutState, setAboutState] = useState<any>({
    loadedVersionInfo: false,
    info: [],
  });

  const { loadedVersionInfo, info, error } = aboutState;

  useEffect(() => {
    if (loadedVersionInfo) return;
    (async () => {
      const d = await deploymentInfoApi.getDeploymentInfo();
      setAboutState({
        loadedVersionInfo: true,
        info: d.data,
        error: d.error,
      });
    })();
  }, [loadedVersionInfo]);

  return (
    <>
      <DialogWithBackdropClick
        disableBackdropClick
        fullWidth={true}
        open={open}
        maxWidth={maxWidth || "sm"}
      >
        <StyledDialogTitle id="about-dialog-title">
          <div>{_T("About")}</div>
        </StyledDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1} className={classes.root}>
            <Grid item>
              <img
                style={{
                  height: "35px",
                  top: -0,
                  display: "block",
                }}
                alt="logo"
                src="/images/videojetConnect-logo-90black.png"
              />
            </Grid>
            <Grid item>
              {_T("VideojetConnectDetails")}
            </Grid>
            <Grid item>{`VRS Version: ${VRSVersionInfo}`}</Grid>
            <Grid item>{`Client Version: ${clientVersion}`}</Grid>
            {!loadedVersionInfo ? (
              <ProgressIndicator fullScreen={false} />
            ) : (
              info?.map((i: { Name: any; Version: any }) => (
                <Grid item key={i.Name}>
                  {`${i.Name}: ${i.Version}`}{" "}
                </Grid>
              ))
            )}
            {error ? (
              <Grid item>{`Error: ${error.message || "Unknown"}`}</Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionButtons}>
          <Button variant="contained" onClick={onClose}>
            {_T("Close")}
          </Button>
        </DialogActions>
      </DialogWithBackdropClick>
    </>
  );
};

export default AboutDialog;
