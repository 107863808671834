import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { standardBoxShadow } from "../../../constants/colors";
import StyledContentCard from "../StyledContentCard/StyledContentCard";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5, 0, "!important"),
    boxShadow: standardBoxShadow,
  },
}));

const StyledBarContentCard = ({ children, classes, ...props }: any) => {
  const commonClasses = useStyles();
  const activeRootClasses = classes && classes.root
    ? classNames(classes.root, commonClasses.root)
    : commonClasses.root;
  return (
    <StyledContentCard classes={{ ...classes, root: activeRootClasses }} {...props}>
      {children}
    </StyledContentCard>
  );
};

export default StyledBarContentCard;
