import {
  CREATE_PRODUCT_SUCCESS,
  LOAD_PRODUCTS_SUCCESS,
  RESET_SITE_DATA
} from "../../actions/actionTypes";
import initialState from "../initialState";

// A reducing function that returns the next state tree, given the current state tree and an action to handle.
export function productReducer(state = initialState.products, action) {
  switch (action.type) {
    case RESET_SITE_DATA:
      return initialState.products;

    case LOAD_PRODUCTS_SUCCESS: {
      return action.payload;
    }
    case CREATE_PRODUCT_SUCCESS:
      return [
        ...state,
        { ...action.payload },
      ];

    default:
      return state;
  }
}
