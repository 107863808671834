import { SetStateAction, Dispatch, useCallback } from "react";
import StyledGridCell from "../../../../components/Basic/StyledGridCell/StyledGridCell";
import { Button, FormControlLabel } from "@mui/material";
import { useWorkScheduleBarStyles } from "./WorkScheduleBar.css";
import BarStyledGrid from "../../../../components/Basic/BarStyledGrid/BarStyledGrid";

import classNames from "classnames";
import StyledBarContentCard from "../../../../components/Basic/StyledBarContentCard/StyledBarContentCard";
import { StyledSwitch } from "../../../../components/StyledSwitch/StyledSwitch";
import { useVrsTranslationState } from "../../../../context/AppContext/AppContext";
import { Typography } from "@mui/material";
import { getEmptyScheduleList } from "../../../../utilities/utils";
import { IUserProfile } from "../../../../interfaces/User/IUserProfile";

const gridBreak: any = {
  twentyfour7: { lg: 6, md: 6, sm: 6, xs: 12 },
  enableNotification: { lg: 6, md: 6, sm: 6, xs: 12 },
};

interface IWorkScheduleBarProps {
  currentProfile: IUserProfile | null;
  setCurrentProfile: Dispatch<SetStateAction<IUserProfile | null>>;
  setResetIndex: Dispatch<SetStateAction<number>>;
}

const WorkScheduleBar = ({
  currentProfile,
  setCurrentProfile,
  setResetIndex,
}: IWorkScheduleBarProps) => {
  const classes = useWorkScheduleBarStyles();

  const { _T } = useVrsTranslationState();

  const setEnabledNotification = useCallback(
    (value) => {
      if (currentProfile) {
        setCurrentProfile((s) =>
          s
            ? {
                ...s,
                WorkSchedules: {
                  ...s.WorkSchedules,
                  EnableNotification: value,
                },
              }
            : null
        );
      }
    },
    [currentProfile]
  );

  const resetTo247 = useCallback(() => {
    if (currentProfile) {
      setResetIndex((r) => r + 1);
      setCurrentProfile((s) =>
        s
          ? {
              ...s,
              WorkSchedules: {
                EnableNotification: s?.WorkSchedules?.EnableNotification,
                Schedule: getEmptyScheduleList().map((el) => ({
                  ...el,
                  ShiftHours: 23,
                  ShiftMins: 59,
                })),
              },
            }
          : null
      );
    }
  }, [currentProfile]);

  return (
    <StyledBarContentCard>
      <BarStyledGrid>
        <StyledGridCell
          {...gridBreak.twentyfour7}
          className={classNames(classes.filterContainer)}
        >
          <Button color="primary" onClick={resetTo247} variant="contained">
            {_T("Reset to 24/7")}
          </Button>
        </StyledGridCell>
        <StyledGridCell
          {...gridBreak.enableNotification}
          className={classNames(
            classes.filterContainer,
            classes.filterContainerEnd,
            classes.lastGridCell
          )}
        >
          <FormControlLabel
            control={
              <StyledSwitch
                checked={
                  currentProfile?.WorkSchedules.EnableNotification || false
                }
                onChange={(event) =>
                  setEnabledNotification(event.target.checked)
                }
                name="enableNotification"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={
              <Typography className={classes.enabledNotificationLabel}>
                {_T("Enable Notification")}
              </Typography>
            }
          />
        </StyledGridCell>
      </BarStyledGrid>
    </StyledBarContentCard>
  );
};

export default WorkScheduleBar;
