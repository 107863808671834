import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useAppStyles = makeStyles((_: Theme) => ({
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
  error: {
    textAlign: "center",
    fontWidth: "bold",
    fontSize: "1.5rem",
  }
}));
