import { useState } from "react";
import { Divider, TextField, Button } from "@mui/material";

import classNames from "classnames";
import Signup from "../Signup/Signup";
import { useLoginStyles } from "./Login.css";
import LoginHeader from "./LoginHeader";
import Utils from "../../utilities/utils";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface EnterUsernameProps {
  isLoading;
  username;
  setUsername: (username) => void;
  onNext: (bypassSSO) => void;
}

const LoginEnterUsername = ({
  isLoading,
  username,
  setUsername,
  onNext,
}: EnterUsernameProps) => {
  const { _T } = useVrsTranslationState();
  const classes = useLoginStyles();

  const [createDesign, setCreateDesign] = useState(false);

  const onCloseCreateAccountDialog = () => {
    setCreateDesign(false);
  };

  const isValid = () => {
    return username ? true : false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      onNext(false);
    }
  };

  return (
    <>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <LoginHeader />
        <div className={classes.usernameText}>{username}</div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          data-testid="email"
          label={_T("Email")}
          name="email"
          autoComplete="email"
          autoFocus
          value={username}
          disabled={isLoading}
          className={classNames(classes.email, "email-txt")}
          onChange={(event) => {
            const value = event.target.value;
            setUsername(() => value);
          }}
        />
        <div className={classes.loginBtn}>
          <Button
            id="login-next-btn"
            fullWidth
            variant="contained"
            color="primary"
            data-testid="next-btn"
            className={classes.submit}
            type="submit"
            disabled={!isValid()}
          >
            {_T("Next")}
          </Button>
        </div>

        {Utils.isLocalhost() && (
          <div className={classes.loginBtn}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classNames(classes.submit, "noSSO-btn")}
              type="submit"
              disabled={!isValid()}
              onClick={() => onNext(true)}
            >
              {"Bypass SSO (For Testing)"}
            </Button>
          </div>
        )}

        <div className={classNames(classes.backBtn, classes.emptyDiv)}></div>

        <Divider />
        <div className={classes.loginBtn}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => setCreateDesign(true)}
          >
            {_T("Create Account") + " (Design Only)"}
          </Button>
        </div>
      </form>
      <Signup open={createDesign} onClose={onCloseCreateAccountDialog} />
    </>
  );
};

export default LoginEnterUsername;
